export const environment = process.env.NODE_ENV === 'production' ? 'production' : 'local';

export const API_BASE_URL =
  environment === 'production'
    ? process.env.REACT_APP_API_URL.toString().replace('api/v1', '')
    : 'http://localhost:8080/';

export const RESOURCES = {
  dataAccess: {
    getDataAccessClientsReportTypesActive:
      'api/v1/dataAccess/getDataAccessClientsReportTypesActive',
    getDataAccessClientTypeId: 'api/v1/dataAccess/getDataAccessClientTypeId',
  },
  dealersAndLenders: {
    searchDealers: 'api/v1/dealersAndLenders/searchDealers',
    searchLenders: 'api/v1/dealersAndLenders/searchLenders',
  },
  helpCentral: {
    contactUsPath: 'api/v1/contactUs',
    sendEmail: 'api/v1/email/sendEmail',
  },
  indicators: {
    availableIndicators: 'api/v1/indicators/getIndicatorsSelection',
  },
  notifications: {
    notificationsByUser: 'api/v1/notifications/getNotificationsActiveByUser',
    dropDownSource: 'api/v1/notifications/getDropDownSource',
    makeNotificationStale: 'api/v1/notifications/makeNotificationStale',
  },
  error: {
    logging: 'api/v1/errorLogging/logError',
  },
  persona: {
    getPersonaDisplay: 'api/v1/persona/getPersonaDisplay',
    getPersonaUsers: 'api/v1/persona/getPersonaUsers',
    getPersonaUserById: 'api/v1/persona/getPersonaUserById',
  },
  sts: {
    getStsAccountByClientId: 'api/v1/sts/getStsAccountByClientId',
    getStsFolderByUserId: 'api/v1/sts/getStsFolderByUserId',
    getStsBundleByUser: 'api/v1/sts/getStsBundleByUser',
  },
  products: {
    productsByUser: 'api/v1/products/getProductsByUser',
  },
  quickQuery: {
    displayBundle: 'api/v1/quickQuery/getDisplayBundle',
  },
  reportSelection: {
    getReports: 'api/v1/reportSelection/getReports',
    reportDisplayBundle: 'api/v1/reportSelection/getReportsDisplayBundle',
    getReportsListByReportTypeForClientForSchedulingScreen:
      'api/v1/reportSelection/getReportsListByReportTypeForClientForSchedulingScreen',
    reportDisplayByOptions: 'api/v1/displayby/options',
    reportGroupByOptions: 'api/v1/groupby/options',
  },
  risk: {
    controls: 'api/v1/riskControls',
  },
  riskScoreTypes: {
    availableRiskScoreTypes: 'api/v1/riskScoreTypes/getRiskScoreTypesActive',
  },
  territory: {
    availableStatesByClientId: 'api/v1/territory/getAvailableStatesByClient',
    availableDmasByState: 'api/v1/territory/getAvailableDmasByState',
    availableZipsByState: 'api/v1/territory/getAvailableZipsByState',
    availableCountiesByState: 'api/v1/territory/getAvailableCountiesByState',
    availableCountiesByDma: 'api/v1/territory/getCountiesByDma',
    zipsInRange: 'api/v1/territory/getZipsInRange',
  },
  usersQueriesOptions: {
    getRiskDisplayByOptions: 'api/v1/riskdisplayby/options',
    getRiskGroupByOptions: 'api/v1/riskgroupby/options',
    getUsersQueriesOptionsActive: 'api/v1/usersQueriesOptions/getUsersQueriesOptionsActive',
    getSavedRiskOptions: 'api/v1/usersQueriesOptions/getSavedRiskOptions',
    addUserQueryOption: 'api/v1/usersQueriesOptions/addUserQueryOption',
    addUserQueryOptionRun: 'api/v1/usersQueriesOptions/addUserQueryOptionRun',
    addUserQueryOptionRunByUserQueryOptionName:
      'api/v1/usersQueriesOptions/addUserQueryOptionRunByUserQueryOptionName',
    updateUserQueryOption: 'api/v1/usersQueriesOptions/updateUserQueryOption',
    disableUserQueryOption: 'api/v1/usersQueriesOptions/disableUserQueryOption',
    existsUserQueryOptionActiveWithOptionNameForUser:
      'api/v1/usersQueriesOptions/existsUserQueryOptionActiveWithOptionNameForUser',
    getClientUsersListForSchedulingScreen:
      'api/v1/usersQueriesOptions/getClientUsersListForSchedulingScreen',
  },
  usersScheduledQueries: {
    getUserScheduledQueryByUserQueryOptionId:
      'api/v1/usersScheduledQueries/getUserScheduledQueryByUserQueryOptionId',
    getUsersScheduledQueriesActive: 'api/v1/usersScheduledQueries/getUsersScheduledQueriesActive',
    getUsersScheduledQueriesBundleActive:
      'api/v1/usersScheduledQueries/getUsersScheduledQueriesBundleActive',
    getUserScheduledQueryBundleActive:
      'api/v1/usersScheduledQueries/getUserScheduledQueryBundleActive',
    existsUserScheduledQueryActiveWithUserScheduledQueryNameForUser:
      'api/v1/usersScheduledQueries/existsUserScheduledQueryActiveWithUserScheduledQueryNameForUser',
    addUserScheduledQuery: 'api/v1/usersScheduledQueries/addUserScheduledQuery',
    updateUserScheduledQuery: 'api/v1/usersScheduledQueries/updateUserScheduledQuery',
    disableUserScheduledQuery: 'api/v1/usersScheduledQueries/disableUserScheduledQuery',
    addUserScheduledQueryAdditionalActionSendNow:
      'api/v1/usersScheduledQueries/addUserScheduledQueryAdditionalActionSendNow',
      getUsersScheduledQueriesReportFilesActiveByUser: 'api/v1/usersScheduledQueries/getUserScheduledQueryReportFilesByUser',
      getUserScheduledQueryReportFile: 'api/v1/usersScheduledQueries/getUserScheduledQueryReportFile',
      disableUserScheduledQueryReportFile: 'api/v1/usersScheduledQueries/disableUserScheduledQueryReportFile'
  },
  vehicles: {
    availableVehicles: 'api/v1/vehicles/getVehiclesSelection',
    modelsByMakeId: 'api/v1/vehicles/getModelsByMakeId',
    makesAndModels: 'api/v1/vehicles/getMakesAndModels',
    class: 'api/v1/vehicles/getVehicleClass',
    classSize: 'api/v1/vehicles/getVehicleClassSize',
    type: 'api/v1/vehicles/getVehicleType',
    subType: 'api/v1/vehicles/getVehicleSubType',
    fuel: 'api/v1/vehicles/getVehicleFuelType',
    manufacturers: 'api/v1/vehicles/getManufacturers',
    manufacturersAndMakes: 'api/v1/vehicles/getManufacturersAndMakes',
    makesByManufacturerId: 'api/v1/vehicles/getMakesByManufacturerId',
  },
  documents: {
    getFile: 'api/v1/documents/getFile'
  }
};

export const ERRORS = {
  backend: {
    default: 'BK-100',
  },
  noResponse: {
    default: 'NR-110',
  },
  code: {
    default: 'CD-120',
  },
};

export const REPORT_TYPES = {
  marketReportTypeId: 1,
  riskReportTypeId: 2,
};

export const OKTA_CONFIGURATION = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_CALLBACK_REDIRECT_URL,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: false,
  tokenManager: {
    autoRenew: false,
    autoRemove: false,
    // expireEarlySeconds: 60 * 59,
  },
};

export const OKTA_SIGNIN_CONFIGURATION = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
  redirectUri: process.env.REACT_APP_OKTA_CALLBACK_REDIRECT_URL,
  scopes: ['openid', 'profile', 'email'],
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  disableHttpsCheck: false,
  pkce: true,
  tokenManager: {
    autoRenew: false,
    autoRemove: false,
    // expireEarlySeconds: 60 * 59,
  },
};

export const DYNAMIC_REPORTING_PERIODS = {
  MOST_RECENT: 'Most Recent',
  MOST_RECENT_MINUS_ONE: 'One Prior',
  MOST_RECENT_MINUS_TWO: 'Two Prior',
};

export const REFERENCE_MATERIALS = {
  stateAvailability: {
    fileName: 'StateAvailabilityS3.png',
    title: 'State Availabilty',
    extension: 'png',
  },
  userGuide: {
    fileName: 'UserGuideS3.pdf',
    title: 'User Guide - AutoCount Accelerate',
    extension: 'pdf',
  },
  reportGuide: {
    fileName: 'ReportGuideS3.pdf',
    title: 'Report Reference- AutoCount Accelerate',
    extension: 'pdf',
  },
  dataDictionary: {
    fileName: 'DataDictionaryS3.pdf',
    title: 'Data Dictionary - Market, Risk & Performance',
    extension: 'pdf',
  },
  FAQs: {
    fileName: 'FAQsS3.pdf',
    title: 'FAQs - AutoCount Accelerate',
    extension: 'pdf',
  },
  autocountDifferences: {
    fileName: 'AutocountDifferencesS3.pdf',
    title: 'FAQs - AutoCount Differences',
    extension: 'pdf',
  },
  stateDMVData: {
    fileName: 'StateDMVDataS3.pdf',
    title: 'DMV Dealer & Lender Data Availability',
    extension: 'pdf',
  },
  releaseNotes: {
    fileName: 'ReleaseNotesS3.pdf',
    title: 'Release Notes - AutoCount Accelerate',
    extension: 'pdf',
  },
};
