import React, { useMemo } from 'react';

const TabsContext = React.createContext();
TabsContext.displayName = 'TabsContext';

function TabsProvider({ children, ...props }) {
  const [tabsState, setTabsState] = React.useState({
    marketReport: [ true, false, false, false, false, false, false ],
    riskReport: [ true, false, false, false, false, false, false ]
  });

  const setMarketReportTabsState = (newMarketReport) => {
    setTabsState({ ...tabsState, marketReport: newMarketReport });
  };

  const setRiskReportTabsState = (newRiskReport) => {
    setTabsState({ ...tabsState, riskReport: newRiskReport });
  };

  return (
    <TabsContext.Provider
      value={useMemo(
        () => ({
          marketReport: tabsState.marketReport,
          riskReport: tabsState.riskReport,
          setMarketReportTabsState,
          setRiskReportTabsState
        }),
        [tabsState, setMarketReportTabsState, setRiskReportTabsState],
      )}
      {...props}
    >
      {children}
    </TabsContext.Provider>
  );
}

function useTabs() {
  const context = React.useContext(TabsContext);
  if (!context) throw new Error(`useTabs must be used within the TabsProvider`);

  return context;
}

export { TabsProvider, useTabs };
