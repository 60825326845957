import React from 'react';
import './helpContactUs.scss';
import MarqueImage from './MarqueeImage';

function ReferenceMaterial({ path }) {
  const referenceImage = '/images/reference-materials-marquee.jpg';

  return (
    <MarqueImage image={referenceImage} titleName="Reference Materials" path={path} />
  );
}

export default ReferenceMaterial;
