import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import RunIcon from '@mui/icons-material/PlayCircleOutline';
import Typography from '@mui/material/Typography';
import { cloneDeep } from 'lodash';
import SingleSelect from '../../../singleSelect/SingleSelect';
import './SelectReportToRun.scss';
import Select from '../../../select/Select';

function SelectReportToRun({ query, setContextQuery, reportPath, caller, queryOptionTypeId, callerRunQuery }) {
  const [chosenReports, setChosenReports] = useState({});
  const [reportId, setReportId] = useState();
  const history = useHistory();
  const [controls, setControls] = useState([]);
  const [queryCopy, setQueryCopy] = useState();

  const removeSpaces = (value) =>
  value.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1)).replace(/\s/g, '');

  const onChange = (data) => {
    const auxQuery = { ...query, ...data };
    setQueryCopy(auxQuery);
  };

  function chosenReportsSort(chosenReportsJSONObject) {
    if (chosenReportsJSONObject === undefined) 
     return chosenReportsJSONObject
    return chosenReportsJSONObject.sort((a, b) => {
      const valueA = a.reportNm.toUpperCase();
      const valueB = b.reportNm.toUpperCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }
      return 0;
    });
  }

  function riskControlsOptionsSort(riskControlsOptionsJSONObject) {
    if (riskControlsOptionsJSONObject === undefined) 
     return riskControlsOptionsJSONObject
    return riskControlsOptionsJSONObject.sort((a, b) => {
      const valueA = a.valueText.toUpperCase();
      const valueB = b.valueText.toUpperCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA < valueB) {
        return 1;
      }
      return 0;
    });
  }


  useEffect(() => {
    setQueryCopy(cloneDeep(query));
    if (query && query?.chosenReports && (Object.keys(query?.chosenReports).length) === 1) {
      setChosenReports(query?.chosenReports);
      setReportId(Object.keys(query?.chosenReports)[0]);
    } 
  }, []);

  useEffect(() => {
    let riskControlsForReportId = false;
    if (query && (reportId || (query?.chosenReports && (Object.keys(query?.chosenReports).length) === 1))) {
        let selectedReportId = reportId;
        // it might still be undefined due to the first useEffect hook that sets the reportId still pending
        if (selectedReportId === undefined) {
          // eslint-disable-next-line prefer-destructuring
          selectedReportId = Object.keys(query?.chosenReports)[0];
        }
        if (query?.riskOptionsSelection) {
          const previousSelectedRiskControlsForReport = {};
          previousSelectedRiskControlsForReport[selectedReportId] = {};
          let riskControlKey = ""; 
          let riskControlKeyAndArrayValues = {};
          if (query?.riskOptionsSelection?.riskDynamicFields?.[selectedReportId]) 
          {
              Object.keys(query?.riskOptionsSelection?.riskDynamicFields?.[selectedReportId]).forEach((KeyName) => {
                riskControlKey = KeyName;
                riskControlKeyAndArrayValues = {};
                riskControlKeyAndArrayValues = Object.values(query?.riskOptionsSelection?.riskDynamicFields?.[selectedReportId][riskControlKey])  
                previousSelectedRiskControlsForReport[selectedReportId][riskControlKey] = riskControlKeyAndArrayValues;
              });
              setControls(previousSelectedRiskControlsForReport);
              riskControlsForReportId = true;
          }
        }
    }
    if (reportId && riskControlsForReportId && query?.riskOptionsSelection) {
      const riskDynamicFields = {};
      onChange({
        riskOptionsSelection: {
          ...queryCopy?.riskOptionsSelection,
          riskDynamicFields,
        },
      }); 
    }
    else {
      const nestedKey = 'riskOptionsSelection.riskDynamicFields';
      if (queryCopy?.riskOptionsSelection?.riskDynamicFields) {
        const keys = nestedKey.split('.');
        const prop = keys.pop();
        const parent = keys.reduce((obj, key) => obj[key], queryCopy);
        delete parent[prop];
      }
    } 
  }, [reportId]);


  const handleOnChangeReportType = (event) => {
    setChosenReports(event.chosenReports);
    setReportId(Object.keys(event.chosenReports)[0]);
  };

  const handleOnChangeRiskControl = (value, controlName, riskControlReportId) => {
    if (value && value.id) {
      const valueWithId = {[value?.id]: value};
       onChange({
        riskOptionsSelection: {
          ...queryCopy?.riskOptionsSelection,
          riskDynamicFields: {
            ...queryCopy?.riskOptionsSelection?.riskDynamicFields,
            [reportId]: {
              ...queryCopy?.riskOptionsSelection?.riskDynamicFields?.[riskControlReportId],
              [removeSpaces(controlName)]: valueWithId, // value,
            },
          },
        },
      }); 
    }
    else if (controlName && riskControlReportId && queryCopy?.riskOptionsSelection?.riskDynamicFields?.[riskControlReportId][removeSpaces(controlName)]) {
      const oldReportIdRiskControlValue = cloneDeep(queryCopy?.riskOptionsSelection?.riskDynamicFields?.[riskControlReportId]);
      delete oldReportIdRiskControlValue[removeSpaces(controlName)];
      onChange({
        riskOptionsSelection: {
          ...queryCopy?.riskOptionsSelection,
          riskDynamicFields: {
            ...queryCopy?.riskOptionsSelection?.riskDynamicFields,
            [reportId]: 
                oldReportIdRiskControlValue
            },
          },
      });
    };
  };

  const runQuery = () => {
    setContextQuery({ ...queryCopy, chosenReports });
    if (caller && caller === "My Reports") {
      callerRunQuery(queryCopy, queryOptionTypeId);
    }
    else {
      history.push({
        pathname: reportPath,
      });
    }
  };

  function isRunEnabled() {
    if (!chosenReports || Object.keys(chosenReports).length !== 1) {
      return false;
    }
    if (chosenReports && Object.keys(chosenReports).length === 1 && query?.riskOptionsSelection?.riskDynamicFields) {
      if (query?.riskOptionsSelection?.riskDynamicFields && !queryCopy?.riskOptionsSelection?.riskDynamicFields) {
        return false;
      }
      const originalRiskDynamicFieldsForReportId = query?.riskOptionsSelection?.riskDynamicFields[reportId];
      const copyRiskDynamicFieldsForReportId = queryCopy?.riskOptionsSelection?.riskDynamicFields[reportId];
      if (originalRiskDynamicFieldsForReportId && !copyRiskDynamicFieldsForReportId) {
        return false;
      }
      if (copyRiskDynamicFieldsForReportId) {
        // if there are more risk controls types in the original vs the copy there is still a pending selection
        if ((Object.keys(originalRiskDynamicFieldsForReportId).length) !== (Object.keys(copyRiskDynamicFieldsForReportId).length))
        {
          return false;
        }
        // const riskDynamicFieldsForReportControlName = Object.keys(copyRiskDynamicFieldsForReportId)[0];
        let moreThanOneValueInSameRiskControlName = false;
        Object.values(copyRiskDynamicFieldsForReportId).forEach(((riskControlType) => {
          if (riskControlType.length > 1) {
            moreThanOneValueInSameRiskControlName = true;
          }
        }));
        if (moreThanOneValueInSameRiskControlName) {
          return false;
        }
      }
    }
    return true;
  }

  const getValueForControl = (options, controlName, reportIdSelected) => {
    const queryValue = queryCopy?.riskOptionsSelection?.riskDynamicFields?.[reportIdSelected]?.[controlName];
    const defaultValue = undefined;

    let result = defaultValue;
    if (queryValue) {
      result = options.find((option) => option.valueText === queryValue.valueText) || defaultValue;
    }
    return result;
  };

  return (
    <div className="SelectReport__mainContainer">
      <div className="SelectReport__countyContainer">
        <FormControl fullWidth>
          <Select
            key="select-reportId"
            id="SelectReportToRunId"
            testId="ReportType"
            value={chosenReports}
            options={chosenReportsSort(Object.values(query?.chosenReports))}
            displayAttribute="reportNm"
            selectAttribute="reportId"
            onChange={handleOnChangeReportType}
            label="Select Report Type to Run"
            queryDataType="chosenReports"
            hasAllOption={false}
          />


{ controls && Object.keys(controls) && chosenReports && Object.keys(controls)?.length > 0 &&
        Object.keys(controls).map((controlReportId) => {
          let selectedReportControls;

          if (Object.keys(chosenReports).includes(controlReportId)) {
            selectedReportControls = (
              <Grid key={`grid1-${controlReportId.toString()}`} className="riskControl" item xs={12}>
                {
                }


              <Grid key={`grid2-${controlReportId.toString()}`} className="dynamicRiskControlWrapper">
                {Object.entries(controls[controlReportId]).map(([controlName, value]) => (
                  <FormControl key={`formControl-${controlReportId.toString()}-${controlName.toString()}`}  fullWidth>
                    <Typography key={`typoGraphy-${controlReportId.toString()}-${controlName.toString()}`}  variant="h4">{controlName}</Typography>

                    <SingleSelect
                      key={`singleSelect-${controlReportId.toString()}`}
                      options={riskControlsOptionsSort(value)}
                      displayAttribute="valueText"
                      selectAttribute="id"
                      handleChange={(event, innervalue) => {
                        handleOnChangeRiskControl(innervalue, controlName, reportId);
                      }}
                      value={getValueForControl(value, removeSpaces(controlName), reportId)}
                      label={controlName}
                    />
              </FormControl>
            ))}
          </Grid>

          </Grid>
            );
          }
          return selectedReportControls;
        })
      }


          <Button
            id="advancedQuery__generateReportButtonID"
            disabled={!isRunEnabled()}
            variant="contained"
            startIcon={<RunIcon />}
            onClick={runQuery}
          >
            Run Report
          </Button>
        </FormControl>
      </div>
    </div>
  );
}

export default SelectReportToRun;
