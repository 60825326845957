import React, { useContext } from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Alert } from '@mui/material';
import {
  disableUserQueryOption,
  existsUserQueryOptionActiveWithOptionNameForUser,
} from '../../../../../services/usersQueriesOptions.api';
import { StoreContext } from '../../../../../store/storeContext';
import FormField from '../../../../formField/FormField';

function SavedOptions({
  onChange,
  setUserQueryOptionId,
  availableUsersQueriesOptionsActive,
  setSelectedRiskScoreType,
  setCustomScoreRangeOption,
  setHideOwnScoreRangeSection,
  setCustomRiskScoreTypeMinValue,
  setCustomRiskScoreTypeMaxValue,
  setDistributionBandTypeValue,
  setPresetBandsDistributionValue,
  setHideCustomTiersSection,
  setCustomTiers,
  setNewSavedOptionNameTextFieldHidden,
  userQueryOptionId,
  QueryOptionTypes,
  setAvailableUsersQueriesOptionsActive,
  setSavedOptionName,
  setAlertSuccessResponse,
  setSavedOptionsChangeNotSaved,
  savedOptionName,
  newSavedOptionNameTextFieldHidden,
  savedOptionsChangeNotSaved,
  changeNotSaved,
  handleSaveOrUpdate,
  QueryActionTypes,
  setConfirmationDialogOpen,
  validationErrors,
  setMessage,
  message,
  setDefaultValues,
}) {
  const { actions } = useContext(StoreContext);
  function readRiskOptionsFromUserQueryOption(userQueryOptionIdSelected) {
    if (userQueryOptionIdSelected !== undefined) {
      let index = -1;
      for (let i = 0; i < availableUsersQueriesOptionsActive.length; i++) {
        if (
          availableUsersQueriesOptionsActive[i]?.userQueryOptionId === userQueryOptionIdSelected
        ) {
          index = i;
          break;
        }
      }

      const userQueryOptionJSON = JSON.parse(
        availableUsersQueriesOptionsActive[index]?.userQueryOptionJSON,
      );
      onChange({
        riskOptionsSelection: userQueryOptionJSON,
      });
      setUserQueryOptionId(availableUsersQueriesOptionsActive[index]?.userQueryOptionId);
      setSelectedRiskScoreType(
        parseInt(userQueryOptionJSON.editableRiskOption?.scoreType?.riskScoreTypeId),
      );
      setCustomScoreRangeOption(userQueryOptionJSON.editableRiskOption?.customScoreRangeOption);
      if (userQueryOptionJSON.editableRiskOption?.customScoreRangeOption) {
        setHideOwnScoreRangeSection(false);
      } else {
        setHideOwnScoreRangeSection(true);
      }

      setCustomRiskScoreTypeMinValue(
        userQueryOptionJSON.editableRiskOption?.customScoreType?.riskScoreTypeMinValue,
      );
      setCustomRiskScoreTypeMaxValue(
        userQueryOptionJSON.editableRiskOption?.customScoreType?.riskScoreTypeMaxValue,
      );

      setDistributionBandTypeValue(userQueryOptionJSON.editableRiskOption?.distributionBandTypeId);
      setPresetBandsDistributionValue(
        userQueryOptionJSON.editableRiskOption?.presetBandsDistributionValue,
      );
      setCustomTiers(userQueryOptionJSON.editableRiskOption?.customTiers);
      if (userQueryOptionJSON.editableRiskOption?.distributionBandTypeId === 1) {
        setHideCustomTiersSection(true);
      } else if (userQueryOptionJSON.editableRiskOption?.distributionBandTypeId === 2) {
        setHideCustomTiersSection(false);
      }
    }
  }
  function handleSavedOptionsChange(event) {
    setUserQueryOptionId(event.target.value);
    if (event.target.value !== 0) {
      readRiskOptionsFromUserQueryOption(event.target.value);
    } else {
      setDefaultValues(true);
    }
  }

  function handleSavedOptionsAddButtonClick() {
    setNewSavedOptionNameTextFieldHidden(false);
  }

  function handleSavedOptionsDeleteForeverButtonClick() {
    disableUserQueryOption(userQueryOptionId, QueryOptionTypes.CriteriaRiskQueryOptions)
      .then((availableUsersQueriesOptionsActiveData) => {
        setAvailableUsersQueriesOptionsActive(availableUsersQueriesOptionsActiveData);
        setUserQueryOptionId(0);
        setSavedOptionName('');
        setAlertSuccessResponse(`Risk Option deleted successfuly!`, setMessage);
      })
      .catch((error) => {
        actions.errorAction(error);
      });
  }

  function handleNewSavedOptionNameChange(optionName) {
    if (optionName.length > 0) setSavedOptionsChangeNotSaved(true);
    setSavedOptionName(optionName.trimStart().replace(/\s\s+/g, ' '));
  }

  function handleSaveOrUpdateAction() {
    let optionName = '';
    if (savedOptionName.trim() === '') {
      optionName = availableUsersQueriesOptionsActive?.find(
        (item) => item.userQueryOptionId === userQueryOptionId,
      ).userQueryOptionNm;
    } else {
      optionName = savedOptionName;
    }

    if (optionName !== undefined && optionName !== '') {
      existsUserQueryOptionActiveWithOptionNameForUser(
        QueryOptionTypes.CriteriaRiskQueryOptions,
        optionName,
      ).then((existsUserQueryOptionActiveWithOptionNameForUserData) => {
        if (existsUserQueryOptionActiveWithOptionNameForUserData === false)
          handleSaveOrUpdate(QueryActionTypes.Add);
        else setConfirmationDialogOpen(true);
      });
    } else {
      setAlertSuccessResponse(`Risk Option could not be added or updated!`, setMessage);
    }
  }
  return (
    <>
      <Grid container item>
        <Grid container item direction="row" xs={12}>
          <Grid item>
            <FormField
              fieldTitle="Saved options"
              dataCy="aq-SavedRiskOptions"
              fieldInput={
                <>
                  <FormControl>
                    <Select
                      test-id="savedRiskOptions"
                      data-cy="aq-SavedRiskOptions-select"
                      id="userQueryOptionSingleSelect"
                      sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                      value={userQueryOptionId}
                      onChange={(event) => handleSavedOptionsChange(event)}
                    >
                      <MenuItem key={0} value={0}>
                        None
                      </MenuItem>
                      {availableUsersQueriesOptionsActive?.map((row) => (
                        <MenuItem
                          style={{ whiteSpace: 'pre' }}
                          key={row?.userQueryOptionId}
                          value={row?.userQueryOptionId}
                          label={row?.userQueryOptionNm}
                        >
                          {row?.userQueryOptionNm}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Select a previous saved option template to populate values below automatically
                    </FormHelperText>
                  </FormControl>

                  <FormLabel>{'  '}</FormLabel>
                </>
              }
            />
          </Grid>
          <Grid item>
            <IconButton
              test-id="addNewRiskOptionsButton"
              data-cy="aq-addNewRiskOptions-button"
              color="primary"
              aria-label="New option"
              component="span"
              onClick={() => handleSavedOptionsAddButtonClick()}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <TextField
              data-cy="aq-NewRiskOptions-textbox"
              test-id="newRiskOptionsTextFields"
              id="newSavedOptionNameTextField"
              placeholder="New Saved Option Name"
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              value={savedOptionName}
              inputProps={{ maxLength: 50 }}
              hidden={newSavedOptionNameTextFieldHidden}
              onChange={(event) => handleNewSavedOptionNameChange(event.target.value)}
            />
          </Grid>
          <Grid item>
            <IconButton
              test-id="saveRiskOptionsButton"
              data-cy="aq-SaveRiskOptionsButton-button"
              disabled={
                (userQueryOptionId === 0 && savedOptionName.trim() === '') ||
                (!savedOptionsChangeNotSaved && !changeNotSaved) ||
                validationErrors
              }
              color="primary"
              aria-label="Save option"
              component="span"
              onClick={() => handleSaveOrUpdateAction()}
              size="large"
            >
              <SaveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              test-id="deleteForeverRiskOptionsButton"
              data-cy="aq-DeleteRiskOptionsButton-button"
              disabled={userQueryOptionId === 0}
              color="primary"
              aria-label="Delete forever"
              component="span"
              onClick={() => handleSavedOptionsDeleteForeverButtonClick()}
              size="large"
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              test-id="moveTrashRiskOptionsButton"
              data-cy="aq-MoveTrashRiskOptionsButton-button"
              disabled={!savedOptionsChangeNotSaved}
              color="primary"
              aria-label="Move to trash"
              component="span"
              hidden
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              test-id="restoreRiskOptionsButton"
              data-cy="aq-RestoreRiskOptionsButton-button"
              disabled={!savedOptionsChangeNotSaved}
              color="primary"
              aria-label="Restore from trash"
              component="span"
              hidden
              size="large"
            >
              <RestoreFromTrashIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {message.text !== '' ? (
        <div className="divAlert">
          <Alert severity={message.severity}>{message.text}</Alert>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default SavedOptions;
