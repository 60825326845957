import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const PRODUCTS_RESOURCES = RESOURCES.products;

function getProductDropDownSource(clientId) {
  const resource = `${API_BASE_URL}${PRODUCTS_RESOURCES.dropDownSource}/${clientId}`;
  return fetchData(resource);
}
function getProductsIdsByUser() {
  const resource = `${API_BASE_URL}${PRODUCTS_RESOURCES.productsIdsByUser}`;
  return fetchData(resource);
}

function getProductsByUser() {
  const resource = `${API_BASE_URL}${PRODUCTS_RESOURCES.productsByUser}`;
  return fetchData(resource);
}

export { getProductDropDownSource, getProductsIdsByUser, getProductsByUser };
