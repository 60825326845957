import React from 'react';
import { LAYOUT, routes } from './utils/constants/routes';
import { renderRoutes } from './utils/router.utils';

const additioanlProps = {};
const homePath = null;
const isRoot = true;

function App() {
  return <>{renderRoutes(Object.values(routes), LAYOUT, homePath, additioanlProps, isRoot)}</>;
}

export default App;
