import React from 'react';
import LoginForm from '../../components/login/LoginForm';
import LandingProductsDisplay from '../../views/landingProductsDisplay/LandingProductsDisplay';
import { AuthHandler } from '../../contexts/Authentication';
import LoginPageLayout from '../../layouts/loginLayout/LoginPageLayout';
import AutocountLayout from '../../layouts/autocountLayout/AutocountLayout';

const LAYOUT = '/';

const routes = {
  authHandler: {
    component: AuthHandler,
    path: LAYOUT,
    isSecured: false,
  },
  login: {
    component: LoginPageLayout,
    path: '/login',
    children: <LoginForm />,
    isSecured: false,
  },
  welcome: {
    component: LandingProductsDisplay,
    path: '/welcome',
  },
  autocount: {
    component: AutocountLayout,
    path: '/autocount',
    exact: false,
  },
};
export { routes, LAYOUT };
