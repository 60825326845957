import React, { useEffect, useState } from 'react';
import { Alert, Grid, Paper, Checkbox } from '@mui/material';
import { Input } from '@material-ui/core';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import MaterialMultiSelect from '../../components/materialMultiSelect/MaterialMultiSelect';
import './ScheduleForm.scss';
import DeleteScheduleQueryModal from './DeleteScheduleQueryModal';
import {
  getUserScheduledQueryByUserQueryOptionId,
  addUserScheduledQuery,
  disableUserScheduledQuery,
  addUserScheduledQueryAdditionalActionSendNow,
} from '../../services/usersScheduledQueries.api';
import { getPersonaDisplay } from '../../services/persona.api';
import {
  AlertSeverity,
  UsersScheduledQueriesFrequencyTypes,
  UsersScheduledQueriesFormatTypes,
  UsersScheduledQueriesFormatSeparatorTypes,
  UsersScheduledQueriesDeliveryTypes,
  UsersScheduledQueriesSFTPOrSTSElementTypes,
  UsersScheduledQueriesCompressionTypes,
  UsersScheduledQueriesEmailsElementTypes,
} from '../../utils/constants/enums';
import { useAuthentication } from '../../contexts/Authentication';
import { getStsBundleByUser } from '../../services/sts.api';

function ScheduleForm({
  rowData,
  availableUsersQueriesOptionsActive,
  setRefreshTable,
  initializeEmails,
}) {
  //---------------------------------------------------------
  const scheduleDefaultModel = {
    userScheduledQueryNm: `${rowData?.queryOptionTypeNm} ${rowData?.userQueryOptionNm} Schedule`,
    userScheduledQueryCompressionTypeId: UsersScheduledQueriesCompressionTypes.Zip,
    userScheduledQueryDeliveryTypeId: UsersScheduledQueriesDeliveryTypes.Email,
    userScheduledQueryFormatSeparatorTypeId: UsersScheduledQueriesFormatSeparatorTypes.Comma,
    userScheduledQueryFormatTypeId: UsersScheduledQueriesFormatTypes.PDF,
    userScheduledQueryFrequencyTypeId: UsersScheduledQueriesFrequencyTypes.Monthly,
    userScheduledQueryIsSingleXlsActive: false
  };
  //---------------------------------------------------------
  const [scheduledQuery, setScheduledQuery] = useState(scheduleDefaultModel);
  const [scheduledQuerySTS, setScheduledQuerySTS] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isPDFSelected, setIsPDFSelected] = useState(true);
  const [isExcelSelected, setIsExcelSelected] = useState(false);
  const [isDelimitedTextSelected, setIsDelimitedTextSelected] = useState(false);
  const [scheduledQueryEmails, setScheduledQueryEmails] = useState({});
  const [message, setMessage] = useState({ severity: '', text: '' });
  const [EmailOrSFTPOrSTSMessage, setEmailOrSFTPOrSTSMessage] = useState({
    severity: '',
    text: '',
  });

  const [showDelete, setShowDelete] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const deleteMessage = `Delete ${rowData?.userScheduledQueryNm}?`;
  const [availableEmails, setAvailableEmails] = useState(initializeEmails);
  const [defaultEmailObject, setDefaultEmailObject] = useState({});
  const { userInfo } = useAuthentication();

  const updateTable = (
    userScheduledQueryId,
    userScheduledQueryNm,
    userScheduledQueryFormatTypeId,
    userScheduledQueryFrequencyTypeId,
    userScheduledQueryDeliveryTypeId,
    userScheduledQueryCompressionTypeId,
  ) => {
    const objIndex = availableUsersQueriesOptionsActive.findIndex(
      (item) => item?.userQueryOptionId === rowData?.userQueryOptionId,
    );

    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryId = userScheduledQueryId;
    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryNm = userScheduledQueryNm;
    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryFormatTypeId =
      userScheduledQueryFormatTypeId;
    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryFrequencyTypeId =
      userScheduledQueryFrequencyTypeId;
    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryDeliveryTypeId =
      userScheduledQueryDeliveryTypeId;
    // eslint-disable-next-line no-param-reassign
    availableUsersQueriesOptionsActive[objIndex].userScheduledQueryCompressionTypeId =
      userScheduledQueryCompressionTypeId;

    setRefreshTable(true);
  };
  const timerMessage = (time) => {
    setTimeout(() => {
      setMessage({ severity: '', text: '' });
      setEmailOrSFTPOrSTSMessage({ severity: '', text: '' });
    }, time);
  };

  const setAlertErrorResponse = (msg) => {
    setMessage({ severity: AlertSeverity.error, text: msg });
    timerMessage(8000);
  };

  const setAlertSuccessResponse = (msg) => {
    setMessage({ severity: AlertSeverity.success, text: msg });
    timerMessage(8000);
  };

  const handleEmailChange = (data) => {
    setEmailOrSFTPOrSTSMessage({
      severity: '',
      text: '',
    });
    if (data.emailAddress) {
      setScheduledQueryEmails(data.emailAddress);
    }
  };

  useEffect(() => {

    let personaDetails = {};
    getPersonaDisplay()
      .then((data) => {
        if (data.userId) {
          personaDetails = data
        } else {
          personaDetails  = {};
        }
      })
      .catch(() => {
        personaDetails  = {};
      });

      getStsBundleByUser()
      .then((data) => {
        if (data.stsAccountId && data.stsFolderId) {
            setScheduledQuerySTS({
              ...scheduledQuerySTS,
              account: data.stsAccountNm,
              folder: data.stsFolderNm
            });
        }
      })


    getUserScheduledQueryByUserQueryOptionId(rowData.userQueryOptionId).then((scheduleData) => {

      // validating if use persona or logged user
      let userToCompareEmail = userInfo.email;
      const personaStatus = localStorage.getItem('personaStatus');
      if (personaStatus === 'true') {
        userToCompareEmail = personaDetails.personaEmail;
      }
      // logic to get the logged user or persona email
      let defaultEmailAuxObject = {}
      if (availableEmails.length) {
        const filteredResult = availableEmails.find((element) => element.emailAddress === userToCompareEmail);
        if (filteredResult){
          const mail = filteredResult.emailAddress;
          const formattedResult = {};
          formattedResult[mail] = filteredResult;
          const nestedObject = {'emailAddress': formattedResult};
          defaultEmailAuxObject = nestedObject;
          setDefaultEmailObject(nestedObject);
        }
      }

      if (scheduleData?.usersScheduledQueriesResponse !== null) {
        setScheduledQuery(scheduleData?.usersScheduledQueriesResponse[0]);

        
        setScheduledQueryEmails(
          scheduleData?.usersScheduledQueriesEmailsResponse.reduce((obj, item) => {
            // eslint-disable-next-line no-param-reassign
            obj[[item.emailAddress]] = item;
            return obj;
          }, {}),
        );

        const emailsNonUsers = scheduleData?.usersScheduledQueriesEmailsResponse.filter(
          (item) =>
            item.userScheduledQueryEmailElementTypeId ===
            UsersScheduledQueriesEmailsElementTypes.Email,
        );
        setAvailableEmails([...availableEmails, ...emailsNonUsers]);

        setShowDelete(true);
        setIsEdit(true);
      } else {
        // add user email in case it is a new schedule
        handleEmailChange(defaultEmailAuxObject);
      }
    });
  }, []);


  const handleFrequencyChange = (event) => {
    setScheduledQuery({
      ...scheduledQuery,
      userScheduledQueryFrequencyTypeId: parseInt(event.target.value),
    });
  };

  const handleFormatChange = () => {
    if (!isEdit) {
      let newValue = 0;
      if (!isPDFSelected && !isExcelSelected && !isDelimitedTextSelected) {
        // none selected
        newValue = 0;
      }
      if (isPDFSelected && !isExcelSelected && !isDelimitedTextSelected) {
        // PDF selected
        newValue = UsersScheduledQueriesFormatTypes.PDF;
      }
      if (!isPDFSelected && isExcelSelected && !isDelimitedTextSelected) {
        // Excel selected
        newValue = UsersScheduledQueriesFormatTypes.EXCEL;
      }
      if (!isPDFSelected && !isExcelSelected && isDelimitedTextSelected) {
        // Delimited Text selected
        newValue = UsersScheduledQueriesFormatTypes.DELIMITED_TEXT;
      }
      if (isPDFSelected && isExcelSelected && !isDelimitedTextSelected) {
        // PDF and Excel selected
        newValue = UsersScheduledQueriesFormatTypes.PDF_AND_EXCEL;
      }
      if (isPDFSelected && !isExcelSelected && isDelimitedTextSelected) {
        // PDF and Delimited Text selected
        newValue = UsersScheduledQueriesFormatTypes.PDF_AND_DELIMITED_TEXT;
      }
      if (!isPDFSelected && isExcelSelected && isDelimitedTextSelected) {
        // Excel and Delimited Text selected
        newValue = UsersScheduledQueriesFormatTypes.EXCEL_AND_DELIMITED_TEXT;
      }
      if (isPDFSelected && isExcelSelected && isDelimitedTextSelected) {
        // All selected
        newValue = UsersScheduledQueriesFormatTypes.PDF_AND_EXCEL_AND_DELIMITED_TEXT;
      }
      setScheduledQuery({
        ...scheduledQuery,
        userScheduledQueryFormatTypeId: newValue,
      });
    }
  };

  const handleCheckboxSelectionFromUpdate = () => {
    if (isEdit) {
      const value = parseInt(scheduledQuery.userScheduledQueryFormatTypeId);
      switch (value) {
        case UsersScheduledQueriesFormatTypes.PDF:
          setIsPDFSelected(true);
          setIsExcelSelected(false);
          setIsDelimitedTextSelected(false);
          break;
        case UsersScheduledQueriesFormatTypes.EXCEL:
          setIsPDFSelected(false);
          setIsExcelSelected(true);
          setIsDelimitedTextSelected(false);
          break;
        case UsersScheduledQueriesFormatTypes.DELIMITED_TEXT:
          setIsPDFSelected(false);
          setIsExcelSelected(false);
          setIsDelimitedTextSelected(true);
          break;
        case UsersScheduledQueriesFormatTypes.PDF_AND_EXCEL:
          setIsPDFSelected(true);
          setIsExcelSelected(true);
          setIsDelimitedTextSelected(false);
          break;
        case UsersScheduledQueriesFormatTypes.PDF_AND_DELIMITED_TEXT:
          setIsPDFSelected(true);
          setIsExcelSelected(false);
          setIsDelimitedTextSelected(true);
          break;
        case UsersScheduledQueriesFormatTypes.EXCEL_AND_DELIMITED_TEXT:
          setIsPDFSelected(false);
          setIsExcelSelected(true);
          setIsDelimitedTextSelected(true);
          break;
        case UsersScheduledQueriesFormatTypes.PDF_AND_EXCEL_AND_DELIMITED_TEXT:
          setIsPDFSelected(true);
          setIsExcelSelected(true);
          setIsDelimitedTextSelected(true);
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    handleCheckboxSelectionFromUpdate();
  }, [isEdit]);

  useEffect(() => {
    handleFormatChange();
  }, [isPDFSelected, isExcelSelected, isDelimitedTextSelected]);

  const handleSingleXLSFileSelectionChange = (event) => {
    setScheduledQuery({
      ...scheduledQuery,
      userScheduledQueryIsSingleXlsActive: event.target.checked
    });
  };

  const handlePDFSelectionChange = (event) => {
    setIsEdit(false);
    setIsPDFSelected(event.target.checked);
  };

  const handleExcelSelectionChange = (event) => {
    setIsEdit(false);
    setIsExcelSelected(event.target.checked);
    if (!event.target.checked) {
      handleSingleXLSFileSelectionChange({target: { checked: false}})
    }
  };

  const handleDelimitedTextSelectionChange = (event) => {
    setIsEdit(false);
    setIsDelimitedTextSelected(event.target.checked);
  };



  const handleCompressionChange = (event) => {
    setScheduledQuery({
      ...scheduledQuery,
      userScheduledQueryCompressionTypeId: parseInt(event.target.value),
    });
  };

  const handleDeliveryOptionRadioOpitonChange = (event) => {
    setScheduledQuery({
      ...scheduledQuery,
      userScheduledQueryDeliveryTypeId: parseInt(event.target.value),
    });
  };

  const addScheduleToRunNow = (userScheduledQueryId) => {
    let responseMessage = '';
    addUserScheduledQueryAdditionalActionSendNow(userScheduledQueryId)
      .then((response) => {
        if (
          response !== undefined &&
          response?.userScheduledQueryAdditionalActionSendNowStatusAdditionalDetails !== undefined
        ) {
          responseMessage =
            response?.userScheduledQueryAdditionalActionSendNowStatusAdditionalDetails;
        }
        setAlertSuccessResponse(responseMessage);
      })
      .catch(() => {
        setAlertErrorResponse('Run Schedule Now Failed.');
      });
  };

  const handleSaveSchedule = (runNow = false) => {
    let errors = 0;
    let errorsDescription = '';
    // start validations:

    if (
      scheduledQuery?.userScheduledQueryNm === undefined ||
      scheduledQuery?.userScheduledQueryNm === ''
    ) {
      errors += 1;
      errorsDescription += 'Schedule Query Name is required. ';
    }
    if (scheduledQuery?.userScheduledQueryFrequencyTypeId === undefined) {
      errors += 1;
      errorsDescription += 'Frecuency Type is required. ';
    }
    if (scheduledQuery?.userScheduledQueryFormatTypeId === undefined) {
      errors += 1;
      errorsDescription += 'Format Type is required. ';
    }
    if (scheduledQuery?.userScheduledQueryFormatTypeId === 0) {
      errors += 1;
      errorsDescription += 'Format Type is required. ';
    }
    if (scheduledQuery?.userScheduledQueryDeliveryTypeId === undefined) {
      errors += 1;
      errorsDescription += 'Delivery Type is required. ';
    }
    if (scheduledQuery?.userScheduledQueryCompressionTypeId === undefined) {
      errors += 1;
      errorsDescription += 'Compression Type is required. ';
    }
    if (
      scheduledQuery?.userScheduledQueryDeliveryTypeId === 2 &&
      (scheduledQuerySTS?.account === undefined || scheduledQuerySTS?.account === '')
    ) {
      errors += 1;
      errorsDescription += 'SFTP or STS Account is required. ';
    }
    if (
      scheduledQuery?.userScheduledQueryDeliveryTypeId === 2 &&
      (scheduledQuerySTS?.folder === undefined || scheduledQuerySTS?.folder === '')
    ) {
      errors += 1;
      errorsDescription += 'SFTP or STS Folder is required. ';
    }

    if (scheduledQueryEmails === undefined || scheduledQueryEmails.length === 0) {
      setEmailOrSFTPOrSTSMessage({ severity: AlertSeverity.error, text: 'Email missing' });
    } else {
      if (scheduledQuery?.userScheduledQueryFormatSeparatorTypeId === undefined) {
        scheduledQuery.userScheduledQueryFormatSeparatorTypeId =
          UsersScheduledQueriesFormatSeparatorTypes.Tab;
      }

      const usersScheduledQueriesEmailsRequest = Object.values(scheduledQueryEmails);

      const scheduledQueryRequest = [
        {
          ...scheduledQuery,
          userQueryOptionId: rowData.userQueryOptionId,
        },
      ];

      let scheduledQuerySTSRequest = [];
      if (scheduledQuerySTS?.account !== undefined && scheduledQuerySTS?.folder !== undefined) {
        if (scheduledQuerySTS.account.length > 0 && scheduledQuerySTS.folder.length > 0) {
          scheduledQuerySTS.account = scheduledQuerySTS?.account.trimEnd();
          scheduledQuerySTS.folder = scheduledQuerySTS?.folder.trimEnd();

          scheduledQuerySTSRequest = [
            {
              ...scheduledQuerySTS,
              userScheduledQuerySFTPOrSTSElementTypeId:
                UsersScheduledQueriesSFTPOrSTSElementTypes.Default,
            },
          ];
        }
      }

      if (errors === 0) {
        addUserScheduledQuery(
          scheduledQueryRequest,
          usersScheduledQueriesEmailsRequest,
          scheduledQuerySTSRequest,
        )
          .then((response) => {
            setShowDelete(true);
            updateTable(
              response?.usersScheduledQueriesResponse[0]?.userScheduledQueryId,
              scheduledQuery?.userScheduledQueryNm,
              scheduledQuery?.userScheduledQueryFormatTypeId,
              scheduledQuery?.userScheduledQueryFrequencyTypeId,
              scheduledQuery?.userScheduledQueryDeliveryTypeId,
              scheduledQuery?.userScheduledQueryCompressionTypeId,
              scheduledQuery?.userScheduledQueryIsSingleXlsActive
            );
            setScheduledQuery(response?.usersScheduledQueriesResponse[0]);
            if (runNow) {
              addScheduleToRunNow(response?.usersScheduledQueriesResponse[0]?.userScheduledQueryId);
            } else setAlertSuccessResponse('Schedule Saved');
          })
          .catch(() => {
            setAlertErrorResponse('Unable to save the Schedule');
          });
      } else {
        setAlertErrorResponse(`Unable to save the Schedule. ${errorsDescription}`);
      }
    }
  };

  const handleConfirmationDialogConfirmationDisable = (userScheduledQueryId) => {
    disableUserScheduledQuery(userScheduledQueryId)
      .then(() => {
        setScheduledQuery(scheduleDefaultModel);
        setIsDelimitedTextSelected(false);
        setIsExcelSelected(false);
        setIsPDFSelected(true);
        setScheduledQueryEmails({});
        setShowDelete(false);
        updateTable(null, null, null, null);
        setAlertSuccessResponse('Schedule Deleted');
        setRefreshTable(true);
        // set the logged user email as default value
        handleEmailChange(defaultEmailObject);
      })
      .catch(() => {
        setAlertErrorResponse('Schedule Delete Failed');
      });
  };

  return (
    <Paper>
      <Grid className="form__mainContainer">
        <Grid className="form__rowContainer">
          <Grid className="grid_wrapper" item xs={1.5}>
            <Typography variant="h4">Frequency</Typography>
            <FormControl fullWidth>
              <RadioGroup
                className="scheduling__columnGroup"
                test-id="frequencyRadioGroup"
                value={parseInt(scheduledQuery?.userScheduledQueryFrequencyTypeId)}
                onChange={handleFrequencyChange}
              >
                <FormControlLabel
                  value={UsersScheduledQueriesFrequencyTypes.Monthly}
                  control={<Radio
                    data-cy="frequency-radio-button-Monthly"
                  />}
                  label="Monthly"
                />
                <FormControlLabel
                  value={UsersScheduledQueriesFrequencyTypes.CalendarQuarter}
                  control={<Radio
                    data-cy="frequency-radio-button-Calendar-Quarter"
                  />}
                  label="Calendar Quarter"
                />
                <FormControlLabel
                  value={UsersScheduledQueriesFrequencyTypes.CalendarYear}
                  control={<Radio
                    data-cy="frequency-radio-button-Calendar-Year"
                  />}
                  label="Calendar Year"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid className="grid_wrapper" item xs={1}>
            <Typography variant="h4">Format</Typography>
            <FormControl fullWidth>
              <FormControlLabel
                value={UsersScheduledQueriesFormatTypes.PDF}
                control={
                  <Checkbox
                    onChange={handlePDFSelectionChange}
                    checked={isPDFSelected}
                    data-cy="format-checkbox-PDF"
                  />
                }
                label="PDF"
              />
              <FormControlLabel
                value={UsersScheduledQueriesFormatTypes.EXCEL}
                control={
                  <Checkbox
                    onChange={handleExcelSelectionChange}
                    checked={isExcelSelected}
                    data-cy="format-checkbox-Excel"
                  />
                }
                label="Excel"
              />

              <FormControlLabel
                value={UsersScheduledQueriesFormatTypes.DELIMITED_TEXT}
                control={
                  <Checkbox
                    onChange={handleDelimitedTextSelectionChange}
                    checked={isDelimitedTextSelected}
                    data-cy="format-checkbox-CSV"
                  />
                }
                label="CSV"
              />
            </FormControl>
            <Typography variant="h4" className='combined_files_title'>Combined Files</Typography>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleSingleXLSFileSelectionChange}
                    checked={scheduledQuery.userScheduledQueryIsSingleXlsActive}
                    data-cy="combined-files-checkbox-single-excel"
                  />
                  }
                label="Single Excel"
                disabled={!isExcelSelected}
              />
            </FormControl>
          </Grid>
          <Grid className="grid_wrapper" item xs={1}>
            <FormControl fullWidth>
              <Typography variant="h4">Compression</Typography>
              <RadioGroup
                className="scheduling__columnGroup"
                test-id="formatRadioGroupCompressionType"
                value={parseInt(scheduledQuery?.userScheduledQueryCompressionTypeId)}
                onChange={handleCompressionChange}
              >
                <FormControlLabel
                  value={UsersScheduledQueriesCompressionTypes.Zip}
                  control={
                    <Radio data-cy="compression-radio-button-zip" />
                  }
                  label="ZIP"
                />
                <FormControlLabel
                  value={UsersScheduledQueriesCompressionTypes.Gzip}
                  control={
                    <Radio data-cy="compression-radio-button-gzip" />
                  }
                  label="GZIP"
                />
                <FormControlLabel
                  value={UsersScheduledQueriesCompressionTypes.None}
                  control={
                    <Radio data-cy="compression-radio-button-none" />
                  }
                  label="None"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {scheduledQuerySTS?.account && scheduledQuerySTS?.folder && (
            <Grid className="grid_wrapper" item xs={1}>
              <Typography variant="h4">Delivery</Typography>
              <FormControl fullWidth>
                <RadioGroup
                  className="scheduling__columnGroup"
                  test-id="frequencyRadioGroup"
                  value={parseInt(scheduledQuery?.userScheduledQueryDeliveryTypeId)}
                  onChange={handleDeliveryOptionRadioOpitonChange}
                >
                  <FormControlLabel
                    value={UsersScheduledQueriesDeliveryTypes.Email}
                    control={
                      <Radio data-cy="delivery-radio-button-email" />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    value={UsersScheduledQueriesDeliveryTypes.SFTPOrSTS}
                    control={
                      <Radio data-cy="delivery-radio-button-sftporsts" />
                    }
                    label="SFTP/STS"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}

          <Grid className="grid_wrapper" item xs={2}>
            <Typography variant="h4">Email</Typography>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <MaterialMultiSelect
                testid="Emails"
                className="email_dropdown"
                options={availableEmails}
                label="Emails"
                objectRenderField="fullNmAndEmailAddress"
                onChange={handleEmailChange}
                queryDataType="emailAddress"
                selectAttribute="emailAddress"
                value={scheduledQueryEmails}
                defaultAll={false}
                hideAllCheckBox
                disabled={availableEmails.length === 0}
                dataCy="email-input-emails"
              />
            </FormControl>
            {EmailOrSFTPOrSTSMessage.text !== '' ? (
              <div className="divAlert">
                <Alert severity={EmailOrSFTPOrSTSMessage.severity}>
                  {EmailOrSFTPOrSTSMessage.text}
                </Alert>
              </div>
            ) : (
              ''
            )}
          </Grid>
          {scheduledQuerySTS?.account && scheduledQuerySTS?.folder && (
            <Grid className="grid_wrapper scheduling__columnGroup" item xs={2}>
              <Typography variant="h4">SFTP/STS</Typography>
              <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                <Typography htmlFor="component-simple">Account</Typography>
                <Input
                  testid="sftpAccountTextField"
                  id="SFTPOrSTSAccount"
                  value={scheduledQuerySTS?.account || ''}                  
                  data-cy="sftporsts-input-account"
                  disabled
                />
              </FormControl>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <FormControl style={{ minWidth: 200, maxWidth: 200 }}>
                <Typography htmlFor="component-simple">Folder</Typography>
                <Input
                  testid="sftpFolderTextField"
                  id="SFTPOrSTSFolder"
                  value={scheduledQuerySTS?.folder || ''}
                  data-cy="sftporsts-input-folder"
                  disabled
                />
              </FormControl>
            </Grid>
          )}
          <Grid className="schedule_buttonsWrapper" item xs={1}>
            <Button
              className="schedule_buttons"
              variant="contained"
              testid="okButtonSaveDialog"
              onClick={() => handleSaveSchedule(true)}
              color="primary"
              autoFocus
              disabled={
                Object.keys(scheduledQueryEmails).length === 0 ||
                scheduledQuery.userScheduledQueryFormatTypeId === 0
              }
              data-cy="save-run-schedule-now-button"
            >
              SAVE & RUN SCHEDULE NOW
            </Button>
            <Button
              className="schedule_buttons"
              variant="contained"
              testid="okButtonSaveDialog"
              onClick={() => handleSaveSchedule()}
              color="primary"
              autoFocus
              disabled={
                Object.keys(scheduledQueryEmails).length === 0 ||
                scheduledQuery.userScheduledQueryFormatTypeId === 0
              }
              data-cy="save-schedule-button"
            >
              SAVE SCHEDULE
            </Button>

            {showDelete && (
              <Button
                className="schedule_buttons"
                variant="contained"
                testid="okButtonDeleteDialog"
                onClick={() => setShowModal(true)}
                color="primary"
                autoFocus
              >
                DELETE SCHEDULE
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {message.text !== '' ? (
        <div className="divAlert">
          <Alert severity={message.severity}>{message.text}</Alert>
        </div>
      ) : (
        ''
      )}
      <DeleteScheduleQueryModal
        title="Delete saved report"
        text={deleteMessage}
        show={showModal}
        onHideModal={() => {
          setShowModal(false);
        }}
        onConfirmAction={() => {
          handleConfirmationDialogConfirmationDisable(scheduledQuery?.userScheduledQueryId);
          setShowModal(false);
        }}
      />
    </Paper>
  );
}

export default ScheduleForm;
