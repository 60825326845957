import React, { useEffect, useMemo } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useIdleTimer } from 'react-idle-timer';
import { Redirect } from 'react-router-dom';

const AuthenticationContext = React.createContext();
AuthenticationContext.displayName = 'AuthenticationContext';

const actionTypes = {
  authenticate: 'authenticate',
  logout: 'logout',
};

const initialState = {
  firstName: 'Guillermo',
  email: 'email@domain.com',
  isAuthenticated: false,
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.authenticate: {
      return {
        ...state,
        isAuthenticated: true,
        firstName: action.payload.accessToken.claims.firstName,
        email:  action.payload.accessToken.claims.email,
      };
    }
    case actionTypes.logout: {
      return { ...initialState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function AuthenticationProvider({ children, ...props }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, dispatch] = React.useReducer(reducer, initialState);

  const { isIdle } = useIdleTimer({
    timeout: process.env.REACT_APP_ACTIVE_TIMEOUT,
  });

  useEffect(() => {
    oktaAuth.tokenManager.on('expired', () => {
      if (isIdle()) {
        oktaAuth.tokenManager.clear();
        window.location.replace(`${window.location.origin}/login`);
      } else {
        oktaAuth.tokenManager.renew('idToken');
        oktaAuth.tokenManager.renew('accessToken');
      }
    });
  });

  useEffect(() => {
    if (authState?.isAuthenticated)
      dispatch({ type: actionTypes.authenticate, payload: authState });
  }, [authState]);

  const logout = () => {
    localStorage.clear();
    // dispatch({ type: actionTypes.logout });
    oktaAuth.signOut();
  };

  return (
    <AuthenticationContext.Provider
      value={useMemo(
        () => ({ authState, oktaAuth, userInfo, logout }),
        [authState, oktaAuth, userInfo, logout],
      )}
      {...props}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

function useAuthentication() {
  const context = React.useContext(AuthenticationContext);
  if (!context) throw new Error(`useAuthentication must be used within the AuthenticationProvider`);

  return context;
}

function AuthHandler() {
  return <Redirect to="/welcome" />;
}

export { AuthenticationProvider, useAuthentication, reducer, AuthHandler, actionTypes };
