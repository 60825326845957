import { useToast } from '../contexts/Toast';
import { logError } from '../services/errorLogging.api';

const types = {
  ERROR: 'ERROR',
};

const Reducer = (state = {}, action) => {
  const { payload, type } = action;
  const { setToastState } = useToast();

  if (type === types.ERROR) {
    logError(`ERROR HANDLING UI: ${payload}`);
    setToastState({
      open: true,
      severity: 'error',
      message: `Accelerate experienced an issue \n Try again later or contact Accelerate Support \n ${payload} `,
    });
    return state;
  }

  if (payload) {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
};

export { types, Reducer };
