import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const VEHICLES_RESOURCES = RESOURCES.vehicles;

function getAvailableVehicles() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.availableVehicles}`;
  return fetchData(resource);
}

function getModelsByMakeId(makeId) {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.modelsByMakeId}/${makeId}`;
  return fetchData(resource);
}

function getMakesAndModels() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.makesAndModels}`;
  return fetchData(resource);
}

function getTypes() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.type}`;
  return fetchData(resource);
}

function getSubTypes() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.subType}`;
  return fetchData(resource);
}

function getFuels() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.fuel}`;
  return fetchData(resource);
}

function getClasses() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.class}`;
  return fetchData(resource);
}

function getClassSizes() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.classSize}`;
  return fetchData(resource);
}

function getManufacturers() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.manufacturers}`;
  return fetchData(resource);
}

function getAvailableManufacturers() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.manufacturers}`;
  return fetchData(resource);
}

function getMakesByManufacturerId(manufacturerId) {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.makesByManufacturerId}/${manufacturerId}`;
  return fetchData(resource);
}

function getManufacturersAndMakes() {
  const resource = `${API_BASE_URL}${VEHICLES_RESOURCES.manufacturersAndMakes}`;
  return fetchData(resource);
}

export {
  getAvailableVehicles,
  getModelsByMakeId,
  getMakesAndModels,
  getClasses,
  getClassSizes,
  getTypes,
  getSubTypes,
  getFuels,
  getManufacturers,
  getAvailableManufacturers,
  getMakesByManufacturerId,
  getManufacturersAndMakes
};
