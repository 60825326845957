/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QueryOptionTypes, QueryActionTypes } from '../../utils/constants/enums';
import { useAsync } from '../../utils/useAsync';
import {
  addUserQueryOption,
  updateUserQueryOption,
  existsUserQueryOptionActiveWithOptionNameForUser,
} from '../../services/usersQueriesOptions.api';
import { useToast } from '../../contexts/Toast';
import { flattenJson, getCleanQueryCopy, getReportingPeriodId } from '../../utils/objectUtils';

function SavingUpdatingAndDeleting(props) {
  const runQuery = props?.runQuery !== undefined ? props.runQuery : false;
  const isRunAndSaveDisabled =
    props?.isRunAndSaveDisabled !== undefined ? props.isRunAndSaveDisabled : true;
  const query = props?.query !== undefined ? props.query : '';
  const queryOptionTypeId = props?.queryOptionTypeId !== undefined ? props.queryOptionTypeId : 0;
  const { run: runAddUserQueryOption } = useAsync();
  const { run: runUpdateUserQueryOption } = useAsync();
  const { run: runExistsUserQueryOptionActiveWithOptionNameForUser } = useAsync();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const { setToastState } = useToast();

  function getReportIds({ chosenReports }) {
    const reportIds = [];

    const reports = Object.values(chosenReports);
    for (let i = 0; i < reports?.length; i++) {
      reportIds.push(Object.values(chosenReports)[i]?.reportId);
    }

    return reportIds;
  }

  function handleMarketOrRiskSaveOrUpdate(savingOrUpdating, object) {
    let message = '';
    if (object === undefined || object === null) {
      if (savingOrUpdating === 1) {
        message = 'Saved not completed. Try again later or contact system support.';
      } else if (savingOrUpdating === 2) {
        message = 'Update not completed. Try again later or contact system support.';
      }
    } else if (savingOrUpdating === 1) {
      message = 'Saved completed succesfully';
    } else if (savingOrUpdating === 2) {
      message = 'Update completed succesfully';
    }
    setToastState({
      open: true,
      severity: 'info',
      message,
    });
  }

  function handleSaveOrUpdate(queryActionType) {
    const reportIds = getReportIds(query);
    const cleanedQuery = getCleanQueryCopy(query);
    const userQueryOptionJSON = JSON.stringify(flattenJson(cleanedQuery));
    const userQueryOptionReportsJSON = JSON.stringify({ ...reportIds });
    const userQueryOptionNm = query.queryName.trimEnd();
    const userQueryOptionDesc = query.queryDescription.trimEnd();
    const userQueryOptionReportingPeriodsJSON = JSON.stringify(getReportingPeriodId(cleanedQuery));

    const activeIndicator = true;

    if (queryActionType === QueryActionTypes.Add) {
      if (
        queryOptionTypeId === QueryOptionTypes.MarketQuickQuery ||
        queryOptionTypeId === QueryOptionTypes.RiskQuickQuery
      ) {
        runAddUserQueryOption(
          addUserQueryOption(
            queryOptionTypeId,
            userQueryOptionReportsJSON,
            userQueryOptionNm,
            userQueryOptionJSON,
            activeIndicator,
            true,
            queryOptionTypeId === QueryOptionTypes.RiskQuickQuery ||
              queryOptionTypeId === QueryOptionTypes.RiskAdvancedQuery
              ? 'Risk'
              : 'Market',
            userQueryOptionReportingPeriodsJSON,
            userQueryOptionDesc,
          ).then((result) => {
            handleMarketOrRiskSaveOrUpdate(QueryActionTypes.Add, result);
            runQuery();
          }),
        );
      } else {
        runAddUserQueryOption(
          addUserQueryOption(
            queryOptionTypeId,
            userQueryOptionReportsJSON,
            userQueryOptionNm,
            userQueryOptionJSON,
            activeIndicator,
            false,
            queryOptionTypeId === QueryOptionTypes.RiskQuickQuery ||
              queryOptionTypeId === QueryOptionTypes.RiskAdvancedQuery
              ? 'Risk'
              : 'Market',
            userQueryOptionReportingPeriodsJSON,
            userQueryOptionDesc,
          ).then((result) => {
            handleMarketOrRiskSaveOrUpdate(QueryActionTypes.Add, result);
          }),
        );
      }
    } else if (
      queryActionType === QueryActionTypes.Update ||
      queryActionType === QueryActionTypes.UpdateAdd ||
      queryActionType === QueryActionTypes.UpdateReplace
    ) {
      let addReport = true;
      if (queryActionType === QueryActionTypes.UpdateReplace) {
        addReport = false;
      }
      if (
        queryOptionTypeId === QueryOptionTypes.MarketQuickQuery ||
        queryOptionTypeId === QueryOptionTypes.RiskQuickQuery
      ) {
        runUpdateUserQueryOption(
          updateUserQueryOption(
            queryOptionTypeId,
            userQueryOptionReportingPeriodsJSON,
            userQueryOptionReportsJSON,
            userQueryOptionNm,
            userQueryOptionJSON,
            activeIndicator,
            true,
            addReport,
            userQueryOptionDesc,
          ).then((result) => {
            handleMarketOrRiskSaveOrUpdate(QueryActionTypes.Update, result);
            runQuery();
          }),
        );
      } else {
        runUpdateUserQueryOption(
          updateUserQueryOption(
            queryOptionTypeId,
            userQueryOptionReportingPeriodsJSON,
            userQueryOptionReportsJSON,
            userQueryOptionNm,
            userQueryOptionJSON,
            activeIndicator,
            false,
            addReport,
            userQueryOptionDesc,
          ).then((result) => {
            // eslint-disable-next-line no-console
            handleMarketOrRiskSaveOrUpdate(QueryActionTypes.Update, result);
          }),
        );
        setToastState({
          open: true,
          severity: 'info',
          message: 'Update completed succesfully',
        });
      }
    }
  }

  function handleSaveOrUpdateAction() {
    runExistsUserQueryOptionActiveWithOptionNameForUser(
      existsUserQueryOptionActiveWithOptionNameForUser(
        queryOptionTypeId,
        query.queryName.trimEnd(),
      ).then((existsUserQueryOptionActiveWithOptionNameForUserData) => {
        if (existsUserQueryOptionActiveWithOptionNameForUserData === false)
          handleSaveOrUpdate(QueryActionTypes.Add);
        else setConfirmationDialogOpen(true);
      }),
    );
  }

  function handleConfirmationDialogClose() {
    setConfirmationDialogOpen(false);
  }

  function handleConfirmationDialogConfirmationReplace() {
    handleSaveOrUpdate(QueryActionTypes.UpdateReplace);
    setConfirmationDialogOpen(false);
  }

  return (
    <div className="advancedQuery__buttons">
      <Button
        id="saveReportButtonID"
        disabled={!isRunAndSaveDisabled()}
        className="quickQuery__buttonScheduleReport"
        variant="contained"
        startIcon={<SaveIcon />}
        color="primary"
        onClick={() => handleSaveOrUpdateAction()}
        data-cy="aq-Save-button"
      >
        {queryOptionTypeId === QueryOptionTypes.MarketQuickQuery ||
        queryOptionTypeId === QueryOptionTypes.RiskQuickQuery
          ? 'Save & Run Report'
          : 'Save Report'}
      </Button>

      <Dialog
        open={confirmationDialogOpen}
        onClose={() => handleConfirmationDialogClose()}
        aria-labelledby="alert-dialog-confirm"
        aria-describedby="alert-dialog-confirm-with-user"
      >
        <DialogTitle id="alert-dialog-title">Replace existing report?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Previous saved information will be overwritten.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationDialogClose()} color="primary">
            CANCEL
          </Button>
          <Button
            onClick={() => handleConfirmationDialogConfirmationReplace()}
            color="primary"
            autoFocus
          >
            REPLACE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export { SavingUpdatingAndDeleting };
