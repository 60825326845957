import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import './helpContactUs.scss';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { useAsync } from '../../utils/useAsync';
import { sendEmail } from '../../services/helpCentral.api';

function SubmitRequest({ scheduleMessage, emailAddress }) {
  const { run, status, error } = useAsync();

  const [submitText, setSubmitText] = useState();
  const [disabledButton, setDisabledButton] = useState(true);
  const [disabledButtonStyle, setDisabledButtonStyle] = useState('contactUS__buttonLowerCase');
  const [countCharacter, setCountCharacter] = useState(0);

  const handleOnChange = (event) => {
    setSubmitText(event.target.value);
    setCountCharacter(event.target.value.length);
    if (event.target.value.length < 25) {
      setDisabledButton(true);
      setDisabledButtonStyle('contactUS__buttonLowerCase');
    } else {
      setDisabledButton(false);
      setDisabledButtonStyle('contactUs__buttonEnabled');
    }
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    run(
      sendEmail(submitText).then((data) => {
        if (data?.status === 'OK') {
          setDisabledButton(true);
          setDisabledButtonStyle('contactUS__buttonLowerCase');
          setSubmitText('');
        }
      }),
    );
  };

  return (
    <div id="SubmitRequest">
      <div className="contactUs__mainFlex">
        <Paper className="contactUs__Paper" variant="outlined" elevation={3}>
          <div className="contactUs__SubmitDiv">
            <div>
              <div>
                <h6 className="contactUs__align">{scheduleMessage}</h6>
                <h6 className="contactUs__align">
                  _______________________________________________________________________________________________________________________________________
                </h6>
                <h6 className="contactUs__align">Email support requests to {emailAddress}</h6>
                <h6 className="contactUs__align contactUs__completeFormLabel">
                  or complete this form
                </h6>
              </div>
            </div>

            <form onSubmit={handleOnSubmit} className="contactUs__mainFlex">
              <div>
                <TextField
                  data-cy="help-SubmitRequest-textbox"
                  name="SummitText"
                  className="contactUs__submitText"
                  id="outlined-multiline-static"
                  label="Enter your support request here"
                  helperText={`Minimum 25. Maximum 5000 / ${countCharacter} characters.`}
                  inputProps={{ maxLength: 5000, autoFocus: true }}
                  multiline
                  rows={6}
                  onChange={handleOnChange}
                  variant="outlined"
                  value={submitText}
                />

                <div>
                  {status === 'resolved' ? (
                    <h6 className="contactUs__greenMessage">
                      The support request has been sent succesfully
                    </h6>
                  ) : (
                    <div />
                  )}
                </div>

                <div>
                  {error?.message ? (
                    <h6 className="contactUs__redMessage">Something went wrong, try it later</h6>
                  ) : (
                    <div />
                  )}
                </div>

                <div className="contactUs__align">
                  <Button
                    data-cy="help-SubmitRequest-button"
                    className={disabledButtonStyle}
                    variant="contained"
                    type="submit"
                    disabled={disabledButton}
                  >
                    Submit request
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default SubmitRequest;
