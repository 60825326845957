import './MaterialMultiSelect.scss';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Popper from '@mui/material/Popper';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material';
import { selectAll, allSelectionChangeQuery, objectFromArray } from './MaterialMultiSelect.utils';
import ListboxComponent from '../listBox/ListBoxComponent';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

function MaterialMultiSelect({
  label,
  onChange,
  options,
  limitTags,
  searchMode,
  loading,
  id,
  objectRenderField,
  queryDataType,
  selectAttribute,
  onChangeText,
  testId,
  defaultAll,
  isElementCheckedFlag,
  value,
  resetState,
  setResetState,
  hideAllCheckBox = false,
  disabled,
  className,
  verifyAllCheckbox = false,
  dataCy,
}) {
  const [checkValue, setCheckValue] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [stateLabel, setStateLabel] = useState(`No ${label} Selected`);
  const [checkState, setCheckState] = useState(searchMode || defaultAll);
  const [disableAll, setDisableAll] = useState(searchMode || defaultAll);

  // eslint-disable-next-line no-param-reassign
  options = options ?? [];

  // Sorting-----------------------------------------------------
  if (options.length > 0 && options !== undefined)
    // eslint-disable-next-line no-param-reassign
    options = options.sort((a, b) =>
      a[objectRenderField] < b[objectRenderField]
        ? -1
        : Number(a[objectRenderField] > b[objectRenderField]),
    );
  //---------------------------------------------------------------
  useEffect(() => {
    if (value) {
      if (Object.keys(value).length > 0) {
        setCheckState(false);
        setDisableAll(false);
      }
      setCheckValue(Object.values(value));
    } else {
      setCheckValue([]);
    }
  }, [value]);

  useEffect(() => {
    if (resetState === true) {
      setCheckValue([]);
      setResetState(false);
    }
    if (resetState === true && hideAllCheckBox === false) {
      setCheckState(true);
      setDisableAll(true);
    }
  }, [resetState]);

  useEffect(() => {
    if (Object.keys(checkValue).length === 0) {
      if (searchMode) {
        setStateLabel('Type to start searching');
        if (isElementCheckedFlag) isElementCheckedFlag(false);
      } else if (!checkState) {
        setStateLabel(`No ${label} Selected`);
        if (isElementCheckedFlag) isElementCheckedFlag(false);
      } else {
        setStateLabel(`All ${label} Selected`);
        if (isElementCheckedFlag) isElementCheckedFlag(true);
      }
    } else if (Object.keys(checkValue).length === options.length) {
      setStateLabel(searchMode ? 'All matches selected' : `All ${label} Selected`);
      if (isElementCheckedFlag) isElementCheckedFlag(true);
    } else {
      setStateLabel(`${label} Selected`);
      if (isElementCheckedFlag) isElementCheckedFlag(true);
    }
  }, [checkValue, disableAll, label]);

  const handleCheckChange = () => {
    if (checkState) {
      allSelectionChangeQuery(onChange, queryDataType);
    } else {
      allSelectionChangeQuery(onChange, queryDataType, {});
    }
    setDisableAll(!disableAll);
    setCheckValue([]);

    setCheckState(!checkState);
  };

  const handleChange = (e, element, typeOfChange, selection) => {
    const data = objectFromArray(element, selectAttribute);
    setCheckValue(element);
    setStateLabel(`${label} Selected`);

    onChange(
      {
        [queryDataType]: data,
      },
      queryDataType,
      selection?.option,
      typeOfChange,
    );
    if (!checkState && element.length === 0 && verifyAllCheckbox) {
      allSelectionChangeQuery(onChange, queryDataType);
    }
  };

  // Method that find the largest string of an array
  const findLargestString = (array) => {
    let largestString = '';
    array.forEach((element) => {
      if (element[objectRenderField].length > largestString.length) {
        largestString = element[objectRenderField];
      }
    });
    return largestString;
  };

  const largestString = findLargestString(options);
  let paperWidth = largestString.length * 12 + 50;
  paperWidth = paperWidth < 130 ? 130 : paperWidth;
  paperWidth = paperWidth > 540 ? 540 : paperWidth;

  return (
    <Grid container direction="column" spacing={6}>
      <Grid container item direction="row">
        <Grid item xs>
          <Autocomplete
            className={className}
            multiple
            id={id}
            disabled={disableAll || disabled}
            test-id={`multiSelect${testId}`}
            data-cy={`${dataCy}-select`}
            limitTags={limitTags}
            value={checkValue}
            options={options}
            PopperComponent={StyledPopper}
            isOptionEqualToValue={(option, v) => option[selectAttribute] === v[selectAttribute]}
            disableCloseOnSelect
            ListboxComponent={ListboxComponent}
            getOptionLabel={(option) => option[objectRenderField]}
            onChange={handleChange}
            onInputChange={(event, v) => onChangeText(v)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option[objectRenderField]}
              </li>
            )}
            renderInput={(params) => {
              // eslint-disable-next-line no-param-reassign
              params.inputProps.autoComplete = 'no';
              return (
                <TextField
                  {...params}
                  data-cy={`${dataCy}-textbox`}
                  variant="outlined"
                  label={stateLabel}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              );
            }}
            componentsProps={{
              paper: { sx: { width: paperWidth }}
            }}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            test-id={`multiSelectAllCheckBox-label-${testId}`}
            data-cy={`${dataCy}-checkboxlabel`}
            control={
              <Checkbox
                id={`multiSelectAllCheckBox__${testId}`}
                checked={checkState}
                onChange={handleCheckChange}
                data-cy={`${dataCy}-checkbox`}
              />
            }
            label="All"
            labelPlacement="bottom"
            hidden={hideAllCheckBox}
          />
        </Grid>
        <Grid item display="flex" alignSelf="center">
          {searchMode && (
            <Button
              disabled={disableAll || disabled}
              className="multiSelectAddMatchesButton"
              variant="contained"
              size="small"
              data-cy={`${dataCy}-button`}
              test-id={`multiSelectAllButton${testId}`}
              onClick={() =>
                selectAll(
                  checkValue,
                  options,
                  onChange,
                  searchMode,
                  queryDataType,
                  setCheckValue,
                  selectAttribute,
                )
              }
            >
              {searchMode ? 'Add Matches' : 'All'}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

MaterialMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  searchMode: PropTypes.bool,
  limitTags: PropTypes.number,
  loading: PropTypes.bool,
  onChangeText: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectRenderField: PropTypes.string.isRequired,
  queryDataType: PropTypes.string.isRequired,
  selectAttribute: PropTypes.string.isRequired,
  value: PropTypes.shape({}),
  defaultAll: PropTypes.bool,
  isElementCheckedFlag: PropTypes.func,
};

MaterialMultiSelect.defaultProps = {
  id: undefined,
  loading: false,
  limitTags: 3,
  searchMode: false,
  defaultAll: true,
  options: [],
  value: null,
  isElementCheckedFlag: () => {},
  onChangeText: () => {},
};

export default MaterialMultiSelect;
