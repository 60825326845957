/* eslint-disable no-param-reassign */
import React from 'react';
import Moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import './SavedQueryTable.scss';

import {
  UsersScheduledQueriesFrequencyTypes,
  UsersScheduledQueriesFormatTypes,
  UsersScheduledQueriesDeliveryTypes,
} from '../../utils/constants/enums';

function handleReportSelectChange(
  userQueryOptionId,
  tableIndex,
  event,
  reportSelectDictionary,
  reportSelectArray,
  setReportSelectArray,
  setReportSelectDictionary,
) {
  const objIndex = reportSelectDictionary.findIndex((item) => item.key === userQueryOptionId);
  reportSelectDictionary[objIndex].value = parseInt(event.target.value);
  const newReportSelectArray = [...reportSelectDictionary];
  newReportSelectArray[tableIndex] = parseInt(event.target.value);
  reportSelectArray[tableIndex] = parseInt(event.target.value);
  setReportSelectArray(newReportSelectArray);
  setReportSelectDictionary(reportSelectDictionary);
}

function getUserQueryReportsArrayObject(reportIds, reportNms) {
  const reportIdsArray = reportIds?.split(',');
  const reportNamesArray = reportNms?.split(',');
  if (reportIdsArray == null) {
    return [];
  }
  return reportIdsArray?.map((id, index) => ({
    reportId: id,
    reportNm: reportNamesArray[index],
  }));
}

function getKeyByValue(object, value) {
  const response = Object.keys(object).find((key) => object[key] === parseInt(value));
  return response;
}

const getReportSelectValue = (userQueryOptionId, reportSelectDictionary) =>
  reportSelectDictionary?.find((item) => item.key === userQueryOptionId)?.value;
const columns = (
  reportSelectArray,
  reportSelectDictionary,
  setReportSelectArray,
  setReportSelectDictionary,
) => [
  {
    title: 'Report',
    field: 'userQueryOptionNm',
    render: ({ userQueryOptionNm }) => (
      <span className="query-name" style={{ whiteSpace: 'pre' }}>
        {userQueryOptionNm.length > 50
          ? `${userQueryOptionNm.substring(0, 47)}...`
          : userQueryOptionNm}
      </span>
    ),
  },
  {
    title: 'Report Area',
    field: 'queryOptionTypeNm',
    lookup: {
      Market: 'Market',
      Risk: 'Risk',
    },
    render: ({ queryOptionTypeNm }) => <span className="query-name">{queryOptionTypeNm}</span>,
  },
  {
    title: 'Report Type',
    field: 'usersQueriesOptionsReportNms',
    filtering: false,
    render: ({
      tableData,
      userQueryOptionId,
      usersQueriesOptionsReportIds,
      usersQueriesOptionsReportNms,
    }) => (
      <select
        test-id={`reportColumn${tableData?.id}`}
        onChange={(e) =>
          handleReportSelectChange(
            userQueryOptionId,
            tableData?.id,
            e,
            reportSelectDictionary,
            reportSelectArray,
            setReportSelectArray,
            setReportSelectDictionary,
          )
        }
        value={reportSelectArray[tableData?.id]}
        name="Reports"
        className="reportType__select"
        data-cy="reportType_select"
      >
        {getUserQueryReportsArrayObject(
          usersQueriesOptionsReportIds,
          usersQueriesOptionsReportNms,
        ).map((report, index) => (
          <option key={index} value={index}>
            {report?.reportNm}
          </option>
        ))}
      </select>
    ),
  },
  {
    title: 'State',
    field: 'usersQueriesOptionsStateNms',
    render: ({ usersQueriesOptionsStateNms }) => {
      let text = '';
      if (usersQueriesOptionsStateNms?.length > 0)
        text =
          usersQueriesOptionsStateNms?.length > 50
            ? `${usersQueriesOptionsStateNms?.substring(0, 47)}...`
            : usersQueriesOptionsStateNms;
      return <span className="query-name">{text}</span>;
    },
  },
  {
    title: 'Description',
    field: 'userQueryOptionDesc',
    render: ({ userQueryOptionDesc }) => (
      <span className="query-name" style={{ whiteSpace: 'pre' }}>
        {userQueryOptionDesc && userQueryOptionDesc.length > 50
          ? `${userQueryOptionDesc.substring(0, 47)}...`
          : userQueryOptionDesc}
      </span>
    ),
  },
  {
    title: 'Frequency',
    field: 'frequency',
    defaultSort: 'desc',
    filtering: false,
    render: ({ userScheduledQueryFrequencyTypeId }) => (
      <span className="query-name">
        {getKeyByValue(UsersScheduledQueriesFrequencyTypes, userScheduledQueryFrequencyTypeId)}
      </span>
    ),
  },
  {
    title: 'Format',
    field: 'format',
    defaultSort: 'desc',
    filtering: false,
    render: ({ userScheduledQueryFormatTypeId }) => (
      <span className="query-name">
        {getKeyByValue(UsersScheduledQueriesFormatTypes, userScheduledQueryFormatTypeId)}
      </span>
    ),
  },
  {
    title: 'Delivery',
    field: 'deliveryOption',
    defaultSort: 'desc',
    filtering: false,
    render: ({ userScheduledQueryDeliveryTypeId }) => (
      <span className="query-name">
        {getKeyByValue(UsersScheduledQueriesDeliveryTypes, userScheduledQueryDeliveryTypeId)}
      </span>
    ),
  },

  {
    title: 'Last Run',
    field: 'lastRun',
    defaultSort: 'desc',
    filtering: false,
    render: ({ lastRun }) => (
      <span className="query-name">
        {lastRun === undefined || lastRun === null ? '' : Moment(lastRun).format('MM-DD-YYYY')}
      </span>
    ),
  },
];

const actions = (
  prepareAndRunQuery,
  prepareAndEditQuery,
  reportSelectDictionary,
  setShowConfirmationModal,
  setCurrentRow,
) => [
  {
    icon: () => <PlayCircleOutline
      data-cy="saved-query-table-run-online-button"
    />,
    tooltip: 'Run Online',
    onClick: (event, rowData) =>
      prepareAndRunQuery(
        getReportSelectValue(rowData?.userQueryOptionId, reportSelectDictionary),
        rowData?.userQueryOptionId,
        rowData?.userQueryOptionNm,
        rowData?.usersQueriesOptionsReportIds,
        rowData?.usersQueriesOptionsReportNms,
        rowData?.usersQueriesOptionsReportTypeIds,
        rowData?.userQueryOptionJSON,
        rowData?.queryOptionTypeId,
        rowData?.userQueryOptionDesc,
      ),
  },
  {
    icon: () => <EditIcon
      data-cy="saved-query-table-edit-button"
    />,
    tooltip: 'Edit',
    onClick: (event, rowData) => {
      prepareAndEditQuery(
        getReportSelectValue(rowData?.userQueryOptionId, reportSelectDictionary),
        rowData?.userQueryOptionId,
        rowData?.userQueryOptionNm,
        rowData?.usersQueriesOptionsReportIds,
        rowData?.usersQueriesOptionsReportNms,
        rowData?.usersQueriesOptionsReportTypeIds,
        rowData?.userQueryOptionJSON,
        rowData?.queryOptionTypeId,
        rowData?.userQueryOptionDesc,
      );
    },
  },
  {
    icon: () => <DeleteIcon
      data-cy="saved-query-table-delete-button"
    />,
    tooltip: 'Delete',
    onClick: (event, rowData) => {
      setCurrentRow(rowData);
      setShowConfirmationModal(true);
    },
  },
];

export { columns, getReportSelectValue, getUserQueryReportsArrayObject, actions };
