import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';
import { Modal, Box } from '@mui/material';
import {
  getUsersQueriesOptionsActive,
  disableUserQueryOption,
  getClientUsersListForSchedulingScreen,
} from '../../services/usersQueriesOptions.api';
import {
  QueryOptionTypes,
  UserQueryOptionRunSourceTypes,
  TerritoryTypes,
} from '../../utils/constants/enums';
import { useAsync } from '../../utils/useAsync';
import { getReports } from '../../services/reportSelection.api';
import { actions, columns } from './SavedQueryTable.utils';
import { useTableauQuery } from '../../contexts/TableauQuery';
import { routes as marketRoutes } from '../../utils/constants/market.routes';
import { routes as riskRoutes } from '../../utils/constants/risk.routes';
import { StoreContext } from '../../store/storeContext';
import { validateJsonAndGetTicket } from '../../components/tableauVizualization/queryValidation';
import { useToast } from '../../contexts/Toast';
import { TableIcons } from './TableIcons';
import ScheduleForm from '../scheduleQuery/ScheduleForm';
import DeleteSavedQueryModal from './deleteSavedQueryModal';
import { getDataAccessClientsReportTypesActive } from '../../services/dataAccess.api';
import SelectReportToRun from '../../components/advancedQuery/components/selectReportToRun/SelectReportToRun';

function SavedQueryTable() {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [, setContextQuery] = useTableauQuery();
  const history = useHistory();
  const { setToastState } = useToast();
  const [reports, setReports] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [availableUsersQueriesOptionsActive, setAvailableUsersQueriesOptionsActive] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const deleteMessage = `Delete ${currentRow.userQueryOptionNm}?`;
  const [clientType, setClientType] = React.useState(2);
  const [refreshTable, setRefreshTable] = useState(false);
  const [initializeEmails, setInitializeEmails] = React.useState([]);
  // const [chosenReports, setChosenReports] = React.useState({});
  const [queryRebuildTemp, setQueryRebuildTemp] = React.useState({});

  const [reportSelectDictionary, setReportSelectDictionary] = useState([]);
  const [reportSelectArray, setReportSelectArray] = useState([]);
  const { actions: actionsContext } = useContext(StoreContext);
  const { run: runGetDataAccessClientsReportTypesActive } = useAsync();
  const [dataAccessClientsReportTypesActive, setDataAccessClientsReportTypesActive] = useState(0);
  const [openExecuterModal, setOpenExecuterModal] = useState(false);
  const handleCloseModal = () => setOpenExecuterModal(false);
  const [queryOptionTypeIdForSelectReportToRun, setQueryOptionTypeIdForSelectReportToRun] =
    useState();

  const runQuery = (query, queryOptionTypeId) => {
    setContextQuery(query);
    if (
      dataAccessClientsReportTypesActive === 1 &&
      queryOptionTypeId !== QueryOptionTypes.MarketAdvancedQuery
    ) {
      setToastState({
        open: true,
        severity: 'error',
        status: 401,
        message: 'Risk reports are no longer available with this licensing option.',
      });
    } else {
      history.push({
        pathname:
          queryOptionTypeId === QueryOptionTypes.MarketAdvancedQuery
            ? marketRoutes.reportDisplay.path
            : riskRoutes.reportDisplay.path,
      });
    }
  };

  const handleOpenModalOrRunQuery = (query, queryOptionTypeId, savedQueryJSONUnparsed) => {
    if (Object.keys(query?.chosenReports).length > 1) {
      setOpenExecuterModal(true);
      return;
    }
    if (Object.keys(query?.chosenReports).length === 1) {
      const reportId = Object.keys(query?.chosenReports)[0];
      if (
        savedQueryJSONUnparsed &&
        savedQueryJSONUnparsed !== '' &&
        query?.riskOptionsSelection?.riskDynamicFields
      ) {
        try {
          const riskDynamicFieldsForReportId =
            JSON.parse(savedQueryJSONUnparsed)?.riskOptionsSelection?.riskDynamicFields[reportId];
          /*
            v1 risk options or non risk option reports can't have more than 1 risk control.
          */
          if (riskDynamicFieldsForReportId === undefined) {
            runQuery(query, queryOptionTypeId);
          }
          const riskDynamicFieldsForReportControlName = Object.keys(
            riskDynamicFieldsForReportId,
          )[0];
          if (
            Object.values(riskDynamicFieldsForReportId[riskDynamicFieldsForReportControlName])
              .length > 1
          ) {
            setQueryOptionTypeIdForSelectReportToRun(queryOptionTypeId);
            setOpenExecuterModal(true);
            return;
          }
        } catch (err) {
          return;
        }
      }
      runQuery(query, queryOptionTypeId);
    }
  };

  const editQuery = async (query, queryOptionTypeId, finalAction = 'edit') => {
    let response;
    try {
      response = await validateJsonAndGetTicket(queryOptionTypeId, query, 'edit');
      const newQuery = JSON.parse(response.userQueryOptionJSON);
      if (response.userQueryOptionStatusTypeId !== 1) {
        setToastState({
          open: true,
          severity: 'error',
          status: response.userQueryOptionStatusTypeId,
          message: response.userQueryOptionStatusAdditionalDetails,
        });
        if (response.userQueryOptionStatusTypeId !== 9)
        return;
      }
      newQuery.reportingPeriods.beginDateRange = [newQuery.reportingPeriods.beginDateRange];
      newQuery.reportingPeriods.endDateRange = [newQuery.reportingPeriods.endDateRange];
      newQuery.chosenReports = query.chosenReports;
      newQuery.queryType = queryOptionTypeId;
      newQuery.queryName = query.queryName;
      newQuery.queryDescription = query.queryDescription;
      newQuery.userQueryOptionId = '';

      if (
        newQuery?.territorySelection?.territoryTypes === undefined &&
        newQuery?.territorySelection?.states === undefined
      ) {
        newQuery.territorySelection = { territoryTypes: { 1: 1 }, states: {} };
      } else if (newQuery?.territorySelection?.states === undefined) {
        let territoryTypesFullValue = { territoryTypes: { 1: 1 } };
        if (
          newQuery?.territorySelection?.territoryTypes !== undefined &&
          TerritoryTypes.Dealer in newQuery.territorySelection.territoryTypes
        ) {
          territoryTypesFullValue = { territoryTypes: { 2: 2 } };
        }
        newQuery.territorySelection = { ...territoryTypesFullValue, states: {} };
      } else {
        // if (newQuery?.territorySelection?.territoryTypes === undefined) {
        let territoryTypesValueOnly = { 1: 1 };
        if (
          newQuery?.territorySelection?.territoryTypes !== undefined &&
          TerritoryTypes.Dealer in newQuery.territorySelection.territoryTypes
        ) {
          territoryTypesValueOnly = { 2: 2 };
        }
        newQuery.territorySelection.territoryTypes = territoryTypesValueOnly;
      }

      window.localStorage.setItem(`${queryOptionTypeId}-aq`, JSON.stringify(newQuery));
    } catch (e) {
      actions.errorAction(e);
    }
    if (finalAction === 'edit') {
      history.push({
        pathname:
          queryOptionTypeId === QueryOptionTypes.MarketAdvancedQuery
            ? marketRoutes.advancedQuery.path
            : riskRoutes.advancedQuery.path,
      });
    }
  };

  function getQueries() {
    getUsersQueriesOptionsActive()
      .then((availableUsersQueriesOptionsActiveData) => {
        setAvailableUsersQueriesOptionsActive(
          availableUsersQueriesOptionsActiveData.map((queryActive) => {
            const item = queryActive;
            if (!queryActive?.usersQueriesOptionsStateNms) {
              item.usersQueriesOptionsStateNms = 'All';
            }
            return item;
          }),
        );
        setLoading(false);

        const newReportSelectDictionary = [];
        const newReportSelectArray = [];
        for (let i = 0; i < availableUsersQueriesOptionsActiveData.length; i++) {
          newReportSelectDictionary.push({
            key: availableUsersQueriesOptionsActiveData[i].userQueryOptionId,
            value: 0,
          });
          newReportSelectArray.push(0);
        }
        setReportSelectDictionary(newReportSelectDictionary);
        setReportSelectArray(newReportSelectArray);
      })
      .catch((error) => {
        actionsContext.errorAction(error);
      });
  }

  useEffect(() => {
    getReports(process.env.REACT_APP_PRODUCTS_PRODUCT_ID_AUTOCOUNT_ACCELERATE).then(
      (getReportsData) => {
        setReports(getReportsData);
      },
    );
    getQueries();

    getClientUsersListForSchedulingScreen().then((availableUsersData) => {
      availableUsersData?.clientUsersList.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.userScheduledQueryEmailElementTypeId = 1;
      });
      setInitializeEmails(availableUsersData?.clientUsersList);
      setClientType(availableUsersData?.clientTypeId);
    });

    runGetDataAccessClientsReportTypesActive(
      getDataAccessClientsReportTypesActive().then((getDataAccessClientsReportTypesActiveData) => {
        if (
          getDataAccessClientsReportTypesActiveData !== undefined &&
          getDataAccessClientsReportTypesActiveData.length > 0
        ) {
          setDataAccessClientsReportTypesActive(
            getDataAccessClientsReportTypesActiveData[
              getDataAccessClientsReportTypesActiveData.length - 1
            ].reportTypeId,
          );
        } else {
          setDataAccessClientsReportTypesActive(0);
        }
      }),
    );
  }, []);

  function handleDisableUserQueryOptionButtonClick(rowData) {
    if (rowData?.userQueryOptionId !== undefined && rowData?.queryOptionTypeId !== undefined) {
      disableUserQueryOption(rowData?.userQueryOptionId, rowData?.queryOptionTypeId)
        .then(() => {
          getQueries();
        })
        .catch((error) => {
          actionsContext.errorAction(error);
        });
    }
  }

  function prepareAndRunQuery(
    reportIdSelectionIndex,
    userQueryOptionId,
    userQueryOptionNm,
    usersQueriesOptionsReportIds,
    usersQueriesOptionsReportTypeIds,
    usersQueriesOptionsReportNms,
    savedQueryJSONUnparsed,
    queryOptionTypeId,
  ) {
    try {
      const usersQueriesOptionsReportIdsArray = usersQueriesOptionsReportIds.split(',');
      const queryRebuild = {};
      const reportId = parseInt(usersQueriesOptionsReportIdsArray[reportIdSelectionIndex]);
      const reportData = reports.find((item) => item.reportId === reportId);
      const newReportData = {
        [reportId]: {
          reportId,
          reportNm: reportData.reportNm,
          filterSheetNm: reportData.filterSheetNm,
          visualizationView: reportData.visualizationView,
          pdfView: reportData.pdfView,
          csvView: reportData.csvView,
          imageView: reportData.imageView,
          reportType: reportData.reportType
        },
      };
      queryRebuild.chosenReports = newReportData;
      queryRebuild.userQueryOptionRunSourceTypeId = UserQueryOptionRunSourceTypes.MyReports;

      queryRebuild.savedQueryId = userQueryOptionId;
      queryRebuild.queryName = userQueryOptionNm;

      // runQuery(queryRebuild, queryOptionTypeId);
      queryRebuild.riskOptionsSelection = JSON.parse(savedQueryJSONUnparsed)?.riskOptionsSelection;
      setQueryRebuildTemp(queryRebuild);
      editQuery(queryRebuild, queryOptionTypeId, 'run');
      handleOpenModalOrRunQuery(queryRebuild, queryOptionTypeId, savedQueryJSONUnparsed);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  function prepareAndEditQuery(
    reportIdSelectionIndex,
    userQueryOptionId,
    userQueryOptionNm,
    usersQueriesOptionsReportIds,
    usersQueriesOptionsReportTypeIds,
    usersQueriesOptionsReportNms,
    savedQueryJSONUnparsed,
    queryOptionTypeId,
    userQueryOptionDesc,
  ) {
    try {
      const usersQueriesOptionsReportIdsArray = usersQueriesOptionsReportIds.split(',');
      const queryRebuild = {};
      queryRebuild.userQueryOptionRunSourceTypeId = UserQueryOptionRunSourceTypes.MyReports;
      const numberArray = usersQueriesOptionsReportIdsArray.map(Number);
      const reportData = reports.filter((item) => numberArray.indexOf(item.reportId) !== -1);
      queryRebuild.chosenReports = reportData.reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.reportId] = item;
        return obj;
      }, {});

      queryRebuild.userQueryOptionRunSourceTypeId = UserQueryOptionRunSourceTypes.MyReports;

      queryRebuild.savedQueryId = userQueryOptionId;
      queryRebuild.queryName = userQueryOptionNm;
      queryRebuild.queryDescription = userQueryOptionDesc;

      editQuery(queryRebuild, queryOptionTypeId);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  useEffect(() => {
    setRefreshTable(false);
  }, [refreshTable]);

  return (
    <div>
      <Modal
        open={openExecuterModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SelectReportToRun
            // onChange={handleQueryOnChange}
            query={queryRebuildTemp}
            setContextQuery={setContextQuery}
            // reportPath={reportPath}
            caller="My Reports"
            queryOptionTypeId={queryOptionTypeIdForSelectReportToRun}
            callerRunQuery={runQuery}
          />
        </Box>
      </Modal>
      <MaterialTable
        icons={TableIcons}
        detailPanel={[
          {
            tooltip: 'Schedule',
            render: (rowData) => (
              <ScheduleForm
                rowData={rowData}
                availableUsersQueriesOptionsActive={availableUsersQueriesOptionsActive}
                setAvailableUsersQueriesOptionsActive={setAvailableUsersQueriesOptionsActive}
                setRefreshTable={setRefreshTable}
                clientType={clientType}
                initializeEmails={initializeEmails}
              />
            ),
          },
        ]}
        test-id="savedQueriesTable"
        title="My Saved Queries"
        isLoading={isLoading}
        options={{
          pageSize: 10,
          search: false,
          toolbar: false,
          filtering: true,
          showTitle: false,
          doubleHorizontalScroll: true,
        }}
        columns={columns(
          reportSelectArray,
          reportSelectDictionary,
          setReportSelectArray,
          setReportSelectDictionary,
        )}
        data={availableUsersQueriesOptionsActive}
        actions={actions(
          prepareAndRunQuery,
          prepareAndEditQuery,
          reportSelectDictionary,
          setShowModal,
          setCurrentRow,
        )}
      />
      <DeleteSavedQueryModal
        title="Delete saved report"
        text={deleteMessage}
        show={showModal}
        onHideModal={() => {
          setShowModal(false);
        }}
        onConfirmAction={() => {
          handleDisableUserQueryOptionButtonClick(currentRow);
          setShowModal(false);
        }}
      />
    </div>
  );
}

export default SavedQueryTable;
