import { FormLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../../../formField/FormField';
import MaterialMultiSelect from '../../../../materialMultiSelect/MaterialMultiSelect';

function RiskControlMultiSelect({
                      id,
                      controlName,
                      availableRiskControls,
                      riskControlSelection,
                      resetState,
                      setResetState,
                      handleRiskControlChange,
                    }) {
  return (
    <>
      <FormField
        fieldTitle=""
        fieldInput={
          <MaterialMultiSelect
            id={id}
            testId="RiskControlMultiSelect"
            options={availableRiskControls}
            label="Risk Control"
            objectRenderField="valueText"
            onChange={handleRiskControlChange}
            queryDataType={controlName}
            selectAttribute="id"
            value={riskControlSelection}
            resetState={resetState}
            setResetState={setResetState}
            defaultAll={false}
            hideAllCheckBox
          />
        }
      />

      <FormLabel>
        {availableRiskControls?.length === undefined || availableRiskControls?.length === 0
          ? 'No risk control option available.'
          : ''}
      </FormLabel>
    </>
  );
}

RiskControlMultiSelect.propTypes = {
  id: PropTypes.string.isRequired,
  availableRiskControls: PropTypes.arrayOf(PropTypes.shape({})),
  riskControlSelection: PropTypes.shape({}),
  handleRiskControlChange: PropTypes.func.isRequired,
};

RiskControlMultiSelect.defaultProps = {
  availableRiskControls: [],
  riskControlSelection: {},
};

export default RiskControlMultiSelect;
