import MarketReports from '../../views/marketReport/MarketReport';
import RiskReports from '../../views/riskReport/RiskReport';
import HomePage from '../../views/homePage/HomePage';
import HelpManager from '../../views/help/HelpManager';
import SavedQueryTable from '../../views/savedQuery/SavedQueryTable';
import SavedFilesTable from '../../views/savedFiles/SavedFilesTable';

const LAYOUT = '/autocount/';

const routes = {
  home: {
    path: `${LAYOUT}home`,
    name: 'Home',
    component: HomePage,
    exact: false,
    defaultPathOption: '/notifications',
  },
  myReports: {
    path: `${LAYOUT}my-reports`,
    name: 'My Reports',
    component: SavedQueryTable,
  },
  myFiles: {
    path: `${LAYOUT}my-files`,
    name: 'My Files',
    component: SavedFilesTable,
  },

  helpCentral: {
    path: `${LAYOUT}help-central`,
    name: 'Help',
    exact: false,
    component: HelpManager,
  },
};

const routesWithMarketTab = {
  home: {
    path: `${LAYOUT}home`,
    name: 'Home',
    component: HomePage,
    exact: false,
    defaultPathOption: '/notifications',
  },
  market: {
    path: `${LAYOUT}market`,
    name: 'Market Report',
    component: MarketReports,
    exact: false,
    defaultPathOption: '/advanced-query',
  },
  myReports: {
    path: `${LAYOUT}my-reports`,
    name: 'My Reports',
    component: SavedQueryTable,
  },
  myFiles: {
    path: `${LAYOUT}my-files`,
    name: 'My Files',
    component: SavedFilesTable,
  },

  helpCentral: {
    path: `${LAYOUT}help-central`,
    name: 'Help',
    exact: false,
    component: HelpManager,
  },
};

const routesWithMarketAndtRiskTabs = {
  home: {
    path: `${LAYOUT}home`,
    name: 'Home',
    component: HomePage,
    exact: false,
    defaultPathOption: '/notifications',
  },
  market: {
    path: `${LAYOUT}market`,
    name: 'Market Report',
    component: MarketReports,
    exact: false,
    defaultPathOption: '/advanced-query',
  },
  risk: {
    path: `${LAYOUT}risk`,
    name: 'Risk Report',
    component: RiskReports,
    exact: false,
    defaultPathOption: '/advanced-query',
  },
  myReports: {
    path: `${LAYOUT}my-reports`,
    name: 'My Reports',
    component: SavedQueryTable,
  },
  myFiles: {
    path: `${LAYOUT}my-files`,
    name: 'My Files',
    component: SavedFilesTable,
  },

  helpCentral: {
    path: `${LAYOUT}help-central`,
    name: 'Help',
    exact: false,
    component: HelpManager,
  },
};

export { routes, routesWithMarketTab, routesWithMarketAndtRiskTabs, LAYOUT };
