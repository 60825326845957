import { fetchData, fetchFileData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const USERS_QUERY_OPTIONS_RESOURCES = RESOURCES.usersScheduledQueries;

function getUserScheduledQueryByUserQueryOptionId(userQueryOptionId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUserScheduledQueryByUserQueryOptionId}/${userQueryOptionId}`;
  return fetchData(resource);
}

function getUsersScheduledQueriesActive(reportTypeId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUsersScheduledQueriesActive}/${reportTypeId}`;
  return fetchData(resource);
}

function getUserScheduledQueryBundleActive(userScheduledQueryId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUserScheduledQueryBundleActive}/${userScheduledQueryId}`;
  return fetchData(resource);
}

function getUsersScheduledQueriesBundleActive(reportTypeId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUsersScheduledQueriesBundleActive}/${reportTypeId}`;
  return fetchData(resource);
}

function existsUserScheduledQueryActiveWithUserScheduledQueryNameForUser(userScheduledQuerynNm) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.existsUserScheduledQueryActiveWithUserScheduledQueryNameForUser}/${userScheduledQuerynNm}`;
  return fetchData(resource);
}

const getUsersScheduledQueriesReportFilesActiveByUser = () => {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUsersScheduledQueriesReportFilesActiveByUser}`;
  return fetchData(resource);
}

const getUserScheduledQueryReportFile = (userScheduledQueryReportFileId) => {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUserScheduledQueryReportFile}/${userScheduledQueryReportFileId}`;
  return fetchFileData(resource);
}

const disableUserScheduledQueryReportFile = (userScheduledQueryReportFileId) =>  {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.disableUserScheduledQueryReportFile}/${userScheduledQueryReportFileId}`;
  return fetchData(resource);
}



function addUserScheduledQuery(
  usersScheduledQueriesRequest,
  usersScheduledQueriesEmailsRequest,
  usersScheduledQueriesSFTPsOrSTSsRequest,
) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.addUserScheduledQuery}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      userScheduleQueryId: null,
      usersScheduledQueriesRequest,
      usersScheduledQueriesEmailsRequest,
      usersScheduledQueriesSFTPsOrSTSsRequest,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

function updateUserScheduledQuery(
  userScheduleQueryId,
  usersScheduledQueriesRequest,
  usersScheduledQueriesEmailsRequest,
  usersScheduledQueriesSFTPsOrSTSsRequest,
) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.updateUserScheduledQuery}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      userScheduleQueryId,
      usersScheduledQueriesRequest,
      usersScheduledQueriesEmailsRequest,
      usersScheduledQueriesSFTPsOrSTSsRequest,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

function disableUserScheduledQuery(userScheduledQueryId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.disableUserScheduledQuery}/${userScheduledQueryId}`;
  return fetchData(resource);
}

function addUserScheduledQueryAdditionalActionSendNow(userScheduledQueryId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.addUserScheduledQueryAdditionalActionSendNow}/${userScheduledQueryId}`;
  return fetchData(resource);
}

export {
  getUserScheduledQueryByUserQueryOptionId,
  getUsersScheduledQueriesActive,
  getUserScheduledQueryBundleActive,
  getUsersScheduledQueriesBundleActive,
  existsUserScheduledQueryActiveWithUserScheduledQueryNameForUser,
  addUserScheduledQuery,
  updateUserScheduledQuery,
  disableUserScheduledQuery,
  addUserScheduledQueryAdditionalActionSendNow,
  getUsersScheduledQueriesReportFilesActiveByUser,
  getUserScheduledQueryReportFile,
  disableUserScheduledQueryReportFile
};
