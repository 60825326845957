import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import './helpReference.scss';
import { REFERENCE_MATERIALS } from '../../utils/constants/constants';
import { getFile } from '../../services/documents.api';

function LinkButtonsSection({ contactUsData }) {
  const { linkExperianAutomotive, linkPrivacyPolicy, linkTermsConditions } = contactUsData;
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState('');

  function downloadFile(referenceMaterial) {
    const { fileName, title, extension } = referenceMaterial;
    return function download() {
      setIsLoading(true);
      setFilename(fileName);
      getFile(fileName)
      .then((res) => {
        res.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = `${title}.${extension}`;
          alink.click();
          setIsLoading(false);
        });
      })
        .catch(() => {
          setIsLoading(false);
          setFilename('');
        });
    };
  }

  function openLink(link) {
    return () => window.location.href = link;
  }

  return (
    <div>
      <div className="reference__subtitleJustification">
        <h2 className="reference__subtitle">What can we help you with?</h2>
      </div>

      <div className="marquee__mainFlex">
        <div className="reference__mainFlex reference__HashLinkButtonSpace">
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <div className="buttonContainer">
              <Button
                data-cy="help-UserGuide-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.userGuide)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.userGuide.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.userGuide.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'User Guide'
                )}
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-ReportGuide-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.reportGuide)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.reportGuide.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.reportGuide.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'Report Guide'
                )}
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-DataDictionary-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.dataDictionary)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.dataDictionary.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.dataDictionary.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'Data Dictionary'
                )}
              </Button>
            </div>
          </Grid>

          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <div className="buttonContainer">
              <Button
                data-cy="help-Faqs-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.FAQs)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.FAQs.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.FAQs.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'FAQs'
                )}
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-AutocountDifferences-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.autocountDifferences)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.autocountDifferences.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.autocountDifferences.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'Autocount Differences'
                )}
              </Button>
            </div>
            <div className="buttonContainer">
              <Button
                data-cy="help-StateData-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.stateDMVData)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.stateDMVData.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.stateDMVData.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'State DMV Data'
                )}
              </Button>
            </div>
            <div className="buttonContainer">
              <Button
                data-cy="help-ReleaseNotes-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={downloadFile(REFERENCE_MATERIALS.releaseNotes)}
                disabled={isLoading && filename === REFERENCE_MATERIALS.releaseNotes.fileName}
              >
                {isLoading && filename === REFERENCE_MATERIALS.releaseNotes.fileName ? (
                  <CircularProgress size={24} color="white" />
                ) : (
                  'Release Notes'
                )}
              </Button>
            </div>
          </Grid>
          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <div className="buttonContainer">
              <Button
                data-cy="help-TrainingNavigation-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink('https://bcove.video/3jYWjKj')}
              >
                Training - Navigation
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-TrainingCreateReports-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink('https://bcove.video/3v3aynN')}
              >
                Training - Create Reports
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-TrainingRun&Save-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink('https://bcove.video/3Oxc9tY')}
              >
                Training - Run & Save Reports
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-TrainingManagingReports-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink('https://bcove.video/3vz8aV0')}
              >
                Training - Managing Reports
              </Button>
            </div>
          </Grid>

          <Grid container direction="column" justifyContent="flex-start" alignItems="center">
            <div className="buttonContainer">
              <Button
                data-cy="help-ExperianAutomotive-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink(linkExperianAutomotive)}
              >
                Experian Automotive
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-Terms&Conditions-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink(linkTermsConditions)}
              >
                Terms & Conditions
              </Button>
            </div>

            <div className="buttonContainer">
              <Button
                data-cy="help-PrivacyPolicy-button"
                className="reference__buttonHashLink"
                size="medium"
                color="primary"
                variant="text"
                onClick={openLink(linkPrivacyPolicy)}
              >
                Privacy Policy
              </Button>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default LinkButtonsSection;
