import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import Paper from '@mui/material/Paper';
import SpotlightButton from '../spotlightButton/SpotlightButton';
import './StandardErrorComponent.scss';

function StandardErrorComponent({ text, retryFunction }) {
  return (
    <Paper elevation={7} className="errorPaper">
      <h2>
        {text}
        <WarningIcon className="errorIcon" />
      </h2>
      <SpotlightButton onClick={retryFunction} text="Retry" />
    </Paper>
  );
}

export default StandardErrorComponent;
