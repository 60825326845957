import React from 'react';
import TextField from '@mui/material/TextField';
import './TextField.scss';

function CustomTextField({ onChange, value, label, testId, dataCy, ...props }) {
  return (
    <TextField
      test-id={`textBox${testId}`}
      data-cy={dataCy}
      className="textField"
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      label={!value && label}
      InputLabelProps={{ shrink: false }}
      value={value}
      {...props}
    />
  );
}

export default CustomTextField;
