import { cloneDeep } from 'lodash';
import { DYNAMIC_REPORTING_PERIODS } from './constants/constants';

// find value of first match with key in object
export function deepSearch(object, key) {
  // eslint-disable-next-line no-prototype-builtins
  if (object.hasOwnProperty(key)) return object[key];

  for (let i = 0; i < Object.keys(object).length; i++) {
    const value = object[Object.keys(object)[i]];
    if (typeof value === 'object' && value != null) {
      return deepSearch(object[Object.keys(object)[i]], key);
    }
  }
  return null;
}

// remove props y empty values
export function removeEmptyValues(object) {
  if (!object) return;
  const o = { ...object };

  // eslint-disable-next-line no-restricted-syntax
  for (const [key] of Object.entries(object))
    if (typeof o[key] === 'object' && !Object.keys(o[key]).length) {
      delete o[key];
    }

  // eslint-disable-next-line consistent-return
  return o;
}

/**
 * Get latest date of an array
 *
 * @param {array} list Tableau report URL
 * @param {string} property Property name that contains date
 */
export const latestDate = (list, property) =>
  new Date(Math.max(...list.map((e) => new Date(e[property]))));

export function getCleanQueryCopy(q) {
  const cleanedQuery = cloneDeep(q);
  delete cleanedQuery.chosenReports;

  if (q?.reportingPeriods?.endDateRange?.[0]?.dateDescription === 'Most Recent') {
    cleanedQuery.reportingPeriods.reportingPeriodId = null;
    cleanedQuery.reportingPeriods.beginDateRange = null;
  }

  return cleanedQuery;
}

// flatten json query to use only
export function flattenJson(q) {
  const newObj = {
    territorySelection: {},
    indicatorSelection: {},
    vehiclesSelection: {},
    dealersAndLendersSelection: {},
    marketLayoutCriteriaSelection: {},
    riskLayoutCriteriaSelection: {},
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const section in q) {
    // eslint-disable-next-line no-prototype-builtins
    if (newObj.hasOwnProperty(section)) {
      Object.entries(q[section]).forEach(([key, value]) => {
        if (value) newObj[section][key] = Object.keys(value);
      });
    }
  }

  // add risk options selection
  if (q.riskOptionsSelection?.focusLender)
    return {
      ...newObj,
      riskOptionsSelection: {
        ...q.riskOptionsSelection,
        focusLender: [ `${Object.keys(q.riskOptionsSelection?.focusLender || {}).at(0)}` ]
      }
    };
  return { ...newObj, riskOptionsSelection: q.riskOptionsSelection };
}


export function getReportingPeriodId(q) {
  let reportingPeriodIdValue = null;
  let dynamicMonthAdjustment = null;
  const dateDescription = q?.reportingPeriods?.endDateRange?.[0].dateDescription;
  if ( dateDescription !== DYNAMIC_REPORTING_PERIODS.MOST_RECENT
      && dateDescription !== DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_ONE
      && dateDescription !== DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_TWO) {
    reportingPeriodIdValue = q?.reportingPeriods?.endDateRange?.[0].reportingPeriodId;
  }
  if (dateDescription === DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_ONE
      || dateDescription === DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_TWO) {
    dynamicMonthAdjustment = q?.reportingPeriods?.endDateRange?.[0].dynamicMonthAdjustment;
  }
  const reportingPeriodJSON = {
    reportingPeriodId: reportingPeriodIdValue,
    monthsTimeFrame: q?.reportingPeriods.numOfMonths,
    dynamicMonthAdjustment
  };

  return reportingPeriodJSON;
}
