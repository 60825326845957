import React from 'react';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useStyles } from '../../theme/VelocityHubMaterial';

function HeroButton({ text, onClick, disabled }) {
  const { classes } = useStyles();

  if (disabled) {
    return (
      <Button
        id="Button"
        variant="contained"
        className={classes.heroButton}
        endIcon={<ChevronRightIcon />}
        type="submit"
        onClick={onClick}
        data-cy="loginpage-Logout-button"
        disabled
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      className={classes.heroButton}
      endIcon={<ChevronRightIcon />}
      type="submit"
      onClick={onClick}
      data-cy="loginpage-Logout-button"
    >
      {text}
    </Button>
  );
}

export default HeroButton;
