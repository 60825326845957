const getAccessToken = () => {
  const oktaStorage = localStorage.getItem('okta-token-storage');
  if (!oktaStorage) return undefined;
  const json = JSON.parse(oktaStorage);
  const { accessToken } = json;
  if (accessToken?.value !== undefined) {
    return accessToken?.value;
  }
  return accessToken?.accessToken;
};

const getAuthHeaders = (accessToken) => {
  if (!accessToken) return;
  // eslint-disable-next-line consistent-return
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

async function fetchData(resource, options = { headers: {} }) {
  const accessToken = getAccessToken();
  const authHeaders = getAuthHeaders(accessToken);
  const personaStatus = localStorage.getItem('personaStatus');

  if (personaStatus === 'true') {
    const persona = true;
    // eslint-disable-next-line no-param-reassign
    options = { ...options, headers: { ...options.headers, ...authHeaders, persona } };
  } else {
    const persona = false;
    // eslint-disable-next-line no-param-reassign
    options = { ...options, headers: { ...options.headers, ...authHeaders, persona } };
  }
  try {
    const response = await fetch(resource, options);
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    const errorMeessage = data.error ?? data.message;
    return await Promise.reject(new Error(`${errorMeessage}: ${resource}`));
  } catch (err) {
    return Promise.reject(new Error(`${err.message}: ${resource}.`));
  }
}

async function fetchFileData(resource, options = { headers: {} }) {
  const accessToken = getAccessToken();
  const authHeaders = getAuthHeaders(accessToken);
  const personaStatus = localStorage.getItem('personaStatus');

  if (personaStatus === 'true') {
    const persona = true;
    // eslint-disable-next-line no-param-reassign
    options = { ...options, headers: { ...options.headers, ...authHeaders, persona } };
  } else {
    const persona = false;
    // eslint-disable-next-line no-param-reassign
    options = { ...options, headers: { ...options.headers, ...authHeaders, persona } };
  }

  try {
    const response = await fetch(resource, options);
    if (response.ok) {
      return response;
    }
    const errorMeessage = response.error ?? response.message;
    return await Promise.reject(new Error(`${errorMeessage}: ${resource}`));
  } catch (err) {
    return Promise.reject(new Error(`${err.message}: ${resource}.`));
  }
}

export { fetchData, fetchFileData };
