import React from 'react';

function useSafeDispatch(dispatch) {
  const mountedRef = React.useRef(false);

  React.useLayoutEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  return React.useCallback(
    (...args) => (mountedRef.current ? dispatch(...args) : undefined),
    [dispatch],
  );
}

export { useSafeDispatch };
