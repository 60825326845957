import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const NOTIFICATIONS_RESOURCES = RESOURCES.error;

export function logError(message) {
  const resource = `${API_BASE_URL}${NOTIFICATIONS_RESOURCES.logging}`;
  const payload = {
    method: 'POST',
    body: JSON.stringify({
      message,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}
