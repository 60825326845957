import React from 'react';
import './helpContactUs.scss';
import LinkButtonsSection from './LinkButtonsSection';
import ContactUsDisplay from './ContactUsDisplay';
import ReferenceMaterial from './ReferenceMaterial';
import { useAsync } from '../../utils/useAsync';
import { getDisplayContactUs } from '../../services/helpCentral.api';

function HelpSection() {
  const { data, run } = useAsync();

  React.useEffect(() => {
    run(getDisplayContactUs());
  }, [run]);

  return (
    <div>
      <div style={{ paddingBottom: '50px' }}>
        {data && <ContactUsDisplay contactUsData={data} />}
      </div>
      
      <div style={{ paddingBottom: '50px' }}>
        <ReferenceMaterial />
      </div>
      
      <div style={{ paddingBottom: '50px' }}>
        {data && <LinkButtonsSection contactUsData={data} />}
      </div>
    </div>
  );
}

export default HelpSection;
