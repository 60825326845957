import { React, useState } from 'react';
import './Navbar.scss';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import { useAuthentication } from '../../contexts/Authentication';
import Navigation from './Navigation';
import Persona from '../persona/Persona';

const useStyles = makeStyles()((theme) => ({
  root: {
    position: 'relative',
  },
  avatarDropdown: {
    position: 'absolute',
    top: -35,
    right: 0,
    left: 50,
    zIndex: 20,
    width: '150px',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
}));

function Navbar({ routes, personaUser, personaStatus, setPersonaStatus, ...props }) {
  const { logout } = useAuthentication();

  const { classes } = useStyles();
  const [avatarOpen, setAvatarOpen] = useState(false);

  const handleAvatarClick = () => {
    setAvatarOpen((prev) => !prev);
  };

  const handleAvatarClickAway = () => {
    setAvatarOpen(false);
  };

  return (
    <div className="navbar-wrapper">
      <Container className="navbar-container">
        <div className="navigation">
          <Navigation navItems={Object.values(routes)} {...props} />
        </div>

        <ClickAwayListener onClickAway={handleAvatarClickAway}>
          <div className="root">
            <div className="persona_container">
              {personaUser && (
                <Persona
                  personaUser={personaUser}
                  personaStatus={personaStatus}
                  setPersonaStatus={setPersonaStatus}
                />
              )}
            </div>
            <Avatar
              data-cy="navbar-Avatar-button"
              src="/broken-image.jpg"
              onClick={handleAvatarClick}
            />
            {avatarOpen ? (
              <div className={classes.avatarDropdown}>
                <Link data-cy="navbar-ReturnHub-button" to="/welcome">
                  Return to Hub
                </Link>
                <Link data-cy="navbar-MyProfile-button" to="/">
                  My Profile
                </Link>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link data-cy="navbar-Logout-button" onClick={logout}>
                  Logout
                </Link>
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </Container>
    </div>
  );
}

export default Navbar;
