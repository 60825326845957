import React from 'react';
import Button from '@mui/material/Button';
import { useStyles } from '../../theme/VelocityHubMaterial';

function SpotlightButton({ onClick, text }) {
  const { classes } = useStyles();

  return (
    <Button variant="contained" className={classes.spotlightButton} type="submit" onClick={onClick}>
      {text}
    </Button>
  );
}

export default SpotlightButton;
