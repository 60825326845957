import './TextLoader.scss';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import LinearProgress from '@mui/material/LinearProgress';

const CAR_FACTS = [
  'The average fuel cost across all vehicles is 14.45 cents per mile, or about 23 miles per gallon.',
  'The best selling car of all-time is the Toyota Corolla.',
  'The U.S. consumes about half of the world’s gasoline.',
  'Ferrari manufactures a maximum of 14 cars a day.',
  'Although 1886 is credited with being the birth year of the modern automobile, the first gas gauge wasn’t installed until 1922.',
  'Every year, over $60 billion worth of car maintenance goes unperformed.',
  'The average consumer spends $400 a year on diagnostics, scheduled maintenance, and tune-ups.',
  'Traffic congestion wastes three billion gallons of gas each year.',
  'The first windshield wipers were hand-operated.',
  'The most commonly stolen vehicle is the Honda Accord.',
  'White is the most popular car color.',
  'The total average repair cost in the U.S. is $305.55, including $202.28 for parts and $103.27 for labor.',
  'The average American will spend two weeks of his or her life stopped at red lights.',
  '90 percent of drivers admit to signing behind the wheel.',
  'The first cars did not have steering wheels. They were operated by a lever.',
  'An airbag inflates within 40 milliseconds of a crash.',
  'It takes half an ounce of gas to start a car.',
  'Most car horns beep in the key of “F”.',
  'The automobile is the most recycled consumer product in the world.',
  'Faulty oxygen sensors account for 40 percent of all check engine light related issues.',
];

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

function TextLoader() {
  const index = React.useRef(Math.floor(Math.random() * CAR_FACTS.length));
  const fact = CAR_FACTS[index.current];
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((prev) => (prev === 100 ? 100 : prev + 2));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Dialog open TransitionComponent={Transition}>
      <LinearProgress color="secondary" variant="determinate" value={value} />
      <Card className="text-loader__container">
        <div className="text-loader__details">
          <CardContent className="text-loader__content">
            <Typography variant="subtitle1" color="textSecondary">
              Did you know that... {fact}
            </Typography>
          </CardContent>
        </div>
        <CardMedia
          className="text-loader__cover"
          image="/images/cars.jpg"
          title="Experian Automative"
        />
      </Card>
    </Dialog>
  );
}

export { TextLoader };
