import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Select from '../../../select/Select';
import {
  getGroupByOptions,
  getDisplayByOptions,
} from '../../../../services/usersQueriesOptions.api';
import {
  getMarketDisplayByOptions,
  getMarketGroupByOptions,
} from '../../../../services/reportSelection.api';
import FormField from '../../../formField/FormField';
import {
  RISK_TYPE_REPORT,
  MARKET_TYPE_REPORT,
  GROUP_BY_DESC_SORTING,
  DISPLAY_BY_DESC_SORTING,
} from '../../../utils/constants';

function capitalize(s) {
  return s[0].toUpperCase() + s.slice(1);
}

function LayoutCriteria({
  onChange,
  isMarketReport,
  riskLayoutCriteriaSelection,
  marketLayoutCriteriaSelection,
}) {
  const [groupByOptions, setGroupByOptions] = React.useState([]);
  const [displayByOptions, setDisplayByOptions] = React.useState([]);

  const handleAggregationChange = (value, queryProperty, id) => {
    if (!isMarketReport) {
      const propertyName = [RISK_TYPE_REPORT, capitalize(queryProperty)].join('');
      onChange({
        riskLayoutCriteriaSelection: {
          ...riskLayoutCriteriaSelection,
          [propertyName]: { [value[id]]: value },
        },
      });
    } else {
      const propertyName = [MARKET_TYPE_REPORT, capitalize(queryProperty)].join('');
      onChange({
        marketLayoutCriteriaSelection: {
          ...marketLayoutCriteriaSelection,
          [propertyName]: { [value[id]]: value },
        },
      });
    }
  };

  const compare = (a, b, attr) => {
    if (a[attr] > b[attr]) {
      return 1;
    }
    if (a[attr] < b[attr]) {
      return -1;
    }
    return 0;
  };

  const compareGroupByDesc = (a, b) => compare(a, b, GROUP_BY_DESC_SORTING);
  const compareDisplayByDesc = (a, b) => compare(a, b, DISPLAY_BY_DESC_SORTING);

  useEffect(() => {
    if (!isMarketReport) {
      getGroupByOptions().then((data) => {
        setGroupByOptions(data.sort(compareGroupByDesc));
      });
      getDisplayByOptions().then((data) => {
        setDisplayByOptions(data.sort(compareDisplayByDesc));
      });
    } else {
      getMarketGroupByOptions().then((data) => {
        setGroupByOptions(data.sort(compareGroupByDesc));
      });
      getMarketDisplayByOptions().then((data) => {
        setDisplayByOptions(data.sort(compareDisplayByDesc));
      });
    }
  }, [isMarketReport]);

  return (
    <Grid container spacing={3} padding="10px 30px">
      <Grid item xs={6}>
        <FormField
          fieldTitle="Group By"
          dataCy="aq-GroupBy-label"
          fieldInput={
            <Select
              id="advanceQuery__selectGroupBy:Id"
              test-id="groupByRiskOptions"
              options={groupByOptions}
              value={
                isMarketReport
                  ? marketLayoutCriteriaSelection.marketGroupBy
                  : riskLayoutCriteriaSelection?.riskGroupBy
              }
              displayAttribute="groupByDesc"
              selectAttribute="groupById"
              label="No Group Selected"
              queryDataType={isMarketReport ? 'marketGroupBy' : 'riskGroupBy'}
              onChange={(a, b, element) => handleAggregationChange(element, 'groupBy', 'groupById')}
              hasAllOption={false}
              dataCy="aq-GroupBy"
            />
          }
        />
      </Grid>
      <Grid item xs={6}>
        <FormField
          fieldTitle="Display By"
          dataCy="aq-DisplayBy-label"
          fieldInput={
            <Select
              id="advanceQuery__selectDisplayBy:Id"
              test-id="displayByRiskOptions"
              options={displayByOptions}
              value={
                isMarketReport
                  ? marketLayoutCriteriaSelection?.marketDisplayBy
                  : riskLayoutCriteriaSelection?.riskDisplayBy
              }
              displayAttribute="displayByDesc"
              selectAttribute="displayById"
              label="No Display Selected"
              queryDataType={isMarketReport ? 'marketDisplayBy' : 'riskDisplayBy'}
              onChange={(a, b, element) =>
                handleAggregationChange(element, 'displayBy', 'displayById')
              }
              hasAllOption={false}
              dataCy="aq-DisplayBy"
            />
          }
        />
      </Grid>
    </Grid>
  );
}

export default LayoutCriteria;
