import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { TextLoader } from '../../../../components/loader/TextLoader';
import { getNotificationsByUser } from '../../../../services/notifications.api';
import NotificationBoxes from '../notificationBoxes/NotificationsBoxes';
import { StoreContext } from '../../../../store/storeContext';

function HomePageNotifications() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { actions } = useContext(StoreContext);

  useEffect(() => {
    getNotificationsByUser()
      .then((d) => {
        setData(d);
        setLoading(false);
      })
      .catch((error) => {
        actions.errorAction(error);
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <TextLoader />}
      {data?.map(({ messageTitle, createTs, messageContent }, index) => (
        <NotificationBoxes
          key={index}
          title={messageTitle}
          subtitle={moment(createTs).format('LL')}
          content={messageContent}
        />
      ))}
    </>
  );
}

export default HomePageNotifications;
