import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import './NotificationBoxes.scss';
import PageHeader from '../../../../components/pageHeader/PageHeader';

function NotificationBoxes({ title, subtitle, content }) {
  return (
    <Paper className="notification-box" elevation={2}>
      <Card>
        <CardHeader
          title={<PageHeader title={title} />}
          subheader={
            <Typography className="notification-sub-title" variant="subtitle1">
              {subtitle}
            </Typography>
          }
        />
        <CardContent className="notification-content">
          <Typography variant="body2" component="p">
            {content}
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
}

NotificationBoxes.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default NotificationBoxes;
