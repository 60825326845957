import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const STS_RESOURCES = RESOURCES.sts;

function getStsAccountByClientId(clientId) {
  const resource = `${API_BASE_URL}${STS_RESOURCES.getStsAccountByClientId}/${clientId}`;
  return fetchData(resource);
}
function getStsFolderByUserId(userId) {
  const resource = `${API_BASE_URL}${STS_RESOURCES.getStsFolderByUserId}/${userId}`;
  return fetchData(resource);
}

function getStsBundleByUser() {
  const resource = `${API_BASE_URL}${STS_RESOURCES.getStsBundleByUser}`;
  return fetchData(resource);
}

export { getStsAccountByClientId, getStsFolderByUserId, getStsBundleByUser };
