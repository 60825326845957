import React from 'react';
import './helpContactUs.scss';
import SubmitRequest from './SubmitRequest';
import MarqueImage from './MarqueeImage';

function ContactUsDisplay({ contactUsData }) {
  const contactUsImage = '/images/contact-us-marquee.jpg';

  return (
    <>
      <div style={{ paddingBottom: '15px' }}>
        <MarqueImage image={contactUsImage} titleName="Contact Us" />
      </div>
      <div>
        <SubmitRequest
          scheduleMessage={contactUsData?.scheduleMessage}
          emailAddress={contactUsData?.emailAddress}
        />
      </div>
    </>
  );
}

export default ContactUsDisplay;
