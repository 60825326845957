import React from 'react';
import Button from '@mui/material/Button';
import ClassicModal from '../../components/common/ClassicModal';
import { useStyles } from '../../theme/VelocityHubMaterial';

function DeleteSavedQueryModal({show, onHideModal, title, onConfirmAction, text}) {
    const { classes } = useStyles();

    const deleteSavedQueryModalBody = (
        <div className={classes.root}>
          {text}
        </div>
      );

    const deleteSavedQueryModalFooter = (
        <div className={classes.root}>
          <Button
            variant="contained"
            color="primary"
            onClick={onConfirmAction}
            data-cy="delete-saved-query-button-confirm"
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            onClick={onHideModal}
            data-cy="delete-saved-query-button-cancel"
          >
            Cancel
          </Button>
        </div>
      );

    return (
        <div>
          <ClassicModal
            show={show}
            className={classes.root}
            onHide={onHideModal}
            title={title}
            body={deleteSavedQueryModalBody}
            footer={deleteSavedQueryModalFooter}
          />
        </div>
      );
}

export default DeleteSavedQueryModal;