import React, {useEffect, useState} from 'react';
import './StateAvailability.scss';
import {
  getFile
} from '../../../../services/documents.api';
import { REFERENCE_MATERIALS } from '../../../../utils/constants/constants';

function HomePageStatesAvailabilities() {
  const [imgSrc, setImgSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getFile(REFERENCE_MATERIALS.stateAvailability.fileName)
    .then(response => response.blob())
    .then(images => {
      setImgSrc(URL.createObjectURL(images))
      setIsLoading(false);
    })
  }, []);
  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <img
          alt="report"
          className="reportContainer"
          src={imgSrc}
          data-cy="homepage-StateAvailability-img"
        />
      )}
    </div>
  );
}

export default HomePageStatesAvailabilities;
