import moment from 'moment';

export const getStartDate = (reportingPeriods, timeFrame, endDate) => {
  let result;
  if (timeFrame.numOfMonths) {
    result = reportingPeriods[timeFrame.numOfMonths - 1];
  } else if (endDate) {
    let index = reportingPeriods.length - 1;
    for (; index >= 0; index--) {
      if (
        moment(reportingPeriods[index].periodDate, 'YYYY-MM-DD').year() ===
        moment(endDate.periodDate, 'YYYY-MM-DD').year()
      ) {
        result = reportingPeriods[index];
        break;
      }
    }
  }
  return result;
};

export const handlePeriodChange = (
  element,
  setTimeFrameSelected,
  endDate,
  onChange,
  availableReportingPeriods,
  setStartDate,
) => {
  setTimeFrameSelected({ element });
  const allMonthBeforeRef = endDate?.element
    ? availableReportingPeriods.filter(
        ({ periodDate }) => new Date(periodDate) <= new Date(endDate.element.periodDate),
      )
    : availableReportingPeriods;
  const beginDateRange = getStartDate(allMonthBeforeRef, element, endDate?.element);

  onChange({
    reportingPeriods: {
      beginDateRange: [beginDateRange],
      endDateRange: [endDate?.element],
      numOfMonths: element?.numOfMonths,
    },
  });
  setStartDate(beginDateRange);
};

export const handleOnChange = (element, setEndDate) => {
  setEndDate({ element });
};
