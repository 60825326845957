import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Select from '../../../select/Select';
import FormField from '../../../formField/FormField';
import { searchLenders } from '../../../../services/dealersAndLenders.api';
import { StoreContext } from '../../../../store/storeContext';

function FocusLenderManager({ query, onChange, focusLender}) {
  const [ lenders, setLenders ] = useState([]);
  const [ loadingLenderFocus, setLoadingLenderFocus ] = useState(false);
  const { actions } = useContext(StoreContext);

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      setLoadingLenderFocus(true);
      let response;
      try {
        response = await searchLenders(0, '', 1);
      } catch (e) {
        actions.errorAction(e);
        setLoadingLenderFocus(false);
      }
      setLoadingLenderFocus(false);
      setLenders(response);
    }, 500);
    return () => {
      setLenders([])
      clearTimeout(timeOutId);
    }
  }, []);

  const focusLenderChanged = (element) => {
    if (element)
      onChange({
        riskOptionsSelection: {
          ...query?.riskOptionsSelection,
          focusLender: { [element?.lenderId]: element }
        }
      });
  }

  return (
    <div className="focusLenderFieldset">
      <Grid container>
        <Grid item xs={12} marginBottom={3}>
          <FormField
            fieldTitle=""
            fieldInput={
              <Select
                id="advanceQuery__focusLenderId"
                value={focusLender? [ focusLender ] : []}
                options={lenders}
                displayAttribute="lenderNm"
                selectAttribute="lenderId"
                loading={loadingLenderFocus}
                onChange={(a, b, element) => focusLenderChanged(element)}
                label="No Focus Lender Selected"
                queryDataType="focusLenders"
                hasAllOption={false}
              />
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default FocusLenderManager;
