import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function FormField({ fieldTitle, fieldInput, dataCy }) {
  return (
    <Grid container item xs={12} wrap="nowrap">
      <Grid item display="flex" alignItems="center">
        <Typography variant="h4" marginBottom={0} data-cy={`${dataCy}-label`}>
          {fieldTitle}
        </Typography>
      </Grid>
      <Grid item xs={12} data-cy={`${dataCy}-wrapper`}>
        {fieldInput}
      </Grid>
    </Grid>
  );
}

FormField.propTypes = {
  fieldTitle: PropTypes.string.isRequired,
  fieldInput: PropTypes.element.isRequired,
};

export default FormField;
