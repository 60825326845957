import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function DistributionBandSelector({
  setDistributionBandTypeValue,
  setHideCustomTiersSection,
  setChangeNotSaved,
  setPresetBandsDistributionValue,
  presetBandsDistributionValue,
}) {
  function handlePresetBandsDistributionValueChange(event) {
    if (event.target.value === 'Custom Tiers') {
      setDistributionBandTypeValue(2);
      setHideCustomTiersSection(false);
    } else {
      setHideCustomTiersSection(true);
      setDistributionBandTypeValue(1);
    }
    setPresetBandsDistributionValue({ value: event.target.value });
    setChangeNotSaved(true);
  }
  return (
    <Grid item container xs={4} direction="column">
      <Typography variant="h4">Distribution band</Typography>
      <Grid item container direction="row">
        <Typography variant="label">
          I want to use pre-set bands distributed in sections of:
        </Typography>

        <Select
          className="selectorWithASpace"
          test-id="distributionBandSelector"
          data-cy="aq-DistributionBands-selector"
          sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          value={presetBandsDistributionValue?.value}
          onChange={(event) => handlePresetBandsDistributionValueChange(event)}
        >
          <MenuItem value="Custom Tiers">Custom Tiers</MenuItem>
          <MenuItem value="Credit Category Scores">Experian Bands</MenuItem>
          <MenuItem value="20 Point Bands">20 Point Bands</MenuItem>
          <MenuItem value="50 Point Bands">50 Point Bands</MenuItem>
          <MenuItem value="100 Point Bands">100 Point Bands</MenuItem>
        </Select>
      </Grid>
    </Grid>
  );
}

export default DistributionBandSelector;
