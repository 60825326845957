import React from 'react';
import { useSafeDispatch } from './useSafeDispatch';
import { useToast } from '../contexts/Toast';

const actionTypes = {
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected',
};

function asyncReducer(state, action) {
  switch (action.type) {
    case actionTypes.pending: {
      return { status: 'pending', data: null, error: null };
    }
    case actionTypes.resolved: {
      return { status: 'resolved', data: action.data, error: null };
    }
    case actionTypes.rejected: {
      return { status: 'rejected', data: null, error: action.error };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function displayErrorToast(setToastState, severity, error) {
  setToastState({
    open: true,
    severity,
    message: error,
  });
}

function useAsync(initialState, reducer = asyncReducer) {
  const { setToastState } = useToast();
  const [state, unsafeDispatch] = React.useReducer(reducer, {
    status: 'idle',
    data: null,
    error: null,
    ...initialState,
  });

  const dispatch = useSafeDispatch(unsafeDispatch);

  const { data, error, status } = state;

  const run = React.useCallback(
    (promise) => {
      dispatch({ type: actionTypes.pending });
      promise.then(
        (result) => {
          dispatch({ type: actionTypes.resolved, data: result });
        },
        (e) => {
          displayErrorToast(setToastState, 'error', e.message);
          dispatch({ type: actionTypes.rejected, e });
        },
      );
    },
    [dispatch, setToastState],
  );

  return {
    error,
    status,
    data,
    run,
  };
}

export { useAsync, asyncReducer, actionTypes };
