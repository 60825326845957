import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import NotFound from '../views/notFound/NotFound';
import ErrorBoundary from '../components/errorBoundary/ErrorBoundary';

export const NOT_FOUND_PATH = '/not-found';
export const OKTA_CALLBACK_PATH = '/implicit/callback';

function buildRoutes(routes, sharedProps, layout = '') {
  return routes.map(
    ({
      path,
      component: Component,
      children,
      innerRoutes,
      exact = true,
      isSecured = true,
      props,
    }) =>
      // eslint-disable-next-line no-nested-ternary
      innerRoutes ? (
        buildRoutes(innerRoutes, sharedProps, path)
      ) : isSecured ? (
        <SecureRoute
          exact={exact}
          key={layout + path}
          path={layout + path}
          render={(routeProps) => (
            <Component {...routeProps} {...sharedProps} {...props}>
              {children}
            </Component>
          )}
        />
      ) : (
        <Route
          exact={exact}
          key={layout + path}
          path={layout + path}
          render={(routeProps) => (
            <Component {...routeProps} {...sharedProps} {...props}>
              {children}
            </Component>
          )}
        />
      ),
  );
}

function renderRoutes(routes, layout, homePath, sharedProps = {}, isRoot = false) {
  return (
    <ErrorBoundary>
      <Switch>
        {homePath && <Redirect exact path={layout} to={homePath} />}
        {buildRoutes(routes, sharedProps)}
        {isRoot && <Route path={OKTA_CALLBACK_PATH} component={LoginCallback} />}
        {isRoot && (
          <Route exact path={NOT_FOUND_PATH}>
            <NotFound />
          </Route>
        )}
      </Switch>
    </ErrorBoundary>
  );
}

export { renderRoutes };
