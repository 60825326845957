import React, { useEffect, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '../../../../select/Select';
import './ReportingPeriodManager.scss';
import { handleOnChange, handlePeriodChange } from './ReportPeriodManager.utils';
import FormField from '../../../../formField/FormField';
import { DYNAMIC_REPORTING_PERIODS } from '../../../../../utils/constants/constants';

function ReportingPeriodManager({
  availableReportingPeriods,
  onChange,
  allAvailableTimeFrames,
  reportSelection,
  endDate,
  setEndDate,
  resetState,
}) {
  const [timeFrameSelected, setTimeFrameSelected] = useState(null);
  const [startDate, setStartDate] = useState();
  const [reportingPeriods, setReportingPeriods] = useState();
  const availableTimeFrames = allAvailableTimeFrames?.slice();
  useEffect(() => {
    if (availableTimeFrames?.length > 0) {
      if (!reportSelection?.numOfMonths) {
        handlePeriodChange(
          availableTimeFrames[0],
          setTimeFrameSelected,
          endDate,
          onChange,
          availableReportingPeriods,
          setStartDate,
        );
      } else {
        const customTimeFrameSelected = availableTimeFrames.filter(
          (timeFrame) => timeFrame.numOfMonths === reportSelection.numOfMonths,
        );
        handlePeriodChange(
          customTimeFrameSelected[0],
          setTimeFrameSelected,
          endDate,
          onChange,
          availableReportingPeriods,
          setStartDate,
        );
      }
    }
  }, [allAvailableTimeFrames, reportSelection?.numOfMonths, resetState]);

  useEffect(() => {
    if (availableReportingPeriods?.length > 0) {
      const customReportingPeriods = cloneDeep(availableReportingPeriods);
      const mostRecentOption = cloneDeep(availableReportingPeriods[0]);
      mostRecentOption.reportingPeriodId = 0;
      mostRecentOption.dateDescription = DYNAMIC_REPORTING_PERIODS.MOST_RECENT;

      // adding Most Recent -2 Option
      if (availableReportingPeriods?.length > 2) {
        const mostRecentMinusTwoOption = cloneDeep(availableReportingPeriods[2]);
        mostRecentMinusTwoOption.reportingPeriodId = 0;
        mostRecentMinusTwoOption.dateDescription = DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_TWO;
        mostRecentMinusTwoOption.dynamicMonthAdjustment = 2;
        customReportingPeriods.unshift(mostRecentMinusTwoOption);
      }

      // adding Most Recent -1 Option
      if (availableReportingPeriods?.length > 1) {
        const mostRecentMinusOneOption = cloneDeep(availableReportingPeriods[1]);
        mostRecentMinusOneOption.reportingPeriodId = 0;
        mostRecentMinusOneOption.dateDescription = DYNAMIC_REPORTING_PERIODS.MOST_RECENT_MINUS_ONE;
        mostRecentMinusOneOption.dynamicMonthAdjustment = 1;
        customReportingPeriods.unshift(mostRecentMinusOneOption);
      }

      // adding Most Recent Option
      customReportingPeriods.unshift(mostRecentOption);

      setReportingPeriods(customReportingPeriods);
      if (!reportSelection?.endDateRange?.[0]?.reportingPeriodId || reportSelection === undefined) {
        if (!reportSelection?.endDateRange?.[0]?.dynamicMonthAdjustment) {
          // most recent
          handleOnChange(customReportingPeriods[0], setEndDate);
        } else if (reportSelection?.endDateRange?.[0]?.dynamicMonthAdjustment === 1) {
          // most recent -1
          handleOnChange(customReportingPeriods[1], setEndDate);
        } else {
          // most recent -2
          handleOnChange(customReportingPeriods[2], setEndDate);
        }
      } else {
        handleOnChange(reportSelection.endDateRange[0], setEndDate);
      }
    }
  }, [availableReportingPeriods, reportSelection?.numOfMonths, resetState]);

  useEffect(() => {
    if (endDate && timeFrameSelected) {
      handlePeriodChange(
        timeFrameSelected.element,
        setTimeFrameSelected,
        endDate,
        onChange,
        timeFrameSelected.element.reportingPeriodOptionNm === '1 Month' &&
          endDate.element.dateDescription === 'Most Recent'
          ? reportingPeriods
          : availableReportingPeriods,
        setStartDate,
      );
    }
  }, [endDate]);

  const availableData = ` ${
    availableReportingPeriods?.[availableReportingPeriods.length - 1].dateDescription
  } - ${availableReportingPeriods?.[0].dateDescription}`;

  const reportCovers = ` ${startDate?.dateDescription} - ${endDate?.element?.dateDescription}`;

  return (
    <div className="reportingPeriodFieldset">
      <Grid container>
        <Grid item xs={12} marginBottom={3}>
          <FormField
            fieldTitle="Report Period *"
            dataCy="aq-ReportPeriod"
            fieldInput={
              <Select
                id="advanceQuery__reportPeriodId"
                value={endDate}
                options={reportingPeriods}
                displayAttribute="dateDescription"
                selectAttribute="id"
                onChange={(a, b, element) => handleOnChange(element, setEndDate)}
                label="No Period Selected"
                queryDataType="reportingPeriods"
                hasAllOption={false}
                dataCy="aq-ReportPeriod"
              />
            }
          />
        </Grid>
        <Grid item xs={12} marginBottom={3}>
          <FormField
            fieldTitle="Time Frame *"
            dataCy="aq-TimeFrame"
            fieldInput={
              <Select
                id="advanceQuery__timeFrameId"
                testId="TimeFrame"
                value={timeFrameSelected}
                options={availableTimeFrames}
                displayAttribute="reportingPeriodOptionNm"
                selectAttribute="reportingPeriodOptionId"
                onChange={(a, b, element) =>
                  handlePeriodChange(
                    element,
                    setTimeFrameSelected,
                    endDate,
                    onChange,
                    availableReportingPeriods,
                    setStartDate,
                  )
                }
                label="No Time Frame Selected"
                queryDataType="reportingPeriods"
                hasAllOption={false}
                dataCy="aq-TimeFrame"
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          {availableReportingPeriods?.length > 0 && (
            <Typography component="span" data-cy="aq-AvailableData-span" gutterBottom>
              <Box fontWeight="fontWeightMedium" display="inline" data-cy="aq-AvailableData-label">
                Available Data:
              </Box>
              {availableData}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {endDate && startDate && (
            <Typography component="span" data-cy="aq-ReportCovers-span" gutterBottom>
              <Box fontWeight="fontWeightMedium" display="inline" data-cy="aq-ReportCovers-label">
                Report Covers:
              </Box>
              {reportCovers}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default ReportingPeriodManager;
