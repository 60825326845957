import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import { useHistory } from 'react-router-dom';
import StandardErrorComponent from '../../components/standardErrorComponent/StandardErrorComponent';
import WelcomeHeader from '../../components/welcomeHeader/WelcomeHeader';
import { useAuthentication } from '../../contexts/Authentication';
import { getProductsByUser } from '../../services/products.api';
import CookieConsent from '../../components/cookieConsent/CookieConsent';

function LandingProductsDisplay() {
  const { userInfo } = useAuthentication();
  const { isAuthenticated } = userInfo;
  const images = {
    'Velocity Market': '/images/velocity_market.PNG',
    'Velocity Market/Risk': '/images/velocity_risk.PNG',
    Administration: '/images/administration.PNG',
    'AutoCount Accelerate': '/images/autocount.jpg',
  };

  const [connectionError, setConnectionError] = useState(false);
  const [productsIds, setProductsIds] = useState();
  const [products, setProducts] = useState();
  const history = useHistory();

  const getData = () => {
    getProductsByUser().then(
      (data) => {
        setProducts(data);
        const productIds = new Array(data.length);

        data.map((product) => productIds.push(product.productId));

        setProductsIds(productIds);
        setConnectionError(false);
      },
      (error) => setConnectionError(error),
    );
  };

  useEffect(() => {
    getData();
  }, [isAuthenticated]);

  const goToProduct = (product) => {
    // If product is Administration
    if (product?.productId === 2) {
      window.location.href = process.env.REACT_APP_ADMINISTRATION_UI;
    } else {
      history.push(product.productPath);
    }
  };

  return (
    <Grid container>
      <Grid className="leftPanel" item xs={5} container direction="column">
        <img
          src="/images/ExperianLogo.svg"
          className="logo"
          alt="Logo"
          data-cy="loginpage-experianLogo-img"
        />
        <WelcomeHeader />
        {connectionError ? (
          <StandardErrorComponent text="Something Went Wrong" retryFunction={getData} />
        ) : (
          <Grid className="rightPanel" container spacing={2}>
            {productsIds &&
              products?.map((card) => (
                <Grid item key={card.productId} xs={12} sm={12} md={6}>
                  <Card className="card" elevation={4}>
                    <CardActionArea onClick={() => goToProduct(card)}>
                      <CardMedia
                        component="img"
                        className="cardMedia"
                        image={images[card.productNm]}
                        title={card.productNm}
                        data-cy={`loginpage-${card.productNm.trim()}-img`}
                      />
                      <CardContent className="cardContent">
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          data-cy={`loginpage-${card.productNm.trim()}-label`}
                        >
                          {card.productNm}
                        </Typography>
                        <Typography>{card.productDescription}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
      <Grid item xs={7}>
        <img
          alt="landingPageImage"
          className="landingPageImage"
          src="/images/LandingPageImage.jpg"
        />
      </Grid>
      {process.env.REACT_APP_COOKIES_POLICY_URL && (
        <CookieConsent
          privacyPolicyURL={process.env.REACT_APP_COOKIES_POLICY_URL}
          cookieName="cookieConsent"
          message="Cookies help us deliver the best experience on our website. By using our webside, you agree the use of cookies. "
        />
      )}
    </Grid>
  );
}

export default LandingProductsDisplay;
