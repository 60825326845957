import React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { Export } from '../export/Export';
import QueryInfo from '../queryInfo/QueryInfo';
import './AdditionalInfo.scss';

function AdditionalInfo({ viz, query, reportTypeId, hideInPopover }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'additional-info-popover' : undefined;

  return hideInPopover ? (
    <div className="additional-info_button-container">
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        className="display-button"
      >
        Display Query Details
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="additional-info_container">
          <QueryInfo query={query} reportTypeId={reportTypeId} />
        </div>
      </Popover>
      <Export query={query} reportTypeId={reportTypeId} viz={viz} />
    </div>
  ) : (
    <div>
      <Export query={query} reportTypeId={reportTypeId} viz={viz} />
      <div className="additional-info_container">
        <QueryInfo query={query} reportTypeId={reportTypeId} />
      </div>
    </div>
  );
}

export { AdditionalInfo };
