import React from "react";
import "./ErrorFallback.scss";
import { ERRORS } from "../../utils/constants/constants";

function ErrorFallback({ error }) {
  return (
    <div className="errorFallback" role="alert">
      <h3>Accelerate experienced an issue</h3>
      <p>Try again later or contact Accelerate Support</p>
      <pre className="errorFallback__alert">
        {`Error: ${ERRORS.code.default}`}
        <br />
        {error.message}
      </pre>
    </div>
  );
}

export default ErrorFallback;
