import './NotFound.scss';
import React from 'react';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router-dom';

function NotFound() {
  const history = useHistory();
  const handleOnClick = () => {
    history.push('/welcome');
  };

  return (
    <div className="notFound__wrapper">
      <div className="notFound__content">
        <div className="notFound__contentHeader">
          <h1>We couldnt find that page</h1>
          <h4>Are you sure you enter the right url?</h4>
        </div>

        <Button
          id="Button"
          variant="contained"
          className="notFound__button"
          startIcon={<ChevronLeftIcon />}
          type="submit"
          onClick={handleOnClick}
        >
          take me back to home
        </Button>
      </div>

      <div className="notFound__background" />
    </div>
  );
}

export default NotFound;
