import React from 'react';

function useLocalStorage(
  key,
  defaultValue = '',
  { serialize = JSON.stringify, deserialize = JSON.parse } = {},
) {
  const [state, setState] = React.useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    // eslint-disable-next-line no-nested-ternary
    return valueInLocalStorage
      ? deserialize(valueInLocalStorage)
      : typeof defaultValue === 'function'
      ? defaultValue()
      : defaultValue;
  });

  const prevKeyRef = React.useRef(key);

  React.useEffect(() => {
    const prevKey = prevKeyRef.current;
    if (prevKey !== key) window.localStorage.removeItem(prevKey);
    prevKeyRef.current = key;
    window.localStorage.setItem(key, serialize(state));
  }, [key, serialize, state]);

  return [state, setState];
}

function clearLocalStorage(key) {
  window.localStorage.removeItem(key);
}

export { useLocalStorage, clearLocalStorage };
