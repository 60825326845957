import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { actions, columns } from './SavedFilesTable.utils';
import { StoreContext } from '../../store/storeContext';
import DeleteSavedQueryModal from './deleteSavedFileModal';
import {
  getUsersScheduledQueriesReportFilesActiveByUser,
  getUserScheduledQueryReportFile,
  disableUserScheduledQueryReportFile,
} from '../../services/usersScheduledQueries.api';

function SavedFilesTable() {
  const [isLoading, setLoading] = useState(true);
  const [
    availableUsersScheduledQueriesReportFilesActive,
    setAvailableUsersScheduledQueriesReportFilesActive,
  ] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRow, setCurrentRow] = useState({});
  const deleteMessage = `Delete ${currentRow.reportUiFileName}?`;
  const [refreshTable, setRefreshTable] = useState(false);

  const [reportSelectDictionary, setReportSelectDictionary] = useState([]);
  const [reportSelectArray, setReportSelectArray] = useState([]);
  const { actions: actionsContext } = useContext(StoreContext);

  function getReportFiles() {
    getUsersScheduledQueriesReportFilesActiveByUser()
      .then((availableUserScheduledQueriesReportFilesData) => {
        setAvailableUsersScheduledQueriesReportFilesActive(
          availableUserScheduledQueriesReportFilesData,
        );
        setLoading(false);
      })
      .catch((error) => {
        actionsContext.errorAction(error);
      });
  }

  useEffect(() => {
    getReportFiles();
  }, []);

  function handleDisableReportFileButtonClick(rowData) {
    if (rowData.userScheduledQueryReportFileId) {
      disableUserScheduledQueryReportFile(rowData?.userScheduledQueryReportFileId)
        .then(() => {
          getReportFiles();
        })
        .catch((error) => {
          actionsContext.errorAction(error);
        });
    }
  }

  function downloadFile(id, name) {
    getUserScheduledQueryReportFile(id)
      .then((res) => {
        res.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = name;
          alink.click();
        });
      })
      .catch((error) => {
        actionsContext.errorAction(error);
      });
  }

  useEffect(() => {
    setRefreshTable(false);
  }, [refreshTable]);

  return (
    <div>
      <h6><i>Files are removed after 45 days</i></h6>
      <MaterialTable
        test-id="savedFilesTable"
        title="My Saved Files"
        isLoading={isLoading}
        options={{
          pageSize: 10,
          search: false,
          toolbar: false,
          filtering: true,
          showTitle: false,
          doubleHorizontalScroll: true,
        }}
        columns={columns(
          reportSelectArray,
          reportSelectDictionary,
          setReportSelectArray,
          setReportSelectDictionary,
        )}
        data={availableUsersScheduledQueriesReportFilesActive}
        actions={actions(downloadFile, setShowModal, setCurrentRow)}
      />
      <DeleteSavedQueryModal
        title="Delete saved file"
        text={deleteMessage}
        show={showModal}
        onHideModal={() => {
          setShowModal(false);
        }}
        onConfirmAction={() => {
          handleDisableReportFileButtonClick(currentRow);
          setShowModal(false);
        }}
      />
    </div>
  );
}

export default SavedFilesTable;
