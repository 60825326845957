/* eslint-disable react/destructuring-assignment */
import React from 'react';
import './Navigation.scss';
import { NavLink } from 'react-router-dom';

function Navigation(props) {
  const activeRoute = (routeName) => props.location.pathname.indexOf(routeName) > -1;

  return (
    <nav>
      {props.navItems.map(({ path, defaultPathOption = '', name }) => (
        <NavLink
          className={activeRoute(path) ? 'nav-menu-item--active' : 'nav-menu-item'}
          activeClassName={activeRoute(path) ? '.nav-menu-item--active' : '.nav-menu-item'}
          to={{ pathname: path + defaultPathOption }}
          key={path}
          data-cy={`homepage-${name.replace(/ /g, '')}-tab`}
        >
          {name}
        </NavLink>
      ))}
    </nav>
  );
}

export default Navigation;
