import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import './valueDisplay.scss'

function ValueDisplay({text}) {
    return (
        <Tooltip title={text} placement="top">
            <p className="value">{text}</p>
        </Tooltip>
    );
}

export default ValueDisplay;
