import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const REPORT_SELECTION_RESOURCES = RESOURCES.reportSelection;

function getReports(productId) {
  const resource = `${API_BASE_URL}${REPORT_SELECTION_RESOURCES.getReports}/${productId}`;
  return fetchData(resource);
}

function getReportDisplayBundle(productId, reportTypeId) {
  const resource = `${API_BASE_URL}${REPORT_SELECTION_RESOURCES.reportDisplayBundle}/${productId}/${reportTypeId}`;
  return fetchData(resource);
}

function getReportsListByReportTypeForClientForSchedulingScreen(productId, reportTypeId) {
  const resource = `${API_BASE_URL}${REPORT_SELECTION_RESOURCES.getReportsListByReportTypeForClientForSchedulingScreen}/${productId}/${reportTypeId}`;
  return fetchData(resource);
}

function getMarketDisplayByOptions() {
  const resource = `${API_BASE_URL}${REPORT_SELECTION_RESOURCES.reportDisplayByOptions}`;
  return fetchData(resource);
}

function getMarketGroupByOptions() {
  const resource = `${API_BASE_URL}${REPORT_SELECTION_RESOURCES.reportGroupByOptions}`;
  return fetchData(resource);
}

export {
  getReports,
  getReportDisplayBundle,
  getMarketDisplayByOptions,
  getMarketGroupByOptions,
  getReportsListByReportTypeForClientForSchedulingScreen,
};
