import React from 'react';
import './Header.scss';
import experianLogo from './logo-experian.svg';
import autocountLogo from './autocount-accelerate.svg';

function Header() {
  return (
    <div className="header-wrapper">
      <div className="experian-logo">
        <a href="/">
          <img src={experianLogo} alt="Experian" data-cy="homepage-Experian-img" />
        </a>
      </div>
      <div className="autocount-logo">
        <a href="/">
          <img src={autocountLogo} alt="Autocount" data-cy="homepage-Autocount-img" />
        </a>
      </div>
    </div>
  );
}

export default Header;
