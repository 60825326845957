import { addUserQueryOptionRun } from '../../services/usersQueriesOptions.api';
import { REPORT_TYPES } from '../../utils/constants/constants';
import { UserQueryOptionRunSourceTypes } from '../../utils/constants/enums';
import { flattenJson, getCleanQueryCopy, getReportingPeriodId } from '../../utils/objectUtils';

export function validateJsonAndGetTicket(reportTypeId, query, functionType = 'run') {
  let reportId;
  let userQueryOptionReportingPeriodsJSON;
  let userQueryOptionJSON;
  const userQueryOptionId = query.savedQueryId;
  const userQueryOptionNm = query.queryName;

  let userQueryOptionRunSourceTypeId;
  if (query.userQueryOptionRunSourceTypeId) {
    userQueryOptionRunSourceTypeId = query.userQueryOptionRunSourceTypeId;
    reportId = parseInt(Object.values(query.chosenReports)[0].reportId);
  } else if (reportTypeId === REPORT_TYPES.marketReportTypeId) {
    reportId = parseInt(Object.values(query.chosenReports)[0].reportId);
    userQueryOptionRunSourceTypeId = UserQueryOptionRunSourceTypes.MarketAdvancedQuery;
  } else {
    reportId = parseInt(Object.values(query.chosenReports)[0].reportId);
    userQueryOptionRunSourceTypeId = UserQueryOptionRunSourceTypes.RiskAdvancedQuery;
  }

  if (userQueryOptionId === '') {
    const cleanedQuery = getCleanQueryCopy(query);
    userQueryOptionReportingPeriodsJSON = JSON.stringify(getReportingPeriodId(cleanedQuery));
    userQueryOptionJSON = JSON.stringify(flattenJson(cleanedQuery));
  } else {
    if (query?.reportingPeriods) {
      userQueryOptionReportingPeriodsJSON = JSON.stringify(getReportingPeriodId(query));
    } else userQueryOptionReportingPeriodsJSON = '';
    userQueryOptionJSON = JSON.stringify(flattenJson(query));
  }

  return addUserQueryOptionRun(
    userQueryOptionId,
    reportId,
    userQueryOptionReportingPeriodsJSON,
    userQueryOptionNm,
    userQueryOptionJSON,
    userQueryOptionRunSourceTypeId,
    functionType,
  ).then((e) => e);
}

export function removeEmptySpacesObject(object) {
  const cleanedObject = object;
  Object.entries(object).forEach(([key, value]) => {
    cleanedObject[key] = typeof value === 'string' ? value.trim() : value;
  });
  return cleanedObject;
}
