/* eslint-disable no-param-reassign */
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ALL = 'ALL';

function Select({
  id,
  value,
  testId,
  selectAttribute,
  displayAttribute,
  errorText,
  label,
  onChange,
  queryDataType,
  options,
  disabled = false,
  loading = false,
  hasAllOption = true,
  selectAllByDefault = true,
  dataCy,
}) {
  const [text, setText] = React.useState('');

  options = options ?? [];
  if (value) {
    if (!Object.keys(value).length) value = null;
    // eslint-disable-next-line prefer-destructuring
    else value = Object.values(value)[0];
  }

  if (hasAllOption) {
    const allOption = { [selectAttribute]: ALL, [displayAttribute]: ALL };

    options = [allOption, ...options];

    // eslint-disable-next-line prefer-destructuring
    if (selectAllByDefault && !value) value = options[0];
  }

  const handleOnChange = (e, element) => {
    if (element[selectAttribute] === ALL) onChange(null, queryDataType);
    else
      onChange(
        {
          [queryDataType]: { [element[selectAttribute]]: element },
        },
        queryDataType,
        element,
      );
  };

  return (
    <Autocomplete
      id={id}
      test-id={`autocomplete${testId}`}
      dataCy={`${dataCy}-select`}
      disableCloseOnSelect={false}
      disableClearable
      value={value}
      options={options}
      getOptionLabel={(option) => option?.[displayAttribute]}
      isOptionEqualToValue={(option, v) => option?.[selectAttribute] === v?.[selectAttribute]}
      onChange={handleOnChange}
      renderOption={(props, option) => (
        <li {...props} key={option?.[selectAttribute]}>
          {option[displayAttribute]}
        </li>
      )}
      disabled={!options?.length || disabled}
      popupIcon={<ArrowDropDownIcon className="select__popper" />}
      renderInput={(params) => (
        <TextField
          error={errorText}
          helperText={errorText}
          dataCy={`${dataCy}-select`}
          onChange={(e) => setText(e.target.value)}
          {...params}
          label={!value && !text && label}
          autoFocus={false}
          variant="outlined"
          disabled={!options?.length || disabled}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {(!options || loading) && <CircularProgress color="inherit" size={15} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default Select;
