import React from 'react';
import { removeEmptyValues } from '../../../../utils/objectUtils';
import { useAsync } from '../../../../utils/useAsync';
import getRiskScoreTypesActive from '../../../../services/riskScoreTypes.api';

function RiskOptionsDefault({ onChange }) {
  const [selectedRiskScoreType] = React.useState(1);
  const { run: runAvailableRiskScoreTypes } = useAsync();
  let availableRiskScoreTypes = [];

  function updateRiskOptionsQuery() {
    const riskOptions = {
      userQueryOptionId: 0,
      editableRiskOption: {
        scoreType: {
          riskScoreTypeId: availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeId,
          riskScoreTypeNm: availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeNm,
          riskScoreTypeParameterValueNm:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeParameterValueNm,
          riskScoreTypeMinValue:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeMinValue,
          riskScoreTypeMaxValue:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeMaxValue,
        },
        customScoreRangeOption: false,
        customScoreType: {
          riskScoreTypeId: availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeId,
          riskScoreTypeNm: availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeNm,
          riskScoreTypeParameterValueNm:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeParameterValueNm,
          riskScoreTypeMinValue:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeMinValue,
          riskScoreTypeMaxValue:
            availableRiskScoreTypes[selectedRiskScoreType].riskScoreTypeMaxValue,
        },
        customTiersOption: false,
      },
    };

    onChange({
      riskOptionsSelection: {
        ...removeEmptyValues(riskOptions),
      },
    });
  }
  React.useEffect(() => {
    runAvailableRiskScoreTypes(
      getRiskScoreTypesActive().then((availableRiskScoreTypesData) => {
        availableRiskScoreTypes = availableRiskScoreTypesData;
        updateRiskOptionsQuery(); // initial load with default values
        onChange();
      }),
    );
  }, [runAvailableRiskScoreTypes]);

  return <div />;
}

export default RiskOptionsDefault;
