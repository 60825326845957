import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

function Persona({ personaUser, personaStatus, setPersonaStatus }) {
  useEffect(() => {}, []);

  const handleCheckChange = () => {
    setPersonaStatus(!personaStatus);
    window.location.reload(false);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          data-cy="navbar-Persona-checkbox"
          id="PersonaCheckBox"
          checked={personaStatus}
          onChange={handleCheckChange}
        />
      }
      label={`Impersonate ${personaUser.personaEmail}`}
      labelPlacement="start"
    />
  );
}
export default Persona;
