import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const PERSONA_RESOURCES = RESOURCES.persona;

function getPersonaUserById(userId) {
  const resource = `${API_BASE_URL}${PERSONA_RESOURCES.getPersonaUserById}/${userId}`;
  return fetchData(resource);
}
function getPersonaDisplay() {
  const resource = `${API_BASE_URL}${PERSONA_RESOURCES.getPersonaDisplay}`;
  return fetchData(resource);
}

function getPersonaUsers() {
  const resource = `${API_BASE_URL}${PERSONA_RESOURCES.getPersonaUsers}`;
  return fetchData(resource);
}

export { getPersonaUserById, getPersonaDisplay, getPersonaUsers };
