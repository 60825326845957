import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const TERRITORY_RESOURCES = RESOURCES.territory;

function getAvailableDmasByState(stateId) {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableDmasByState}/${stateId}`;
  return fetchData(resource);
}

function getAvailableZipsByState(stateId) {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableZipsByState}/${stateId}`;
  return fetchData(resource);
}

function getAvailableCountiesByState(stateId) {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableCountiesByState}/${stateId}`;
  return fetchData(resource);
}

function getAvailableCountiesByDma(dmaId, stateId) {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableCountiesByDma}/${dmaId}/${stateId}`;
  return fetchData(resource);
}

function getZipsInRange(centroid, radius) {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.zipsInRange}/${centroid}/${radius}`;
  return fetchData(resource);
}

function getAvailableStatesByClientId() {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableStatesByClientId}`;
  return fetchData(resource);
}

function getAvailableStates() {
  const resource = `${API_BASE_URL}${TERRITORY_RESOURCES.availableStates}`;
  return fetchData(resource);
}

export {
  getAvailableDmasByState,
  getAvailableZipsByState,
  getAvailableCountiesByState,
  getAvailableCountiesByDma,
  getZipsInRange,
  getAvailableStatesByClientId,
  getAvailableStates,
};
