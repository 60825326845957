import { makeStyles } from 'tss-react/mui';
import { red } from '@mui/material/colors';

const drawerWidth = 280;
const red300 = red['500'];

export const useStylesLandingProductsDisplay = makeStyles()((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    bottom: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 15,
    boxShadow: 16,
  },
  cardMedia: {
    paddingTop: '56.25%',
  },
  cardContent: {
    flexGrow: 1,
    height: '140px',
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));
export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    '& .MuiListItemIcon-root': {
      minQidth: 35
    },
    '& .makeStyles-drawerPaper-9': {
      whiteSpace: 'normal'
    },
    '& .MuiTypography-body1': {
      fontSize: '0.87rem !important',
      // 'font-family': '"Roboto","Helvetica","Arial",sans-serif',
      fontFamily: '"Open Sans"',
      fontWeight: 400
    },
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      color: '#007bff'
    },
    '& .MuiButton-root': {
      padding: '5px 12px !important',
      marginRight: '5px',
      textTransform: 'none',
    },
    '& .MuiTextField-root': {
      minWidth: '100%'
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 10
    },
    '&  .MuiInputLabel-outlined': {
      fontSize: '0.87rem'
    },
    '&  .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(12px) scale(0.75)'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      fontSize: 10
    },
    '& .MuiTypography-displayBlock': {
      fontSize: 12
    },
    '& .edge-autoSuggestFieldFilled': {
      fontSize: '0.87rem',
      backgroundColor: 'transparent !important'
    },
    '& .edge-autofilled': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiInputBase-input': {
      fontSize: '0.87rem'
    },
    '& .MuiInputBase-input .MuiInput-input': {
      fontSize: '0.87rem'
    },
    '& .MuiFormLabel-root': {
      fontSize: '0.87rem',
    },
    '& .btnNormal': {
      borderColor: '#ccc',
      backgroundColor: '#fbfbfb'
    },
    '& .MuiListItemAvatar-root': {
      minWidth: '35px'
    },
    '& .MuiListItem-container': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontWeight: '400 !important',
      paddingRight: '8px',
      borderTopRightRadius: '16px',
      borderBottomRightRadius: '16px'
    },
    '& .MuiMenuItem-root': {
      fontSize: 12,
      paddingLeft: '9px',
      paddingRight: '9px'
    }
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    width: '100%'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    maxHeight: '64px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: 'none'
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  },
  textareaAutosize: {
    width: '100%',
    borderRadius: '2px',
    border: '1px solid #e2e2e1',
    fontSize: '0.87rem'
  },
  error: {
    fontSize: '12px',
    color: red300,
    position: 'absolute',
    marginLeft: '14px',
    marginTop: '3px'
  },
  subModalContainer: {
    marginTop: '5%'
  },
  treeRoot: {
    flexGrow: 1,
    maxWidth: 400,
    padding: 10,
    marginLeft: '5%'
  },
  heroButton: {
    backgroundColor: '#ae3e92 !important',
    color: 'white !important',
    height: '40px !important',
    borderRadius: 6,
    paddingLeft: '40px !important',
    paddingRight: '40px !important'
  },
  spotlightButton: {
    backgroundColor: '#26478d !important',
    color: 'white !important',
    height: '44px !important',
    borderRadius: 6,
    paddingLeft: '40px !important',
    paddingRight: '40px !important',
    width: '100% !important'
  },
  loginTextField: {
    height: '60px !important',
    width: '100% !important',
    color: 'primary !important'
  },
  notificationsListRoot: {
    flexGrow: 1,
    maxWidth: 400,
    position: 'absolute ',
    height: 'auto !important',
    right: '1% !important'
  },
  notificationListChild: {
    maxHeight: 'auto !important',
    overflow: 'auto !important',
    width: '400px !important',
    marginRight: '0px !important'
  },
  listItemBody: {
    marginLeft: '10px !important'
  },
  errorPaper: {
    borderRadius: 6,
    paddingLeft: '40px !important',
    paddingRight: '40px !important',
    height: 'fite-content !important',
    width: 'fit-content !important',
    textAlign: 'center !important',
    direction: 'column !important',
    alignItems: 'center !important',
    justify: 'center !important',
    float: 'none !important',
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
    boxShadow: {
      color: red
    }
  },
  errorIcon: {
    fontSize: '50px',
    paddingBottom: '10px',
    marginLeft: '5px'
  },
  avatarDropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: -75,
    zIndex: 1,
    width: '150px',
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  avatarRoot: {
    position: 'relative'
  }
}));
