import React, { useState, useEffect, useContext } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import './TerritorySelection.scss';
import MaterialMultiSelect from '../../../materialMultiSelect/MaterialMultiSelect';
import { removeEmptyValues } from '../../../../utils/objectUtils';
import {
  getAvailableCountiesByState,
  getAvailableDmasByState,
  getAvailableZipsByState,
} from '../../../../services/territory.api';
import { StoreContext } from '../../../../store/storeContext';

const radioElements = [
  { label: 'DMA®', value: 'DMA' },
  { label: 'County', value: 'County' },
  { label: 'ZIP', value: 'ZIP' }
];

const selectorsConfig = (dmas, counties, zips) => ({
  DMA: {
    title: 'DMAs',
    Id: 'dmaId',
    displayData: dmas,
    optionText: 'acnDmaNm',
    queryDataType: 'dmas'
  },
  County: {
    title: 'Counties',
    Id: 'countyId',
    displayData: counties,
    optionText: 'countyNm',
    queryDataType: 'counties'
  },
  ZIP: {
    title: 'Zips',
    Id: 'zipCityId',
    displayData: zips,
    optionText: 'zipCd',
    queryDataType: 'zips'
  }
});

function TerritorySelection({ selectedState, territorySelection, onChange }) {
  const [selectedRadioOption, setSelectedRadioOption] = useState(radioElements[0].value);
  const [dmas, setDMAs] = useState([]);
  const [counties, setCounties] = useState([]);
  const [zips, setZips] = useState([]);
  const [config, setConfig] = useState();
  const { actions } = useContext(StoreContext);

  useEffect(() => {
    const stateId = Object.keys(selectedState);
    getAvailableDmasByState(stateId)
      .then((data) => setDMAs(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getAvailableCountiesByState(stateId)
      .then((data) => setCounties(data))
      .catch((error) => {
        actions.errorAction(error);
      });
    getAvailableZipsByState(stateId)
      .then((data) => setZips(data))
      .catch((error) => {
        actions.errorAction(error);
      });
  }, [selectedState]);

  useEffect(() => {
    if (dmas.length > 0 && zips.length > 0 && counties.length > 0) {
      setConfig(selectorsConfig(dmas, counties, zips));
    }
  }, [dmas, counties, zips]);

  const handleInnerChange = (data) => {
    const type = config[selectedRadioOption].queryDataType;
    onChange({
      territorySelection: {
        ...removeEmptyValues({
          ...territorySelection,
          ...{ [type]: data[type] }
        })
      }
    });
  };

  useEffect(() => {
    if (territorySelection?.dmas) {
      setSelectedRadioOption(radioElements[0].value);
    } else if (territorySelection?.counties) {
      setSelectedRadioOption(radioElements[1].value);
    } else if (territorySelection?.zips) {
      setSelectedRadioOption(radioElements[2].value);
    }  
  }, []);

  const handleGeographicalRadioOpitonChange = (event) => {
    setSelectedRadioOption(event.target.value);
    onChange({
      territorySelection: {
        ...removeEmptyValues({
          ...territorySelection,
          states: selectedState
        })
      }
    });
  };

  return (
    <div className="territory__mainContainer">
      <div className="territory__countyContainer">
        {config && (
          <>
            <FormControl fullWidth>
              <RadioGroup
                className="territory__radioGroup"
                data-testid="territoryRadioGroup"
                id="territoryRadioGroup"
                value={selectedRadioOption}
                onChange={handleGeographicalRadioOpitonChange}
              >
                {radioElements.map((element) => (
                  <FormControlLabel
                    data-testid={element.value}
                    key={element.value}
                    value={element.value}
                    control={<Radio />}
                    label={element.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>

            <MaterialMultiSelect
              id={selectedRadioOption}
              options={config[selectedRadioOption].displayData}
              label={config[selectedRadioOption].title}
              objectRenderField={config[selectedRadioOption].optionText}
              onChange={handleInnerChange}
              queryDataType={config[selectedRadioOption].queryDataType}
              selectAttribute={config[selectedRadioOption].Id}
              value={territorySelection[config[selectedRadioOption].queryDataType]}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default TerritorySelection;
