const validationStaticMessages = {
  msgSameGroupsClient: 'The selected groups must belong to the same client.',
  msgMaximumEditGroups: 'No more than 5 groups can be selected at a time.',
  msgMandatoryField: 'This field is required',
  msgMinimumEditGroup: 'At least one group must be kept.',
  msgMaximumUsersPerClient: 'This client has reached the maximum number of users allowed',
  msgMandatoryGroupForProduct: 'Choose at least one group for the selected product(s)',
};

const errorStaticMessages = {
  msgServerConnectionFailed: 'Network error. The server does not respond.',
};

const notificationStaticMessages = {
  msgAddUser: 'The user has been created.',
};

module.exports = {
  validationStaticMessages,
  errorStaticMessages,
  notificationStaticMessages,
};
