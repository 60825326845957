import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import TabsNavigation from './TabNavigation';
import { renderRoutes } from '../../utils/router.utils';

function TabsNavigationManager({ routes, layout, homePath, sharedProps, children }) {
  const history = useHistory();

  const getActiveTabIndex = () => {
    const index = routes.findIndex((route) => route.path === history.location.pathname);
    return index > -1 ? index : 0;
  };

  const updateTab = (tabIndex) => {
    history.push(routes[tabIndex].path);
  };

  return (
    <>
      <TabsNavigation
        activeTabIndex={getActiveTabIndex()}
        updateTab={updateTab}
        tabItems={routes.filter(({ isHidden }) => !isHidden).map(({ name }) => name)}
      >
        {children}
      </TabsNavigation>
      {renderRoutes(routes, layout, homePath, sharedProps)}
    </>
  );
}

TabsNavigationManager.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  layout: PropTypes.string.isRequired,
  homePath: PropTypes.string,
  sharedProps: PropTypes.shape({}),
  children: PropTypes.node,
};

TabsNavigationManager.defaultProps = {
  children: null,
  homePath: null,
  sharedProps: {},
};

export default TabsNavigationManager;
