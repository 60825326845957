import React from 'react';
import { Box, Typography } from '@mui/material';
import HeroButton from '../heroButton/HeroButton';
import { useAuthentication } from '../../contexts/Authentication';

function WelcomeHeader() {
  const { logout, userInfo } = useAuthentication();
  const { firstName } = userInfo;

  let firstNameDisplay = firstName;

  if (/[a-z]/.test(firstName[0])) {
    firstNameDisplay = firstName[0].toUpperCase() + firstName.slice(1);
  }

  return (
    <div id="headerDisplay">
      <Typography variant="h2" className="welcomeBanner" data-cy="loginpage-Welcome-label">
        Welcome Back,
        <Box fontWeight="fontWeightMedium" display="inline">
          {` ${firstNameDisplay}!`}
        </Box>
      </Typography>
      <HeroButton text="Logout" onClick={logout} />
    </div>
  );
}

export default WelcomeHeader;
