/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import './TabNavigation.scss';

function TabsNavigation({ children, tabItems, activeTabIndex, updateTab }) {
  return (
    <div className="tabsNavigator__container">
      <div className="tabsNavigator__inner">
        <div className="tabsNavigator__navLeft">
          {tabItems?.map((tab, i) => (
            <div role="tab" key={tab} className="tabsNavigator__innerNavItem">
              <div
                className={`hover hover-3  ${
                  activeTabIndex === i && 'tabsNavigator__menuItemActive'
                }`}
                key={`nav-${i}`}
                onClick={() => updateTab(i, tab)}
                data-cy={`aq-${tab.replace(/ /g, '')}-tab`}
              >
                {tab}
              </div>
            </div>
          ))}
        </div>
        <div className="tabsNavigator__navRight">{children}</div>
      </div>
    </div>
  );
}

export default TabsNavigation;
