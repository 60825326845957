/* eslint-disable no-undef */
import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { useToast } from '../../contexts/Toast';
import { REPORT_TYPES } from '../../utils/constants/constants';
import './Export.scss';

function Export({ query, viz }) {
  const { setToastState } = useToast();

  const chosenReports = query && query.chosenReports;

  // eslint-disable-next-line consistent-return
  const getExportSheetName = (reportTypeId) => {
    switch (reportTypeId) {
      case REPORT_TYPES.marketReportTypeId:
        return chosenReports != null ? chosenReports[Object.keys(chosenReports)].filterSheetNm : '';
      default:
    }
  };

  const exportSheetName = chosenReports? getExportSheetName(
    chosenReports[Object.keys(chosenReports)]?.reportTypeId,
  ) : '';

  const displayErrorToast = (severity, error) => {
    setToastState({
      open: true,
      severity,
      message: error,
    });
  };

  const triggerPDFExport = () => {
    try {
      viz.showExportPDFDialog();
    } catch (err) {
      displayErrorToast('error', err.message);
    }
  };

  const triggerCSVExport = () => {
    try {
      viz.showExportCrossTabDialog(exportSheetName);
    } catch (err) {
      displayErrorToast('error', err.message);
    }
  };

  const triggerExcelExport = () => {
    try {
      viz.exportCrossTabToExcel(exportSheetName);
    } catch (err) {
      displayErrorToast('error', err.message);
    }
  };

  return (
    <div className="tableau-vizualization__actions">
      <div>
        <Tooltip title="Open PDF export dialog">
          <Button
            data-cy="criteria-ExportPDF-button"
            className="tableau-vizualization__button"
            variant="contained"
            onClick={triggerPDFExport}
            color="primary"
          >
            <GetAppIcon /> PDF
          </Button>
        </Tooltip>
        <Tooltip title="Download excel">
          <Button
            data-cy="criteria-ExportExcel-button"
            className="tableau-vizualization__button"
            variant="contained"
            onClick={triggerExcelExport}
            color="primary"
          >
            <GetAppIcon /> Excel
          </Button>
        </Tooltip>

        <Tooltip title="Download CSV">
          <Button
            data-cy="criteria-ExportCSV-button"
            className="tableau-vizualization__button"
            variant="contained"
            onClick={triggerCSVExport}
            color="primary"
          >
            <GetAppIcon /> CSV
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}

export { Export };
