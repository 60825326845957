import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

function SingleSelect({
  options,
  handleChange,
  label,
  displayAttribute,
  selectAttribute,
  testId,
  ...rest
}) {
  return (
    <Autocomplete
      test-id={`singleSelect${testId}`}
      options={options}
      getOptionLabel={(option) => option?.[displayAttribute]}
      renderOption={(props, option) => <li {...props}>{option[displayAttribute]}</li>}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
      {...rest}
    />
  );
}

export default SingleSelect;
