import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import FormField from '../../../../formField/FormField';

function ScoreTypeSelector({
  selectedRiskScoreType,
  setSelectedRiskScoreType,
  availableRiskScoreTypes,
  setMinTierValue,
  customScoreRangeOption,
  setCustomScoreRangeOption,
  setCustomRiskScoreTypeMinValue,
  setMaxTierValue,
  setCustomTiers,
  setCustomRiskScoreTypeMaxValue,
  customTiers,
  validateAll,
  setChangeNotSaved,
  setHideOwnScoreRangeSection,
  hideOwnScoreRangeSection,
  customRiskScoreTypeMinValue,
  customRiskScoreTypeMaxValue,
  minTierValue,
  maxTierValue,
  ownScoreRangeErrorMessage,
  setValidationErrors,
  validateErrorsInOwnScoreRange,
}) {
  const [customMin, setCustomMin] = useState();
  const [customMax, setCustomMax] = useState();
  const handleScoreTypeChange = (event) => {
    if (selectedRiskScoreType !== parseInt(event.target.value)) {
      setSelectedRiskScoreType(parseInt(event.target.value));
      const scoreTypeSelected = availableRiskScoreTypes.find(
        ({ riskScoreTypeId }) => riskScoreTypeId === parseInt(event.target.value),
      );
      if (scoreTypeSelected?.riskScoreTypeMinValue) {
        setMinTierValue(parseInt(scoreTypeSelected.riskScoreTypeMinValue));
        setCustomRiskScoreTypeMinValue(parseInt(scoreTypeSelected.riskScoreTypeMinValue));
        setCustomMin(parseInt(scoreTypeSelected.riskScoreTypeMinValue));
      }
      if (scoreTypeSelected?.riskScoreTypeMaxValue) {
        setMaxTierValue(parseInt(scoreTypeSelected.riskScoreTypeMaxValue));
        setCustomRiskScoreTypeMaxValue(parseInt(scoreTypeSelected.riskScoreTypeMaxValue));
        setCustomMax(parseInt(scoreTypeSelected.riskScoreTypeMaxValue));
      }
      validateAll(customTiers, customMin, customMax, minTierValue, maxTierValue);
      setChangeNotSaved(true);
    }
  };

  function handleISetMyScoreRange() {
    setHideOwnScoreRangeSection(!hideOwnScoreRangeSection);
    setCustomScoreRangeOption(!customScoreRangeOption);
    setChangeNotSaved(true);
  }

  function handleOwnScoreRangeChange(field, newValue) {
    if (field === 'low') {
      setCustomMin(newValue);
      setCustomRiskScoreTypeMinValue(newValue);
      setChangeNotSaved(true);
    } else if (field === 'high') {
      setCustomMax(newValue);
      setCustomRiskScoreTypeMaxValue(newValue);
      setChangeNotSaved(true);
    }
  }

  useEffect(() => {
    validateAll(customTiers, customMin, customMax, minTierValue, maxTierValue);
    setCustomTiers([
      {
        id: 1,
        tierName: 'Tier 1',
        low: customRiskScoreTypeMinValue,
        high: customRiskScoreTypeMaxValue,
      },
    ]);
    setValidationErrors(
      validateErrorsInOwnScoreRange(
        customRiskScoreTypeMinValue,
        customRiskScoreTypeMaxValue,
        minTierValue,
        maxTierValue,
      ),
    );
    // setValidationErrors(false);
  }, [customMin, customMax]);
  return (
    <Grid item container>
      <Grid item xs={12}>
        <FormField
          fieldTitle="Score Type"
          fieldInput={
            <>
              <FormControl component="fieldset">
                <FormLabel component="legend" />

                <RadioGroup
                  id="selectScoreTypeRiskOptions"
                  test-id="selectScoreTypeRiskOptions"
                  data-cy="rsk-SelectScoreType-radio"
                  row
                  aria-label="Score Type Radio Group"
                  name="scoreTypeRadioGroupValue"
                  value={parseInt(selectedRiskScoreType)}
                  onChange={(event) => handleScoreTypeChange(event)}
                >
                  {availableRiskScoreTypes?.map((row) => (
                    <FormControlLabel
                      id={row?.riskScoreTypeNm}
                      key={row?.riskScoreTypeId}
                      control={<Radio value={row?.riskScoreTypeId} />}
                      label={row?.riskScoreTypeNm}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <FormControlLabel
                className="checkbox"
                control={
                  <Checkbox
                    id="checkBoxScoreTypeRiskOptions"
                    test-id="checkBoxScoreTypeRiskOptions"
                    data-cy="rsk-SelectScoreType-checkbox"
                    checked={customScoreRangeOption}
                    onChange={() => handleISetMyScoreRange()}
                    name="I want to set my score range"
                    color="primary"
                  />
                }
                label="I want to set my Score Range"
              />
            </>
          }
        />
      </Grid>

      {!hideOwnScoreRangeSection && (
        <Grid item xs={12} container direction="row" spacing={2}>
          <Grid item>
            <FormLabel> Range:</FormLabel>
          </Grid>
          <Grid item>
            <FormLabel>Low (min. {minTierValue})</FormLabel>
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              value={customRiskScoreTypeMinValue}
              onChange={(event) => handleOwnScoreRangeChange('low', event.target.value)}
            />
          </Grid>
          <Grid item>
            <FormLabel>Max (max. {maxTierValue})</FormLabel>
          </Grid>
          <Grid item>
            <TextField
              id="standard-basic"
              sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
              value={customRiskScoreTypeMaxValue}
              onChange={(event) => handleOwnScoreRangeChange('high', event.target.value)}
            />
          </Grid>
          <Grid item>
            <Alert severity="error" hidden={ownScoreRangeErrorMessage.length === 0}>
              {ownScoreRangeErrorMessage}
            </Alert>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ScoreTypeSelector;
