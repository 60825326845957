import './DealersAndLendersSelection.scss';
import React, { useState, useEffect, useContext } from 'react';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import { searchDealers, searchLenders } from '../../../../services/dealersAndLenders.api';
import MaterialMultiSelect from '../../../materialMultiSelect/MaterialMultiSelect';
import { removeEmptyValues } from '../../../../utils/objectUtils';
import { StoreContext } from '../../../../store/storeContext';
import FormField from '../../../formField/FormField';

function DealersAndLendersSelection({
  dealersAndLenders,
  onChange,
  states,
  resetState,
  setResetState,
}) {
  const selectedStateIds = states && Object.keys(states);
  const [dealers, setDealers] = useState([]);
  const [lenders, setLenders] = useState([]);
  const [loadingDealer, setLoadingDealer] = useState(false);
  const [loadingLender, setLoadingLender] = useState(false);
  const [searchTermDealer, setSearchTermDealer] = useState('');
  const [searchTermLender, setSearchTermLender] = useState('');
  const { actions } = useContext(StoreContext);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchTermDealer !== '') {
      const timeOutId = setTimeout(async () => {
        setLoadingDealer(true);
        let response;
        try {
          response = await searchDealers(selectedStateIds, searchTermDealer, 1);
        } catch (e) {
          actions.errorAction(e);
          setLoadingDealer(false);
        }
        setLoadingDealer(false);
        setDealers(response);
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchTermDealer]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchTermLender !== '') {
      const timeOutId = setTimeout(async () => {
        setLoadingLender(true);
        let response;
        try {
          response = await searchLenders(selectedStateIds, searchTermLender, 1);
        } catch (e) {
          actions.errorAction(e);
          setLoadingLender(false);
        }
        setLoadingLender(false);
        setLenders(response);
      }, 500);
      return () => clearTimeout(timeOutId);
    }
  }, [searchTermLender]);

  const handleInnerChange = (data) => {
    const { ...auxDealersAndLenders } = dealersAndLenders;
    onChange({
      dealersAndLendersSelection: {
        ...removeEmptyValues({ ...auxDealersAndLenders, ...data }),
      },
    });
  };

  const dealerChange = (value) => {
    if (searchTermDealer !== value) {
      setSearchTermDealer(value);
    }
  };

  const lenderChange = (value) => {
    if (searchTermLender !== value) {
      setSearchTermLender(value);
    }
  };

  return (
    <Paper elevation={0} className="dealersAndLendersSelection__papper">
      <Grid className="indicatorMultiSelect" container direction="row" spacing={3}>
        <Grid item xs>
          <FormField
            fieldTitle="Dealer"
            dataCy="aq-dealers"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectDealerId"
                searchMode
                limitTags={10}
                testId="DelearsSelected"
                options={dealers}
                label="Dealer"
                objectRenderField="dealerNm"
                loading={loadingDealer}
                onChangeText={dealerChange}
                onChange={handleInnerChange}
                queryDataType="dealers"
                selectAttribute="dealerId"
                defaultAll={false}
                resetState={resetState}
                setResetState={setResetState}
                value={dealersAndLenders?.dealers}
                dataCy="aq-dealers"
              />
            }
          />
        </Grid>
        <Grid item xs>
          <FormField
            fieldTitle="Lender"
            dataCy="aq-lenders"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectLenderId"
                searchMode
                limitTags={10}
                testId="LendersSelected"
                options={lenders}
                label="Lender"
                objectRenderField="lenderNm"
                loading={loadingLender}
                onChangeText={lenderChange}
                onChange={handleInnerChange}
                queryDataType="lenders"
                selectAttribute="lenderId"
                defaultAll={false}
                resetState={resetState}
                setResetState={setResetState}
                value={dealersAndLenders?.lenders}
                dataCy="aq-lenders"
              />
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default DealersAndLendersSelection;
