import './TableauManager.scss';
import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory, useLocation } from 'react-router-dom';
import { TextLoader } from '../loader/TextLoader';
import { useTableauQuery } from '../../contexts/TableauQuery';
import { AdditionalInfo } from '../additionalInfo/AdditionalInfo';
import {
  TableauVizualization,
  IFRAME_BREAKPOINT,
} from '../tableauVizualization/TableauVizualization';

function TableauManager({ setIsHidden, defaultPath, reportTypeId, ...props }) {
  const history = useHistory();
  const location = useLocation();
  const [query] = useTableauQuery();
  const [isLoading, setIsLoading] = useState();
  const [report, setReport] = useState(false);
  const [queryIsVisible] = useState(false);
  const [showVizError, setShowVizError] = useState(false);
  const [vizReference, setVizReference] = useState(null);
  const [refreshQuery, setRefreshQuery] = useState(false);
  const [queryInfo, setQueryInfo] = useState(null);

  const hideInPopover = useMediaQuery(`(max-width:${IFRAME_BREAKPOINT}px)`);

  useEffect(() => {
    if (
      query?.chosenReports &&
      Object.keys(query?.chosenReports).length === 0 &&
      query?.territorySelection?.states &&
      Object.keys(query?.territorySelection?.states).length === 0
    ) {
      setIsHidden(true);
      history.push({
        pathname: defaultPath,
      });
    } else {
      setIsHidden(false);
      setReport(true);
      setRefreshQuery(!refreshQuery);
    }

    return () => {
      setReport(false);
      if (vizReference !== null) {
        vizReference.dispose();
      }
    };
  }, [location]);

  const removeVizErrorFlags = () => {
    setIsLoading(false);
    if (showVizError) {
      setShowVizError(false);
    }
  };

  return (
    <>
      {isLoading && <TextLoader />}
      <div className="tableauManager__containerMain">
        {report && (
          <>
            {hideInPopover && (
              <AdditionalInfo
                query={queryInfo}
                reportTypeId={reportTypeId}
                viz={vizReference}
                hideInPopover={hideInPopover}
              />
            )}
            <div id="tableauContainer">
              <TableauVizualization
                setQueryInfo={setQueryInfo}
                setVizReference={setVizReference}
                vizReference={vizReference}
                refreshQuery={refreshQuery}
                setRefreshQuery={setRefreshQuery}
                query={query}
                removeVizErrorFlags={removeVizErrorFlags}
                show={queryIsVisible}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                reportTypeId={reportTypeId}
                {...props}
              />
            </div>
            {!hideInPopover && !isLoading && queryInfo !== null && (
              <div id="queryContainer">
                <AdditionalInfo
                  query={queryInfo}
                  reportTypeId={reportTypeId}
                  viz={vizReference}
                  hideInPopover={hideInPopover}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default TableauManager;
