import React, { useMemo } from 'react';
import Toast from '../components/toast/Toast';

const ToastContext = React.createContext();
ToastContext.displayName = 'ToastContext';

function ToastProvider({ children, ...props }) {
  const [toastState, setToastState] = React.useState({});

  const toggleToast = (flag) => {
    setToastState({ ...toastState, open: flag });
  };

  return (
    <ToastContext.Provider
      value={useMemo(
        () => ({
          toastState,
          setToastState,
        }),
        [toastState, setToastState],
      )}
      {...props}
    >
      <Toast {...toastState} setOpen={toggleToast} />
      {children}
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = React.useContext(ToastContext);
  if (!context) throw new Error(`useToast must be used within the ToastProvider`);

  return context;
}

export { ToastProvider, useToast };
