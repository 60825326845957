import { createTheme } from '@mui/material/styles';

export default createTheme({
  typography: {
    h1: {
      fontWeight: '400',
      fontSize: '18pt',
      color: '#575757',
    },
    h4: {
      fontWeight: '600',
      fontSize: '15px',
      display: 'block',
      marginBottom: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#406eb3',
    },
    secondary: {
      main: '#E33E7F',
    },
  },
});
