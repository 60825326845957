import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const HELP_CENTRAL_RESOURCES = RESOURCES.helpCentral;

function getDisplayContactUs() {
  const resource = `${API_BASE_URL}${HELP_CENTRAL_RESOURCES.contactUsPath}/`;
  return fetchData(resource);
}

function sendEmail(clientNotes) {
  const resource = `${API_BASE_URL}${HELP_CENTRAL_RESOURCES.sendEmail}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      clientNotes,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

export { getDisplayContactUs, sendEmail };
