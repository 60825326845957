import './MarketReport.scss';
import React from 'react';
import { useHistory } from 'react-router-dom';
import TabsNavigationManager from '../../components/tabNavigator/TabNavigationManager';
import { REPORT_TYPES } from '../../utils/constants/constants';
import { routes, LAYOUT } from '../../utils/constants/market.routes';

const QUICK_QUERY_KEY = `${REPORT_TYPES.marketReportTypeId}-qq`;
const QUICK_QUERY_REPORTING_PERIOD_TEXT_KEY = `${REPORT_TYPES.marketReportTypeId}-qq-rpt`;
const QUICK_QUERY_REPORTING_PERIOD_SELECTED_KEY = `${REPORT_TYPES.marketReportTypeId}-qq-rps`;
const ADVANCED_QUERY_KEY = `${REPORT_TYPES.marketReportTypeId}-aq`;

function MarketReport() {
  const [innerRoutes, setInnerRoutes] = React.useState(routes);
  const history = useHistory();
  const setIsHidden = (flag) => {
    const k = Object.keys(innerRoutes).find(
      (key) => history.location.pathname === innerRoutes[key].path,
    );
    const aux = { ...innerRoutes };
    aux[k].isHidden = flag;
    setInnerRoutes(aux);
  };

  const sharedProps = {
    reportTypeId: REPORT_TYPES.marketReportTypeId,
    setIsHidden,
    defaultPath: routes.advancedQuery.path,
    reportPath: routes.reportDisplay.path,
  };

  React.useEffect(() => {
    // cleanup localstorage keys
    window.localStorage.removeItem(QUICK_QUERY_KEY);
    window.localStorage.removeItem(QUICK_QUERY_REPORTING_PERIOD_TEXT_KEY);
    window.localStorage.removeItem(QUICK_QUERY_REPORTING_PERIOD_SELECTED_KEY);
    window.localStorage.removeItem(ADVANCED_QUERY_KEY);
  }, []);

  return (
    <div className="tabs_manager_wrapper">
      <TabsNavigationManager
        routes={Object.values(innerRoutes)}
        layout={LAYOUT}
        sharedProps={sharedProps}
      />
    </div>
  );
}

export default MarketReport;
