import { FormLabel } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../formField/FormField';
import MaterialMultiSelect from '../materialMultiSelect/MaterialMultiSelect';

function ReportType({
  id,
  availableReports,
  reportSelection,
  resetState,
  setResetState,
  handleReportChange,
}) {
  return (
    <>
      <FormField
        fieldTitle="Report Type *"
        dataCy="aq-ReportType"
        fieldInput={
          <MaterialMultiSelect
            id={id}
            testId="ReportType"
            options={availableReports}
            label="Report Type"
            objectRenderField="reportNm"
            onChange={handleReportChange}
            queryDataType="chosenReports"
            selectAttribute="reportId"
            value={reportSelection?.chosenReports}
            resetState={resetState}
            setResetState={setResetState}
            defaultAll={false}
            hideAllCheckBox
            dataCy="aq-ReportType"
          />
        }
      />

      <FormLabel>
        {availableReports?.length === undefined || availableReports?.length === 0
          ? 'Your current data access does not allow any report selection.'
          : ''}
      </FormLabel>
    </>
  );
}

ReportType.propTypes = {
  id: PropTypes.string.isRequired,
  availableReports: PropTypes.arrayOf(PropTypes.shape({})),
  reportSelection: PropTypes.shape({}),
  handleReportChange: PropTypes.func.isRequired,
};

ReportType.defaultProps = {
  availableReports: [],
  reportSelection: {},
};

export default ReportType;
