import cloneDeep from 'lodash/cloneDeep';

export const objectFromArray = (list, property) =>
  list.reduce((obj, item) => Object.assign(obj, { [item[property]]: item }), {});

export const allSelectionChangeQuery = (onChange, queryDataType, data) => {
  onChange(
    {
      [queryDataType]: data,
    },
    queryDataType,
    'all',
  );
};

export const selectAll = (
  checkValue,
  options,
  onChange,
  searchMode,
  queryDataType,
  setCheckValue,
  selectAttribute,
) => {
  let currentValues = cloneDeep(checkValue);
  const queryData = objectFromArray(currentValues, selectAttribute);
  if (queryData) {
    options.forEach((item) => {
      if (queryData[item[selectAttribute]] === undefined) currentValues.push(item);
    });
  } else {
    currentValues = options;
  }
  setCheckValue(currentValues);
  allSelectionChangeQuery(
    onChange,
    queryDataType,
    searchMode ? objectFromArray(currentValues, selectAttribute) : {},
  );
};
