import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { groupBy, cloneDeep, isEqual } from 'lodash';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { getRiskControls } from '../../../../../services/riksService.api';
import { StoreContext } from '../../../../../store/storeContext';
import './ControlsByReport.scss';
import FocusLenderManager from '../FocusLenderManager';
import RiskControlMultiSelect from "./RiskControlMultiSelect";

const removeSpaces = (value) =>
  value.replace(/\w+/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1)).replace(/\s/g, '');

function ControlsByReport({ onChange, query, setChangeNotSaved, isExecutiveSummaryReport, focusLender, resetState, setResetState, setValidationErrors }) {
  const [controls, setControls] = useState([]);
  const { actions } = useContext(StoreContext);
  const [selectedReports, setSelectedReports] = useState();
  const [riskControlsErrorMessage, setRiskControlsErrorMessage] = useState('');

  function validateErrorsInRiskControls()
  {
    let errorsInRiskControls = false;
    const riskDynamicFields = query?.riskOptionsSelection?.riskDynamicFields;

    if (riskDynamicFields) {
      Object.keys(riskDynamicFields).forEach((reportKey) => {
        if (riskDynamicFields && riskDynamicFields[reportKey] && (Object.values(riskDynamicFields[reportKey]))) {

          Object.keys(riskDynamicFields[reportKey]).forEach((riskControlKeys) => {
            const controlValues = Object.values(riskDynamicFields[reportKey][riskControlKeys])
            if ((controlValues) && (Object.keys(controlValues).length === 0)) {
              errorsInRiskControls = true;
            }
        });
        };
      });
    }

    if (
      errorsInRiskControls
    ) {
      setRiskControlsErrorMessage("You must select at least one risk control per report type.")
      setValidationErrors(true);
    } else {
      setRiskControlsErrorMessage("");
      setValidationErrors(false);
    } 
  }

  function cleanUpNonRequiredRiskControls() {
    if (query?.chosenReports && query?.riskOptionsSelection?.riskDynamicFields) {
      const reportIds = Object.keys(query?.chosenReports)
      const riskOptionsSelection = query?.riskOptionsSelection;
      const riskDynamicFields = query?.riskOptionsSelection?.riskDynamicFields;
      const cleanedUpRiskDynamicFields = cloneDeep(query?.riskOptionsSelection?.riskDynamicFields);
      if ((reportIds === undefined || reportIds.length === 0) && riskOptionsSelection && riskOptionsSelection.riskDynamicFields) 
      {
        delete riskOptionsSelection.riskDynamicFields;
        onChange({
          riskOptionsSelection
          });
        return;
      } 

      if (riskDynamicFields) {
        Object.keys(riskDynamicFields).forEach((key) => {
          if (reportIds === undefined || reportIds.length === 0 || reportIds.indexOf(key) === -1) {
            delete cleanedUpRiskDynamicFields[key];
          }
        });
        if (Object.keys(cleanedUpRiskDynamicFields).length === 0) {
          delete riskOptionsSelection.riskDynamicFields;
          onChange({
              riskOptionsSelection,
          });
        }
        else if (!(isEqual(riskDynamicFields, cleanedUpRiskDynamicFields))) {
          onChange({
            riskOptionsSelection: {
              ...query?.riskOptionsSelection,
              riskDynamicFields: cleanedUpRiskDynamicFields,
            },
          });
        }
      }
    }
  }

  useEffect(() => {
    setSelectedReports(
      Object.fromEntries(
        Object.entries(query?.chosenReports).map(([key, val]) => [key, val?.reportNm]),
      ),
    );
    cleanUpNonRequiredRiskControls();
  }, [query?.chosenReports]);

  useEffect(() => {
    getRiskControls()
      .then((data) => {
        Object.keys(data).forEach((key) => {
          const unordered = groupBy(data[key], (item) => item?.controlName);
          // eslint-disable-next-line no-param-reassign
          data[key] = Object.keys(unordered)
            .sort()
            .reduce((obj, innerkey) => {
              // eslint-disable-next-line no-param-reassign
              obj[innerkey] = unordered[innerkey];
              return obj;
            }, {});
        });
        setControls(data);
      })
      .catch((error) => {
        actions.errorAction(error);
      });
  }, []);

  function sortControlsByValueText(unsortedControls) {
    if (unsortedControls === undefined) 
      return undefined;
    const sortedValueByValueText = cloneDeep(unsortedControls);

    Object.keys(sortedValueByValueText).forEach(((key) => {
      sortedValueByValueText[key].sort((a, b) => {
        const valueTextA = a.valueText.toUpperCase();
        const valueTextB = b.valueText.toUpperCase();
        if (valueTextA < valueTextB) {
          return -1;
        }
        if (valueTextA < valueTextB) {
          return 1;
        }
        return 0;
      });
      return sortedValueByValueText;
    }));

    return sortedValueByValueText;
  }

  useEffect(() => {
    let defaultControlValue;
    if (controls && Object.keys(query?.chosenReports).length > 0) {
      defaultControlValue = Object.fromEntries(
        Object.entries(controls)
          .map(([key, value]) => {
            if (Object.keys(query?.chosenReports).includes(key)) {
              if (query?.riskOptionsSelection?.riskDynamicFields)
                if (Object.keys(query?.riskOptionsSelection?.riskDynamicFields).includes(key))
                  return [];
              const itemValues = Object.fromEntries(
                Object.entries(sortControlsByValueText(value)).map(([elementKey, elementValue]) => [
                  removeSpaces(elementKey),
                  {[elementValue[0].id]: elementValue[0]},
                ]),
              );
              return [key, itemValues];
            }
            return [];
          })
          .filter(([key]) => key !== undefined),
      );

        if (Object.keys(defaultControlValue).length > 0) {
          onChange({
            riskOptionsSelection: {
              ...query?.riskOptionsSelection,
              riskDynamicFields: {
                ...query?.riskOptionsSelection?.riskDynamicFields,
                ...defaultControlValue,
             }, 
            },
          });
      }
    }

  }, [query?.chosenReports, query?.riskOptionsSelection,controls]);

  useEffect(() => {
    validateErrorsInRiskControls()
  }, [query?.riskOptionsSelection?.riskDynamicFields]);

  const getValuesForControl = (options, controlName, reportId) => {
    const queryValue = query?.riskOptionsSelection?.riskDynamicFields?.[reportId]?.[controlName];
    
    const unsortedOptionsTextValues = options.map(option => option.valueText);
    const sortedOptionsTextValues = unsortedOptionsTextValues.sort();
    const firstIndex = options.findIndex(option => option.valueText === sortedOptionsTextValues[0]);
    const defaultValue = options[firstIndex];

    const defaultValueKeyName = defaultValue.id;
    const result = {[defaultValueKeyName]: defaultValue};
    
    if (queryValue) {
      return queryValue;
    }
    return result;
  };

  const handleOnChange = (riskControl, reportId) => {
    setChangeNotSaved(true);
    if (riskControl) {
      const riskControlName = Object.keys(riskControl)[0];
      const riskControlValues = Object.values(riskControl)[0];

      onChange({
        riskOptionsSelection: {
          ...query?.riskOptionsSelection,
          riskDynamicFields: {
             ...query?.riskOptionsSelection?.riskDynamicFields,
             [reportId]: {
              ...query?.riskOptionsSelection?.riskDynamicFields[reportId], 
                [riskControlName]: riskControlValues
             }
          },
        },
      }); 

    }

  };

  return (
    <Grid item container>
      <Grid item>
      <Alert severity="error" hidden={riskControlsErrorMessage.length === 0}>
        {riskControlsErrorMessage}
      </Alert>
    </Grid>


    <Grid item className="riskControlsWrapper" xs={12}>
      { isExecutiveSummaryReport && 
        <Grid className="riskControl" item xs={12}>
          <Typography className="riskControlLabel" variant="h4">
            Executive Summary Options
          </Typography>
          <Grid className="dynamicRiskControlWrapper">
            <Grid className="dynamicRiskControl" item xs={5}>
              <Typography variant="h4">Focus Lender</Typography>
              <FocusLenderManager
                query={query}
                onChange={onChange}
                focusLender={focusLender}
              />
            </Grid>
          </Grid>
        </Grid>
      }
      { controls &&
        Object.keys(controls).map((reportId, index) => {
          let selectedReportControls;

          if (selectedReports && Object.keys(selectedReports).includes(reportId)) {
            selectedReportControls = (
              <Grid key={`grid1-${reportId.toString()  }-${  index.toString()}`} className="riskControl" item xs={12}>
                <Typography key={`typo1-${reportId.toString()  }-${  index.toString()}`} className="riskControlLabel" variant="h4">
                  {selectedReports[reportId]} Options
                </Typography>
                <Grid key={`grid2-${reportId.toString()  }-${  index.toString()}`} className="dynamicRiskControlWrapper">
                  {Object.entries(controls[reportId]).map(([controlName, values]) => (
                    <Grid key={`grid3-${reportId.toString()  }-${  index.toString()}-${controlName}`} className="dynamicRiskControl" item xs={5}>
                      <Typography variant="h4">{controlName}</Typography>
                      <br />
                      <RiskControlMultiSelect
                        id={reportId.toString() + Object.keys(getValuesForControl(values, removeSpaces(controlName), reportId))[0]}
                        controlName={removeSpaces(controlName)}
                        availableRiskControls={values}
                        riskControlSelection={getValuesForControl(values, removeSpaces(controlName), reportId)}
                        resetState={resetState}
                        setResetState={setResetState}
                        handleRiskControlChange={(dataControlName) => {
                          handleOnChange(dataControlName, reportId);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            );
          }
          return selectedReportControls;
        })
      }
    </Grid>

    </Grid>
  );
}

export default ControlsByReport;
