import HelpSection from '../../views/help/HelpSection';

const LAYOUT = '/autocount/help-central/';

const routes = {
  helpMain: {
    name: 'HelpMain',
    component: HelpSection,
    path: `${LAYOUT}`
  }
};

export { routes, LAYOUT };
