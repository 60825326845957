import './Collapsable.scss';
import React, { useState, useEffect } from 'react';
import { AccordionSummary, AccordionDetails, Accordion } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function Collapsable({
  expanded,
  onExpand,
  testId,
  index,
  defaultExpanded,
  title,
  description,
  contentComponent,
  disabled,
  dataCy,
}) {
  const [loadContent, setLoadContent] = useState(false);
  useEffect(() => {
    setLoadContent(expanded);
  }, [expanded]);

  return (
    <div className="collapsable">
      <Accordion
        test-id={`accordion${testId}`}
        // eslint-disable-next-line react/jsx-no-bind
        onChange={onExpand.bind(null, index)}
        defaultExpanded={defaultExpanded}
        expanded={expanded}
        className={`${disabled ? 'collapsable__disabled' : ''}`}
      >
        <AccordionSummary
          className="collapsable__accordionSummary"
          expandIcon={defaultExpanded ? '' : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          test-id={`panel_header_${testId}`}
        >
          <div className="collapsable__accordionSummaryTitle">
            <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }} wrap="nowrap">
              <Grid item>
                <Typography variant="h4" marginBottom={0} data-cy={`${dataCy}Title-label`}>
                  {title}
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography
                  variant="h5"
                  className="collapsable__accordionDetailsTitle"
                  data-cy={`${dataCy}Description-label`}
                  noWrap
                >
                  {description}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </AccordionSummary>
        <AccordionDetails className="collapsable__accordionDetails">
          <div className="collapsable__accordionDetailsContent">
            {loadContent && contentComponent}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Collapsable;
