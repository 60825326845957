import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const USERS_QUERY_OPTIONS_RESOURCES = RESOURCES.usersQueriesOptions;

function getUsersQueriesOptionsActive() {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUsersQueriesOptionsActive}`;
  return fetchData(resource);
}

function getUsersQueriesOptionsActiveByQueryType(queryTypeId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getUsersQueriesOptionsActive}/${queryTypeId}`;
  return fetchData(resource);
}

function getSavedRiskOptions(queryTypeId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getSavedRiskOptions}/${queryTypeId}`;
  return fetchData(resource);
}

const getGroupByOptions = () => {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getRiskGroupByOptions}`;
  return fetchData(resource);
};

const getDisplayByOptions = () => {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getRiskDisplayByOptions}`;
  return fetchData(resource);
};

const getClientUsersListForSchedulingScreen = () => {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.getClientUsersListForSchedulingScreen}`;
  return fetchData(resource);
};

function addUserQueryOption(
  queryOptionTypeId,
  userQueryOptionReportsJSON,
  userQueryOptionNm,
  userQueryOptionJSON,
  activeIndicator,
  addRun,
  queryType = '',
  userQueryOptionReportingPeriodsJSON,
  userQueryOptionDesc,
) {
  let newQueryType = '';

  if (queryType === 'Risk') {
    newQueryType = 'riskReportSelection';
  } else if (queryType === 'Market') {
    newQueryType = 'marketReportSelection';
  }

  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.addUserQueryOption}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      queryOptionTypeId,
      userQueryOptionReportingPeriodsJSON,
      userQueryOptionReportsJSON,
      userQueryOptionNm,
      userQueryOptionJSON,
      activeIndicator,
      addRun,
      queryType: newQueryType,
      userQueryOptionDesc,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };

  return fetchData(resource, payload);
}

function updateUserQueryOption(
  queryOptionTypeId,
  userQueryOptionReportingPeriodsJSON,
  userQueryOptionReportsJSON,
  userQueryOptionNm,
  userQueryOptionJSON,
  activeIndicator,
  addRun,
  addReport,
  userQueryOptionDesc,
) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.updateUserQueryOption}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      queryOptionTypeId,
      userQueryOptionReportingPeriodsJSON,
      userQueryOptionReportsJSON,
      userQueryOptionNm,
      userQueryOptionJSON,
      activeIndicator,
      addRun,
      addReport,
      userQueryOptionDesc,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

function addUserQueryOptionRun(
  userQueryOptionId,
  reportId,
  userQueryOptionReportingPeriodsJSON,
  userQueryOptionNm,
  userQueryOptionJSON,
  userQueryOptionRunSourceTypeId,
  functionType,
) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.addUserQueryOptionRun}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      userQueryOptionId,
      userQueryOptionReportingPeriodsJSON,
      userQueryOptionJSON,
      userQueryOptionRunSourceTypeId,
      reportId,
      userQueryOptionNm,
      functionType,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };

  return fetchData(resource, payload);
}

function addUserQueryOptionRunByUserQueryOptionName(
  queryOptionTypeId,
  reportId,
  userQueryOptionReportingPeriodsJSON,
  userQueryOptionStatesJSON,
  userQueryOptionNm,
  userQueryOptionJSON,
  userQueryOptionRunSourceId,
) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.addUserQueryOptionRunByUserQueryOptionName}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      reportId,
      userQueryOptionReportingPeriodsJSON,
      userQueryOptionNm,
      userQueryOptionJSON,
      userQueryOptionRunSourceId,
      queryOptionTypeId,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

function disableUserQueryOption(userQueryOptionId, queryOptionTypeId) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.disableUserQueryOption}/${userQueryOptionId}/${queryOptionTypeId}`;
  return fetchData(resource);
}

function existsUserQueryOptionActiveWithOptionNameForUser(userQueryOptionId, userQueryOptionNm) {
  const resource = `${API_BASE_URL}${USERS_QUERY_OPTIONS_RESOURCES.existsUserQueryOptionActiveWithOptionNameForUser}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      userQueryOptionId,
      userQueryOptionNm,
    }),
    headers: { 'Content-type': 'application/json' },
  };

  return fetchData(resource, payload);
}

export {
  getSavedRiskOptions,
  getDisplayByOptions,
  getGroupByOptions,
  getUsersQueriesOptionsActive,
  getUsersQueriesOptionsActiveByQueryType,
  addUserQueryOption,
  addUserQueryOptionRun,
  addUserQueryOptionRunByUserQueryOptionName,
  updateUserQueryOption,
  disableUserQueryOption,
  existsUserQueryOptionActiveWithOptionNameForUser,
  getClientUsersListForSchedulingScreen,
};
