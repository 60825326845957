/* eslint-disable no-param-reassign */
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';

import { UsersScheduledQueriesFormatTypes } from '../../utils/constants/enums';

function getUserQueryReportsArrayObject(reportIds, reportNms) {
  const reportIdsArray = reportIds?.split(',');
  const reportNamesArray = reportNms?.split(',');
  if (reportIdsArray == null) {
    return [];
  }
  return reportIdsArray?.map((id, index) => ({
    reportId: id,
    reportNm: reportNamesArray[index],
  }));
}

function getKeyByValue(object, value) {
  const response = Object.keys(object).find((key) => object[key] === parseInt(value));
  return response;
}

const getReportSelectValue = (userQueryOptionId, reportSelectDictionary) =>
  reportSelectDictionary?.find((item) => item.key === userQueryOptionId)?.value;
const columns = () => [
  {
    title: 'Date Created',
    field: 'createTs',
    headerStyle: {
      id: 'files-DateCreated-label',
    },

    render: ({ createTs }) => (
      <span className="query-name" style={{ whiteSpace: 'pre' }}>
        {moment(createTs, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')}
      </span>
    ),
    defaultSort: 'desc',
  },
  {
    title: 'File Name',
    field: 'reportUiFileName',
    headerStyle: {
      id: 'files-FileName-label',
    },
    render: ({ reportUiFileName }) => (
      <span className="query-name" style={{ whiteSpace: 'pre' }}>
        {reportUiFileName}
      </span>
    ),
  },
  {
    title: 'Report Area',
    field: 'reportArea',
    headerStyle: {
      id: 'files-DateCreated-label',
    },
    lookup: {
      Market: 'Market',
      Risk: 'Risk',
    },
    render: ({ reportArea }) => <span className="query-name">{reportArea}</span>,
  },
  {
    title: 'Report Type',
    field: 'reportType',
    headerStyle: {
      id: 'files-ReportType-label',
    },
    render: ({ reportType }) => <span className="query-name">{reportType}</span>,
  },
  {
    title: 'Format',
    field: 'userScheduledQueryFormatTypeId',
    headerStyle: {
      id: 'files-Format-label',
    },
    filtering: false,
    render: ({ userScheduledQueryFormatTypeId }) => (
      <span className="query-name">
        {getKeyByValue(UsersScheduledQueriesFormatTypes, userScheduledQueryFormatTypeId)}
      </span>
    ),
  },
];

const actions = (downloadFile, setShowConfirmationModal, setCurrentRow) => [
  {
    icon: () => <DownloadIcon />,
    tooltip: 'Download',
    onClick: (event, rowData) =>
      downloadFile(rowData.userScheduledQueryReportFileId, rowData.reportUiFileName),
  },
  {
    icon: () => <DeleteIcon />,
    tooltip: 'Delete',
    onClick: (event, rowData) => {
      setCurrentRow(rowData);
      setShowConfirmationModal(true);
    },
  },
];

export { columns, getReportSelectValue, getUserQueryReportsArrayObject, actions };
