/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { logError } from '../../services/errorLogging.api';
import ErrorFallback from '../errorFallback/ErrorFallback';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    logError(`ERROR BOUNDARY UI: ${error}`);
    return { hasError: true, error };
  }

  render() {
    return this.state.hasError ? <ErrorFallback error={this.state.error} /> : this.props.children;
  }
}

export default ErrorBoundary;
