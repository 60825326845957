import React from 'react';
import Grid from '@mui/material/Grid';
import './LoginPageLayout.scss';
import CookieConsent from '../../components/cookieConsent/CookieConsent';

function LoginPageLayout({ children }) {
  return (
    <Grid container>
      <Grid className="loginPanel" item md={4} xs={12} container direction="column">
        {children}
      </Grid>
      <Grid className="content" item md={8} xs={12}>
        <img
          alt="landingPageImage"
          className="landingPageImage"
          src="./images/LandingPageImage.jpg"
        />
      </Grid>
      {process.env.REACT_APP_COOKIES_POLICY_URL && (
        <CookieConsent
          privacyPolicyURL={process.env.REACT_APP_COOKIES_POLICY_URL}
          cookieName="cookieConsent"
          message="Cookies help us deliver the best experience on our website. By using our webside, you agree the use of cookies. "
        />
      )}
    </Grid>
  );
}
export default LoginPageLayout;
