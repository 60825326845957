const AccountStatus = {
  Active: 1,
  Inactive: 2,
  InProgress: 3,
  Incomplete: 4,
};

const AccountStatusDescriptors = {
  Active: 'Active',
  Inactive: 'Inactive',
  InProgress: 'InProgress',
  Incomplete: 'Incomplete',
};

const BtnActions = {
  Add: 1,
  Edit: 2,
  Activate: 3,
  Deactivate: 4,
  Clone: 5,
  ViewHistorical: 6,
  ViewCurrent: 7,
  ViewContacts: 8,
  AddContacts: 9,
  EditContacts: 10,
  DeleteContacts: 11,
  ViewNotes: 12,
  EditNotes: 13,
  AddNotes: 14,
  RemoveNotes: 15,
  Remove: 16,
  Replace: 17,
  RemoveAll: 19,
  AddAll: 20,
  New: 21,
};

const AlertSeverity = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

const NotificationType = {
  Groups: 1,
  Users: 2,
  HomeScreenGeneralNotifications: 3,
};

const QueryDataType = {
  State: 1,
  Dma: 2,
  County: 3,
  Zip: 4,
  Lender: 5,
  Dealer: 6,
  Vehicle: 7,
  Transaction: 8,
  Body: 9,
  Makes: 10,
  Models: 11,
  Segments: 12,
  Years: 13,
  ReportType: 14,
  Report: 15,
  DownloadFormat: 16,
  ReportingPeriodSelectionType: 17,
  ReportingPeriod: 18,
};

const QuerySectionType = {
  Territory: 1,
  Indicator: 2,
  DealerLender: 3,
  Vehicle: 4,
  Risk: 5,
  Report: 6,
  Meta: 7, // delete and replace with Title
  Title: 8,
  QuickQuery: 9,
};

const UsedOrNew = {
  Used: 1,
  New: 2,
};

const BodyTypes = {
  Cars: 1,
  Trucks: 2,
  Van: 3,
  CuvSuvWagon: 4,
};

const SelectionType = {
  Single: 1,
  Multiple: 2,
};

const QueryType = {
  New: 1,
  Load: 2,
  Clone: 3,
};

const ReportingPeriodsOptionsType = {
  MostRecent: 1,
  TwoMonthsRolling2: 2,
  ThreeMonthsRolling3: 3,
  Last12MonthsRolling12: 4,
  Last13MonthsRolling13: 5,
  YearToDate: 6,
  OldestToMostRecent: 7,
  Custom: 8,
};

const QueryOptionTypes = {
  MarketAdvancedQuery: 1,
  RiskAdvancedQuery: 2,
  CriteriaRiskQueryOptions: 3,
};

const QueryActionTypes = {
  Add: 1,
  Update: 2,
  UpdateAdd: 3,
  UpdateReplace: 4,
  DeleteForever: 5,
};

const ReportTypes = {
  TableauMarketReports: 1,
  TableauRiskReportsNonLTV: 2,
  TableauRiskReportsLTV: 3,
};

const UserQueryOptionRunSourceTypes = {
  MarketAdvancedQuery: 1,
  RiskAdvancedQuery: 2,
  MyReports: 3,
};

const UsersScheduledQueriesFrequencyTypes = {
  Monthly: 1,
  CalendarQuarter: 2,
  CalendarYear: 3,
};

const UsersScheduledQueriesReportingPeriodTypes = {
  MostRecentMonth: 1,
  YearToDate: 2,
  MostRecentAndXMonthsBack: 3,
};

const UsersScheduledQueriesFormatTypes = {
  PDF : 1,
  EXCEL : 2,
  DELIMITED_TEXT : 3,
  PDF_AND_EXCEL : 4,
  PDF_AND_DELIMITED_TEXT : 5,
  EXCEL_AND_DELIMITED_TEXT : 6,
  PDF_AND_EXCEL_AND_DELIMITED_TEXT : 7
};

const UsersScheduledQueriesFormatSeparatorTypes = {
  Tab: 1,
  Pipe: 2,
  Comma: 3,
};

const UsersScheduledQueriesDeliveryTypes = {
  Email: 1,
  SFTPOrSTS: 2,
};

const UsersScheduledQueriesEmailsElementTypes = {
  User: 1,
  Email: 2,
};

const UsersScheduledQueriesSFTPOrSTSElementTypes = {
  Default: 1,
};
const ClientTypeId = {
  Internal: 1,
  External: 2,
};

const UsersScheduledQueriesCompressionTypes = {
  Zip: 1,
  Gzip: 2,
  None: 3,
  ZipInOneFile: 4,
};

const TerritoryTypes = {
  Owner: 1,
  Dealer: 2,
};

module.exports = {
  AccountStatus,
  AccountStatusDescriptors,
  BtnActions,
  AlertSeverity,
  NotificationType,
  QueryDataType,
  QuerySectionType,
  UsedOrNew,
  BodyTypes,
  SelectionType,
  QueryType,
  ReportingPeriodsOptionsType,
  QueryOptionTypes,
  QueryActionTypes,
  ReportTypes,
  UserQueryOptionRunSourceTypes,
  UsersScheduledQueriesFrequencyTypes,
  UsersScheduledQueriesReportingPeriodTypes,
  UsersScheduledQueriesFormatTypes,
  UsersScheduledQueriesFormatSeparatorTypes,
  UsersScheduledQueriesDeliveryTypes,
  UsersScheduledQueriesEmailsElementTypes,
  UsersScheduledQueriesSFTPOrSTSElementTypes,
  ClientTypeId,
  UsersScheduledQueriesCompressionTypes,
  TerritoryTypes,
};
