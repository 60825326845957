import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import './QueryInfo.scss';
import KeyValueDisplay from './keyValueDisplay/keyValueDisplay';

function QueryInfo({ query }) {
  const states = (query && query.territorySelection && query.territorySelection.states) || '';
  const counties = (query && query.territorySelection && query.territorySelection.counties) || '';
  const zips = (query && query.territorySelection && query.territorySelection.zips) || '';
  const dmas = (query && query.territorySelection && query.territorySelection.dmas) || '';
  const reportingPeriods = query && query.reportingPeriods;
  const chosenReports = query && query.chosenReports;
  const indicatorSelection = (query && query?.indicatorSelection) || {};
  const vehiclesSelection = (query && query?.vehiclesSelection) || {};
  const dealersAndLendersSelection = (query && query?.dealersAndLendersSelection) || {};
  const isRiskReport =
    query?.chosenReports && Object.values(query?.chosenReports)[0].reportType.reportTypeId === 2;
  const riskOptionsSelection = query?.riskOptionsSelection || {};
  const displayBy = isRiskReport
    ? (query?.riskLayoutCriteriaSelection?.riskDisplayBy &&
        Object.values(query?.riskLayoutCriteriaSelection?.riskDisplayBy)[0]?.displayByDesc) ||
      ''
    : (query?.marketLayoutCriteriaSelection?.marketDisplayBy &&
        Object.values(query?.marketLayoutCriteriaSelection?.marketDisplayBy)[0]?.displayByDesc) ||
      '';
  const groupBy = isRiskReport
    ? (query?.riskLayoutCriteriaSelection?.riskGroupBy &&
        Object.values(query?.riskLayoutCriteriaSelection?.riskGroupBy)[0]?.groupByDesc) ||
      ''
    : query?.marketLayoutCriteriaSelection?.marketGroupBy &&
      Object.values(query?.marketLayoutCriteriaSelection?.marketGroupBy)[0]?.groupByDesc;

  const getValues = (object, property) => {
    const values = object && Object.values(object);
    const array = [];
    if (values.length === 1) return values[0][property];

    values.forEach((content) => array.push(content[property]));
    return array.join(', ');
  };

  return (
    <div className="queryInfo">
      <Typography variant="h5" gutterBottom>
        Query Details
      </Typography>
      <div className="accordionContainer">
        {/* Report Selection */}
        <Accordion className="accordion" defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Report Selection
          </AccordionSummary>
          <AccordionDetails>
            <KeyValueDisplay
              title="Report Type"
              value={
                chosenReports != null ? chosenReports[Object.keys(chosenReports)]?.reportNm : ''
              }
            />
            <KeyValueDisplay
              title="Report Period"
              value={
                reportingPeriods != null
                  ? `${moment(reportingPeriods?.beginDateRange?.periodDate).format(
                      'MMM YYYY',
                    )} - ${moment(reportingPeriods?.endDateRange?.periodDate).format('MMM YYYY')}`
                  : ''
              }
            />
            <KeyValueDisplay
              title="State"
              value={isEmpty(states) ? 'All States' : getValues(states, 'stateDescription')}
            />

            {zips && <KeyValueDisplay title="Zip" value={getValues(zips, 'zipCd')} />}
            {dmas && <KeyValueDisplay title="Dma" value={getValues(dmas, 'acnDmaNm')} />}
            {counties && <KeyValueDisplay title="County" value={getValues(counties, 'countyNm')} />}
          </AccordionDetails>
        </Accordion>
        {/* Purchase Profile */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Purchase Profile
          </AccordionSummary>
          <AccordionDetails>
            {!Object.keys(indicatorSelection).length && <p>No chosen criteria</p>}
            {indicatorSelection?.vehicleNewUsedTypes &&
              Object.keys(indicatorSelection.vehicleNewUsedTypes).length && (
                <KeyValueDisplay
                  title="New Used"
                  value={getValues(
                    indicatorSelection?.vehicleNewUsedTypes,
                    'vehicleNewUsedTypeDescription',
                  )}
                />
              )}
            {indicatorSelection?.transactionTypes &&
              Object.keys(indicatorSelection.transactionTypes).length && (
                <KeyValueDisplay
                  title="Finance Type"
                  value={getValues(
                    indicatorSelection?.transactionTypes,
                    'transactionTypeDescription',
                  )}
                />
              )}
            {indicatorSelection?.lenderTypes &&
              Object.keys(indicatorSelection.lenderTypes).length && (
                <KeyValueDisplay
                  title="Lender Type"
                  value={getValues(indicatorSelection?.lenderTypes, 'lenderTypeDescription')}
                />
              )}
            {indicatorSelection?.dealerTypes &&
              Object.keys(indicatorSelection.dealerTypes).length && (
                <KeyValueDisplay
                  title="Dealer Type"
                  value={getValues(indicatorSelection?.dealerTypes, 'dealerTypeDescription')}
                />
              )}
          </AccordionDetails>
        </Accordion>
        {/* Vehicles */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Vehicles
          </AccordionSummary>
          <AccordionDetails>
            {!Object.keys(vehiclesSelection).length && <p>No chosen criteria</p>}
            {vehiclesSelection?.types && Object.keys(vehiclesSelection.types).length && (
              <KeyValueDisplay
                title="Type"
                value={getValues(vehiclesSelection?.types, 'vehicleTypeDescription')}
              />
            )}
            {vehiclesSelection?.subTypes && Object.keys(vehiclesSelection.subTypes).length && (
              <KeyValueDisplay
                title="Subtype"
                value={getValues(vehiclesSelection?.subTypes, 'vehicleSubTypeTx')}
              />
            )}
            {vehiclesSelection?.classes && Object.keys(vehiclesSelection.classes).length && (
              <KeyValueDisplay
                title="Class"
                value={getValues(vehiclesSelection?.classes, 'vehicleClassTx')}
              />
            )}
            {vehiclesSelection?.classSizes && Object.keys(vehiclesSelection.classSizes).length && (
              <KeyValueDisplay
                title="Class Size"
                value={getValues(vehiclesSelection?.classSizes, 'vehicleClassSizeTx')}
              />
            )}
            {vehiclesSelection?.manufacturers &&
              Object.keys(vehiclesSelection.manufacturers).length && (
                <KeyValueDisplay
                  title="Manufacturer"
                  value={getValues(vehiclesSelection?.manufacturers, 'manufacturerTx')}
                />
              )}
            {vehiclesSelection?.makes && Object.keys(vehiclesSelection.makes).length && (
              <KeyValueDisplay title="Make" value={getValues(vehiclesSelection?.makes, 'makeTx')} />
            )}
            {vehiclesSelection?.models && Object.keys(vehiclesSelection.models).length && (
              <KeyValueDisplay
                title="Model"
                value={getValues(vehiclesSelection?.models, 'modelTx')}
              />
            )}
            {vehiclesSelection?.beginModelYear &&
              vehiclesSelection?.endModelYear &&
              Object.keys(vehiclesSelection.beginModelYear).length &&
              Object.keys(vehiclesSelection.endModelYear).length && (
                <KeyValueDisplay
                  title="Model Year"
                  value={`
                    ${getValues(vehiclesSelection?.beginModelYear, 'modelYear')}
                    - ${getValues(vehiclesSelection?.endModelYear, 'modelYear')}
                    `}
                />
              )}
            {vehiclesSelection?.fuels && Object.keys(vehiclesSelection.fuels).length && (
              <KeyValueDisplay
                title="Fuel"
                value={getValues(vehiclesSelection?.fuels, 'vehicleFuelTypeTx')}
              />
            )}
          </AccordionDetails>
        </Accordion>
        {/* Dealers and Lenders */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Dealers and Lenders
          </AccordionSummary>
          <AccordionDetails>
            {!Object.keys(dealersAndLendersSelection).length && <p>No chosen criteria</p>}
            {dealersAndLendersSelection?.dealers &&
              Object.keys(dealersAndLendersSelection.dealers).length && (
                <KeyValueDisplay
                  title="Dealer"
                  value={getValues(dealersAndLendersSelection?.dealers, 'dealerNm')}
                />
              )}
            {dealersAndLendersSelection?.lenders &&
              Object.keys(dealersAndLendersSelection.lenders).length && (
                <KeyValueDisplay
                  title="Lender"
                  value={getValues(dealersAndLendersSelection?.lenders, 'lenderNm')}
                />
              )}
          </AccordionDetails>
        </Accordion>
        {/* Risk Options */}
        {isRiskReport && (
          <Accordion className="accordion">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              Risk Options
            </AccordionSummary>
            <AccordionDetails>
              {!Object.keys(riskOptionsSelection).length && <p>No chosen criteria</p>}
              {riskOptionsSelection?.riskDynamicFields &&
                riskOptionsSelection?.riskDynamicFields?.Distribution &&
                Object.keys(riskOptionsSelection.riskDynamicFields.Distribution).length && (
                  <KeyValueDisplay
                    title="Distribution"
                    value={getValues(
                      riskOptionsSelection.riskDynamicFields.Distribution,
                      'valueText',
                    )}
                  />
                )}
              {riskOptionsSelection?.riskDynamicFields &&
                riskOptionsSelection?.riskDynamicFields?.AttributeForAverage &&
                Object.keys(riskOptionsSelection.riskDynamicFields.AttributeForAverage).length && (
                  <KeyValueDisplay
                    title="Attribute for Average"
                    value={getValues(
                      riskOptionsSelection.riskDynamicFields.AttributeForAverage,
                      'valueText',
                    )}
                  />
                )}
              {riskOptionsSelection?.riskDynamicFields &&
                riskOptionsSelection?.riskDynamicFields?.ShareCalculation &&
                Object.keys(riskOptionsSelection.riskDynamicFields.ShareCalculation).length && (
                  <KeyValueDisplay
                    title="Share Calculation"
                    value={getValues(
                      riskOptionsSelection.riskDynamicFields.ShareCalculation,
                      'valueText',
                    )}
                  />
                )}
              {riskOptionsSelection?.editableRiskOption &&
                riskOptionsSelection?.editableRiskOption?.scoreType &&
                riskOptionsSelection.editableRiskOption.scoreType
                  ?.riskScoreTypeParameterValueNm && (
                  <KeyValueDisplay
                    title="Score Type"
                    value={
                      riskOptionsSelection.editableRiskOption.scoreType
                        .riskScoreTypeParameterValueNm
                    }
                  />
                )}
              {riskOptionsSelection?.editableRiskOption &&
                riskOptionsSelection?.editableRiskOption?.scoreType &&
                riskOptionsSelection.editableRiskOption.scoreType?.riskScoreTypeMinValue && (
                  <KeyValueDisplay
                    title="Score Min"
                    value={riskOptionsSelection.editableRiskOption.scoreType.riskScoreTypeMinValue}
                  />
                )}
              {riskOptionsSelection?.editableRiskOption &&
                riskOptionsSelection?.editableRiskOption?.scoreType &&
                riskOptionsSelection.editableRiskOption.scoreType?.riskScoreTypeMaxValue && (
                  <KeyValueDisplay
                    title="Score Max"
                    value={riskOptionsSelection.editableRiskOption.scoreType.riskScoreTypeMaxValue}
                  />
                )}
              {riskOptionsSelection?.focusLender &&
                Object.keys(riskOptionsSelection.focusLender).length && (
                  <KeyValueDisplay
                    title="Score Distributions"
                    value={getValues(riskOptionsSelection.focusLender, 'lenderNm')}
                  />
                )}
            </AccordionDetails>
          </Accordion>
        )}
        {/* Layout */}
        <Accordion className="accordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Layout
          </AccordionSummary>
          <AccordionDetails>
            {!displayBy && !groupBy && <p>No chosen criteria</p>}
            {/* TO DO */}
            {displayBy && <KeyValueDisplay title="Display By" value={displayBy} />}
            {groupBy && <KeyValueDisplay title="Group By" value={groupBy} />}
            {/* END TO DO */}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

export default QueryInfo;
