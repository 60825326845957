import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { Alert } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import TextField from '../../../textField/TextField';
import './TitleSelection.scss';
import ReportingPeriodManager from './reportingperiod/ReportingPeriodManager';
import ReportType from '../../../reportTypeSelector/ReportType';
import MaterialMultiSelect from '../../../materialMultiSelect/MaterialMultiSelect';
import FormField from '../../../formField/FormField';
import { TerritoryTypes } from '../../../../utils/constants/enums';
import TerritorySelection from '../territorySelection/TerritorySelection';

function TitleSelection({
  reportTypeId,
  onChange,
  query,
  territorySelection,
  resetState,
  setResetState,
  endDate,
  setEndDate,
  availableTerritoryTypes,
  dataBundle,
  singleStateReportErrorMessage,
}) {
  const [territoryTypeId, setTerritoryTypeId] = React.useState(1);

  useEffect(() => {
    if (resetState) {
      setTerritoryTypeId(TerritoryTypes.Owner);
      const newQuery = query;
      newQuery.territorySelection.territoryTypes = { 1: 1 };
      onChange({
        ...newQuery,
      });
    } else if (
      query?.territorySelection?.territoryTypes !== undefined &&
      TerritoryTypes.Dealer in query.territorySelection.territoryTypes
    ) {
      setTerritoryTypeId(TerritoryTypes.Dealer);
    }
  }, [resetState]);

  const handleOnChange = (data) => {
    // eslint-disable-next-line no-param-reassign

    onChange({
      ...query,
      ...data,
    });
  };

  const handleTitleOnChange = (data) => {
    onChange({
      ...query,
      queryName: data.trimStart().replace(/\s\s+/g, ' '),
    });
  };

  const handleDescriptionOnChange = (data) => {
    onChange({
      ...query,
      queryDescription: data,
    });
  };

  const handleStateChange = (data, queryDataType) => {
    const territoryTypesInfo = territorySelection?.territoryTypes;
    const { ...auxTerritorySelection } = query.territorySelection;
    if (!data) delete auxTerritorySelection[queryDataType];
    onChange({
      territorySelection: {
        territoryTypes: territoryTypesInfo,
        ...data,
      },
    });
    // props.setStateIsChosen(true);
  };

  const handleTerritoryTypeChange = (event) => {
    const newQuery = query;
    const territoryTypeIdTemp = parseInt(event.target.value);
    setTerritoryTypeId(territoryTypeIdTemp);

    const territoryTypes = {
      [territoryTypeIdTemp]: territoryTypeIdTemp,
    };

    if (
      query?.territorySelection?.territoryTypes === undefined ||
      query?.territorySelection?.territoryTypes[territoryTypeIdTemp] === undefined
    ) {
      newQuery.territorySelection.territoryTypes = territoryTypes;
      onChange({
        ...newQuery,
      });
    }
  };

  return (
    <Grid container spacing={3} padding="10px 30px">
      <Grid item xs={4}>
        <Grid item xs={12} marginBottom={2}>
          <FormField
            fieldTitle="Report Name"
            dataCy="aq-ReportName"
            fieldInput={
              <TextField
                id="advanceQuery__reportNameId"
                testId="ReportName"
                label="Title"
                value={query.queryName}
                onChange={(data) => {
                  handleTitleOnChange(data);
                }}
                dataCy="aq-ReportName-textbox"
              />
            }
          />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <FormField
            fieldTitle="Report Description"
            dataCy="aq-ReportDescription"
            fieldInput={
              <TextField
                id="advanceQuery__reportDescriptionId"
                testId="ReportDescription"
                label="Description"
                value={query.queryDescription}
                onChange={(data) => {
                  handleDescriptionOnChange(data);
                }}
                dataCy="aq-ReportDescription-textbox"
              />
            }
          />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          <ReportType
            id="advanceQuery__reportTypeId"
            availableReports={dataBundle?.availableReports}
            reportSelection={query}
            resetState={resetState}
            setResetState={setResetState}
            handleReportChange={handleOnChange}
          />
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid item xs={12} marginBottom={2}>
          <ReportingPeriodManager
            onChange={handleOnChange}
            availableReportingPeriods={dataBundle?.availableReportingPeriods}
            allAvailableTimeFrames={dataBundle?.availableReportingPeriodsOptions}
            reportSelection={query.reportingPeriods}
            reportTypeId={reportTypeId}
            endDate={endDate}
            setEndDate={setEndDate}
            resetState={resetState}
          />
        </Grid>
      </Grid>

      <Grid item xs={1}>
        <Grid item xs={12}>
          <FormField
            fieldTitle=""
            fieldInput={
              <FormControl component="fieldset">
                <FormLabel component="legend" />

                <RadioGroup
                  className="stateRadioButtons"
                  id="advanceQuery__selectStateType"
                  test-id="advanceQuery__selectStateType"
                  column={+true}
                  aria-label="State Type Radio Group"
                  name="stateTypeRadioGroupValue"
                  value={territoryTypeId}
                  onChange={(event) => handleTerritoryTypeChange(event)}
                >
                  {availableTerritoryTypes
                    ?.sort((a, b) => {
                      const fa = a.territoryTypeNm.toLowerCase();
                      const fb = b.territoryTypeNm.toLowerCase();

                      if (fa < fb) {
                        return -1;
                      }
                      if (fa > fb) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((row) => (
                      <FormControlLabel
                        id={row?.territoryTypeId}
                        key={row?.territoryTypeId}
                        control={<Radio value={row?.territoryTypeId} />}
                        label={`${row?.territoryTypeNm} State`}
                        classes={{ root: 'TitleSelection__CapitilizeOptions' }}
                        data-cy={`aq-States${row?.territoryTypeNm}-container`}
                      />
                    ))}
                </RadioGroup>
              </FormControl>
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={3} marginTop={3}>
        <Grid item xs={12} marginBottom={1}>
          <FormField
            fieldTitle=""
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectStateId"
                testId="States"
                options={dataBundle?.availableStates}
                label="States"
                objectRenderField="stateDescription"
                onChange={handleStateChange}
                queryDataType="states"
                selectAttribute="stateId"
                value={territorySelection?.states}
                resetState={resetState}
                setResetState={setResetState}
                verifyAllCheckbox
                dataCy="aq-States"
              />
            }
          />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          {query.territorySelection?.states &&
            Object.keys(query.territorySelection.states).length === 1 && (
              <TerritorySelection
                selectedState={query.territorySelection?.states}
                territorySelection={territorySelection}
                onChange={onChange}
              />
            )}
        </Grid>
      </Grid>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <Alert severity="error" hidden={singleStateReportErrorMessage.length === 0}>
            {singleStateReportErrorMessage}
          </Alert>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default TitleSelection;
