import React from 'react';
import './Footer.scss';
import Typography from '@mui/material/Typography';

function Footer() {
  const releaseVersion = process.env.REACT_APP_RELEASE_VERSION || '0.0.0';
  return (
    <div className="footer_wrapper">
      <div className="footer__gradient">
        <div className="footer__allRightsReserved">
          <div className="footer__allRightsReservedJustify">
            <h6> &copy; {new Date().getFullYear()} Experian. All rights reserved.</h6>
            <h6 className="footer__allRightsReservedSpace">
              {' '}
              Experian and the Experian trademarks used herein are trademarks or registered
              trademarks of Experian and its affiliates. The use of any other trade name, copyright,
              or trademark is for identification and reference purposes only.
            </h6>
            <h6>
              And does not imply any association with the copyright or trademark holder of their
              product or brand. Other product and company names mentioned herein are the property of
              their respective owners. Licenses and Disclosures.
            </h6>
            <br />
            <h6>DMA® is a registered service mark of The Nielsen Company. Used under license.</h6>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Version '} {releaseVersion}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
