import React from 'react';
import Button from '@mui/material/Button';
import ClassicModal from '../../components/common/ClassicModal';
import { useStyles } from '../../theme/VelocityHubMaterial';

function DeleteSavedReportFileModal({ show, onHideModal, title, onConfirmAction, text }) {
  const { classes } = useStyles();

  const deleteSavedQueryReportFileBody = <div className={classes.root}>{text}</div>;

  const deleteSavedQueryReportFileModalFooter = (
    <div className={classes.root}>
      <Button
        data-cy="files-ConfirmDelete-button"
        variant="contained"
        color="primary"
        onClick={onConfirmAction}
      >
        Confirm
      </Button>
      <Button data-cy="files-CancelDelete-button" variant="contained" onClick={onHideModal}>
        Cancel
      </Button>
    </div>
  );

  return (
    <div>
      <ClassicModal
        show={show}
        className={classes.root}
        onHide={onHideModal}
        title={title}
        body={deleteSavedQueryReportFileBody}
        footer={deleteSavedQueryReportFileModalFooter}
      />
    </div>
  );
}

export default DeleteSavedReportFileModal;
