import React from 'react';
import HomePageStatesAvailabilities from './components/stateAvailability/HomePageStatesAvailabilities';
import HomePageNotifications from './components/notifications/HomePageNotifications';
import './HomePage.scss';

function HomePage() {
  return (
    <div className="tabs_manager_wrapper">
      <div className="state_availabilities_container">
        <HomePageStatesAvailabilities />
      </div>
      <div className="notifications_Container">
      <HomePageNotifications />
      </div>
    </div>
  );
}

export default HomePage;
