import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';
import {  fetchFileData } from '../utils/fetchData';

const DOCUMENTS_RESOURCES = RESOURCES.documents;

const getFile = (filename) => {
    const resource = `${API_BASE_URL}${DOCUMENTS_RESOURCES.getFile}/${filename}`;
    return fetchFileData(resource);
  }

export {
  getFile
};
