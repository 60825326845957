import { Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowForwardOutlined from '@mui/icons-material/ArrowForwardOutlined';
import React, { useEffect, useState } from 'react';
import Select from '../select/Select';

function RangeSelector({
  testId,
  options,
  handleChange,
  beginModelYear,
  endModelYear,
  selectAttribute,
  queryDataType,
  displayAttribute,
  setBeginModelYear,
  setEndModelYear,
  resetState,
  setResetState,
  onChange,
  vehiclesSelection,
  dataCy,
}) {
  const [checkState, setCheckState] = useState(true);

  const handleCheckboxChange = () => {
    onChange({ vehiclesSelection: { ...vehiclesSelection, beginModelYear: {}, endModelYear: {} } });
    setBeginModelYear({});
    setEndModelYear({});
    setCheckState(!checkState);
  };

  useEffect(() => {
    if (Object.keys(beginModelYear).length > 0 || Object.keys(endModelYear).length > 0) {
      setCheckState(false);
    }
  }, [beginModelYear, endModelYear]);

  useEffect(() => {
    if (resetState === true) {
      setBeginModelYear({});
      setEndModelYear({});
      setCheckState(true);
      setResetState(false);
    }
  }, [resetState]);
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <Select
          id={`Start${testId}`}
          disabled={checkState}
          options={options}
          label={checkState ? 'All' : 'Start'}
          displayAttribute={displayAttribute}
          onChange={(a, b, value) => handleChange('start', value)}
          queryDataType={queryDataType}
          selectAttribute={selectAttribute}
          value={beginModelYear}
          hasAllOption={false}
          dataCy={`${dataCy}Start`}
        />
      </Grid>
      <Grid item xs={1}>
        <ArrowForwardOutlined className="modelYearIcon" />
      </Grid>
      <Grid item xs={4.5}>
        <Select
          id={`End${testId}`}
          disabled={checkState}
          options={options}
          label={checkState ? 'All' : 'End'}
          displayAttribute={displayAttribute}
          onChange={(a, b, value) => handleChange('end', value)}
          queryDataType={queryDataType}
          selectAttribute={selectAttribute}
          value={endModelYear}
          errorText={
            parseInt(beginModelYear?.element?.[displayAttribute]) >
            parseInt(endModelYear?.element?.[displayAttribute])
              ? 'Start should be earlier than end'
              : null
          }
          hasAllOption={false}
          dataCy={`${dataCy}End`}
        />
      </Grid>
      <Grid item xs={1}>
        <FormControlLabel
          control={
            <Checkbox
              id={`multiSelectAllCheckBox__rangeSelector-label${testId}`}
              checked={checkState}
              onChange={handleCheckboxChange}
              dataCy={`${dataCy}Checkbox`}
            />
          }
          label="All"
          labelPlacement="bottom"
        />
      </Grid>
    </Grid>
  );
}

export default RangeSelector;
