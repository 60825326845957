import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

function SaveConfirmationDialog({
  confirmationDialogOpen,
  setConfirmationDialogOpen,
  handleSaveOrUpdate,
  QueryActionTypes,
}) {
  function handleConfirmationDialogConfirmation() {
    handleSaveOrUpdate(QueryActionTypes.Update);
    setConfirmationDialogOpen(false);
  }
  function handleConfirmationDialogClose() {
    setConfirmationDialogOpen(false);
  }

  return (
    <Dialog
      open={confirmationDialogOpen}
      onClose={() => handleConfirmationDialogClose()}
      aria-labelledby="alert-dialog-confirm"
      aria-describedby="alert-dialog-confirm-with-user"
    >
      <DialogTitle id="alert-dialog-title">
        Saved query or criteria option with this name found
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          There is already a saved query or criteria option with this name do you want to overwrite
          it? Previous saved information will be overwritten.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          test-id="cancelButtonSavedQueryDialog"
          onClick={() => handleConfirmationDialogClose()}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          test-id="okButtonSavedQueryDialog"
          onClick={() => handleConfirmationDialogConfirmation()}
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SaveConfirmationDialog;
