import React, { forwardRef } from 'react';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

export const TableIcons = {
  DetailPanel: forwardRef(
    (props, ref) => (
      <CalendarTodayIcon
        {...props}
        ref={ref}
        data-cy='savedQuery-table-icon-schedule'
      />
    )
  )
};
