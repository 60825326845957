import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const NOTIFICATIONS_RESOURCES = RESOURCES.notifications;

function getDropDownSource() {
  const resource = `${API_BASE_URL}${NOTIFICATIONS_RESOURCES.dropDownSource}`;
  return fetchData(resource);
}

function makeNotificationStale(notificationId) {
  const resource = `${API_BASE_URL}${NOTIFICATIONS_RESOURCES.makeNotificationStale}/${notificationId}`;
  return fetchData(resource);
}

function getNotificationsByUser() {
  const resource = `${API_BASE_URL}${NOTIFICATIONS_RESOURCES.notificationsByUser}`;
  return fetchData(resource);
}

export { getDropDownSource, makeNotificationStale, getNotificationsByUser };
