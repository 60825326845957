import './Indicator.scss';
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import getAvailableIndicators from '../../../../services/indicators.api';
import { removeEmptyValues } from '../../../../utils/objectUtils';
import MaterialMultiSelect from '../../../materialMultiSelect/MaterialMultiSelect';
import { StoreContext } from '../../../../store/storeContext';
import FormField from '../../../formField/FormField';

function Indicator({ indicatorSelection, onChange, resetState, setResetState }) {
  const [availableIndicators, setAvailableIndicators] = useState();
  const { actions } = useContext(StoreContext);

  useEffect(() => {
    getAvailableIndicators()
      .then((data) => setAvailableIndicators(data))
      .catch((error) => {
        actions.errorAction(error);
      });
  }, []);

  const handleInnerChange = (data) => {
    const { ...auxIndicatorSelection } = indicatorSelection;
    onChange({
      indicatorSelection: {
        ...removeEmptyValues({ ...auxIndicatorSelection, ...data }),
      },
    });
  };

  return (
    <Paper elevation={0} className="indicator__paper">
      <Grid className="indicatorMultiSelect" container spacing={3}>
        <Grid item xs={3}>
          <FormField
            fieldTitle="New Used"
            dataCy="aq-NewUsed"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectNewUsedId"
                testId="NewUsed"
                options={availableIndicators?.vehicleNewUsedTypes}
                label="New Used"
                objectRenderField="vehicleNewUsedTypeDescription"
                onChange={handleInnerChange}
                queryDataType="vehicleNewUsedTypes"
                selectAttribute="vehicleNewUsedTypeId"
                value={indicatorSelection?.vehicleNewUsedTypes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-NewUsed"
              />
            }
          />
        </Grid>
        <Grid xs={3} item>
          <FormField
            fieldTitle="Finance Type"
            dataCy="aq-FinanceType"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectFinanceTypeId"
                testId="FinanceType"
                options={availableIndicators?.transactionTypes}
                label="Finance Type"
                objectRenderField="transactionTypeDescription"
                onChange={handleInnerChange}
                queryDataType="transactionTypes"
                selectAttribute="transactionTypeId"
                value={indicatorSelection?.transactionTypes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-FinanceType"
              />
            }
          />
        </Grid>
        <Grid xs={3} item>
          <FormField
            fieldTitle="Lender Type"
            dataCy="aq-LenderType"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectLenderTypeId"
                testId="LenderType"
                options={availableIndicators?.lenderTypes}
                label="Lender Type"
                objectRenderField="lenderTypeDescription"
                onChange={handleInnerChange}
                queryDataType="lenderTypes"
                selectAttribute="lenderTypeId"
                value={indicatorSelection?.lenderTypes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-LenderType"
              />
            }
          />
        </Grid>
        <Grid xs={3} item>
          <FormField
            fieldTitle="Dealer Type"
            dataCy="aq-DealerType"
            fieldInput={
              <MaterialMultiSelect
                id="advanceQuery__selectDealerTypeId"
                testId="DealerType"
                options={availableIndicators?.dealerTypes}
                label="Dealer Type"
                objectRenderField="dealerTypeDescription"
                onChange={handleInnerChange}
                queryDataType="dealerTypes"
                selectAttribute="dealerTypeId"
                value={indicatorSelection?.dealerTypes}
                resetState={resetState}
                setResetState={setResetState}
                dataCy="aq-DealerType"
              />
            }
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default Indicator;
