import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './CookieConsent.scss';
import { Slide } from '@mui/material';

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function CookieConsent({
  cookieName,
  debug,
  cookieValue,
  privacyPolicyURL,
  expires,
  onAccept,
  extraCookieOptions,
  message,
  snackbarAnchor,
  acceptButtonLabel,
  actions,
}) {
  const [visible, setVisible] = useState(false);

  /**
   * Set a persistent cookie
   */
  const handleAccept = () => {
    if (onAccept) {
      onAccept();
    }
    Cookies.set(cookieName, cookieValue, { expires, ...extraCookieOptions });

    setVisible(false);
  };

  useEffect(() => {
    if (Cookies.get(cookieName) === undefined || debug) {
      setVisible(true);
    }
  });

  return (
    <Snackbar
      className="overlay"
      ContentProps={{ className: 'snackBar' }}
      anchorOrigin={snackbarAnchor}
      TransitionComponent={SlideTransition}
      open={visible}
      message={
        <>
          <span id="message-id">{message}</span>
          <Link className="link" to={{ pathname: privacyPolicyURL }} target="_blank">
            Find out how we use cookies.
          </Link>
        </>
      }
      action={[
        ...React.Children.toArray(actions),
        <Button
          className="acceptButton"
          key="accept"
          color="primary"
          size="small"
          onClick={handleAccept}
        >
          {acceptButtonLabel}
        </Button>,
      ]}
    />
  );
}

CookieConsent.defaultProps = {
  privacyPolicyURL: '',
  cookieValue: true,
  expires: 365,
  snackbarAnchor: { horizontal: 'center', vertical: 'bottom' },
  message: '',
  acceptButtonLabel: 'Accept',
};

export default CookieConsent;
