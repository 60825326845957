/* eslint-disable react/destructuring-assignment */
import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import './helpMarquee.scss';

function MarqueeImage(props) {
  return (
    <div className="marquee__mainFlex">
      <Card className="marquee__cardContainer">
        <CardActionArea disabled>
          <CardMedia
            className="marquee__cardMediaContainer"
            image={props.image}
            title={props.titleName}
          >
            <div className="marquee__align">
              <h1 className="marquee__title marquee__align">{props.titleName}</h1>
            </div>
          </CardMedia>
        </CardActionArea>
      </Card>
    </div>
  );
}

export default MarqueeImage;
