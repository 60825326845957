import React from 'react';
import Paper from '@mui/material/Paper';
import { Typography } from '@mui/material';
import './keyValueDisplay.scss';
import ValueDisplay from './valueDisplay';

function KeyValueDisplay({ title, value }) {
  return (
    <div className="infoContainer">
      <div className='titleContainer'>
        <Typography variant="h4" className="title">
          {title}
        </Typography>
      </div>
      <Paper component="span" elevation={9} className="valueContainer">
        <ValueDisplay text={value} />
      </Paper>
    </div>
  );
}

export default KeyValueDisplay;
