import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './theme/VelocityHub.scss';
import './theme/App.scss';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { TableauQueryProvider } from './contexts/TableauQuery';
import ErrorBoundary from './components/errorBoundary/ErrorBoundary';
import { StoreProvider } from './store/storeContext';
import { ToastProvider } from './contexts/Toast';
import { TabsProvider } from './contexts/Tabs';
import { AuthenticationProvider } from './contexts/Authentication';
import mainTheme from './theme/VelocityHubMaterialThemes';
import { OKTA_CONFIGURATION } from './utils/constants/constants';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const oktaAuth = new OktaAuth(OKTA_CONFIGURATION);

const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  window.location.replace(toRelativeUrl(originalUri, window.location.origin));
};

ReactDOM.render(
  <ErrorBoundary>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ToastProvider>
            <TabsProvider>
              <Router>
                <Security
                  oktaAuth={oktaAuth}
                  onAuthRequired={() => {
                    window.location.replace(`${window.location.origin}/login`);
                  }}
                  restoreOriginalUri={restoreOriginalUri}
                >
                  <TableauQueryProvider>
                    <AuthenticationProvider>
                      <StoreProvider>
                        <App />
                      </StoreProvider>
                    </AuthenticationProvider>
                  </TableauQueryProvider>
                </Security>
              </Router>
            </TabsProvider>
          </ToastProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
