import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const DATA_ACCESS_RESOURCES = RESOURCES.dataAccess;

function getDataAccessClientsReportTypesActive() {
  const resource = `${API_BASE_URL}${DATA_ACCESS_RESOURCES.getDataAccessClientsReportTypesActive}`;
  return fetchData(resource);
}

function getDataAccessClientTypeId() {
  const resource = `${API_BASE_URL}${DATA_ACCESS_RESOURCES.getDataAccessClientTypeId}`;
  return fetchData(resource);
}

export { getDataAccessClientsReportTypesActive, getDataAccessClientTypeId };
