import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';

function MyOwnTiers({
  customTiers,
  setChangeNotSaved,
  validateAll,
  minTierValue,
  maxTierValue,
  customRiskScoreTypeMaxValue,
  customRiskScoreTypeMinValue,
  setDistributionBandTypeValue,
  setHideCustomTiersSection,
  validationErrors,
}) {
  const [customTiersWarningMessage, setCustomTiersWarningMessage] = useState('');

  function handleCustomTierRowChange(id, field, newValue) {
    const newCustomTiers = customTiers.map((x) => x);
    if (field === 'tierName') {
      newCustomTiers[id - 1].tierName = newValue;
      setChangeNotSaved(true);
    } else if (field === 'low') {
      newCustomTiers[id - 1].low = newValue ? parseInt(newValue) : '';
      setChangeNotSaved(true);
    } else if (field === 'high') {
      newCustomTiers[id - 1].high = newValue ? parseInt(newValue) : '';
      setChangeNotSaved(true);
    }
    validateAll(
      newCustomTiers,
      customRiskScoreTypeMinValue,
      customRiskScoreTypeMaxValue,
      minTierValue,
      maxTierValue,
    );
  }

  function handleRemoveCustomTier(id) {
    let newCustomTiers = customTiers.map((x) => x);
    // if it is the first one:
    if (id === 1) {
      newCustomTiers = [
        {
          id: 1,
          tierName: 'Tier 1',
          low: customRiskScoreTypeMinValue,
          high: customRiskScoreTypeMaxValue,
        },
      ];
      setDistributionBandTypeValue(1);
      setHideCustomTiersSection(true);
      setChangeNotSaved(true);
    }

    // if it is one in the middle:
    else if (id !== 1 && id !== newCustomTiers.length) {
      newCustomTiers = newCustomTiers.slice(0, -newCustomTiers.length + id - 1);
      setChangeNotSaved(true);
    }

    // if it is the last one:
    else if (id === newCustomTiers.length) {
      newCustomTiers = newCustomTiers.slice(0, -1);
      setChangeNotSaved(true);
    }
    validateAll(
      newCustomTiers,
      customRiskScoreTypeMaxValue,
      customRiskScoreTypeMinValue,
      minTierValue,
      maxTierValue,
    );
  }

  function handleAddCustomTier() {
    // verify limits first
    if (customTiers[customTiers.length - 1].high >= customRiskScoreTypeMaxValue) {
      setCustomTiersWarningMessage('You have reached the maximum score.');
      setTimeout(() => {
        setCustomTiersWarningMessage('');
      }, 8000);
    } else {
      setCustomTiersWarningMessage('');
      const newCustomTiers = customTiers.map((x) => x);
      const id = newCustomTiers[newCustomTiers.length - 1].id + 1;
      newCustomTiers.push({
        id,
        tierName: `Tier ${newCustomTiers.length + 1}`,
        low: parseInt(newCustomTiers[newCustomTiers.length - 1].high) + 1,
        high: customRiskScoreTypeMaxValue,
      });
      validateAll(newCustomTiers);
      setChangeNotSaved(true);
    }
  }
  return (
    <Grid item container>
      <Grid item container direction="row" spacing={2}>
        <FormLabel>
          Begin buidling the tiers by setting a min and max volume per tier section. Each tier must
          be a minimum of 20 score points. The tier levels will appear automatically until you reach
          your Max Range.
        </FormLabel>

        <List test-id="myOwnTiers" data-cy="rsk-Tier-Label">
          {customTiers?.map((element, index) => (
            <ListItem key={index} dense button>
              <TextField
                test-id={`tierName${index}`}
                data-cy="rsk-TierName-Textbox"
                id="tierNameTextField"
                label="Tier Name"
                value={element.tierName}
                inputProps={{ maxLength: 10 }}
                onChange={(event) =>
                  handleCustomTierRowChange(element.id, 'tierName', event.target.value)
                }
              />
              <TextField
                test-id={`low${index}`}
                data-cy="rsk-LowTier-Textbox"
                id="LowTextField"
                label="Low"
                value={element.low}
                inputProps={{ maxLength: customRiskScoreTypeMinValue.length }}
                onChange={(event) =>
                  handleCustomTierRowChange(element.id, 'low', event.target.value)
                }
              />
              <TextField
                test-id={`High${index}`}
                data-cy="rsk-HighTier-Textbox"
                id="highTextField"
                label="High"
                value={element.high}
                inputProps={{ maxLength: customRiskScoreTypeMaxValue.length }}
                onChange={(event) =>
                  handleCustomTierRowChange(element.id, 'high', event.target.value)
                }
              />
              <IconButton
                test-id={`removeTierButton${index}`}
                data-cy="aq-RemoveTier-button"
                color="primary"
                aria-label="Remove Tier"
                component="span"
                onClick={() => handleRemoveCustomTier(element.id)}
                size="large"
              >
                <DeleteForeverIcon />
              </IconButton>
              <IconButton
                test-id={`addTier${index}`}
                data-cy="rsk-AddTier-button"
                hidden={
                  customTiers.length === 10 || element.id < customTiers.length || validationErrors
                }
                color="primary"
                aria-label="Add Next Tier"
                component="span"
                onClick={() => handleAddCustomTier()}
                size="large"
              >
                <AddIcon />
              </IconButton>
              <Alert
                severity="error"
                hidden={element?.errorMessage?.length === 0 || element?.errorMessage === undefined}
              >
                {element?.errorMessage}
              </Alert>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Alert severity="warning" hidden={customTiersWarningMessage.length === 0}>
          {customTiersWarningMessage}
        </Alert>
      </Grid>
    </Grid>
  );
}

export default MyOwnTiers;
