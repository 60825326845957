/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import validator from 'validator';
import { QueryOptionTypes, QueryActionTypes } from '../../../../utils/constants/enums';
import {
  addUserQueryOption,
  updateUserQueryOption,
  getSavedRiskOptions,
} from '../../../../services/usersQueriesOptions.api';
import getRiskScoreTypesActive from '../../../../services/riskScoreTypes.api';
import { setAlertSuccessResponse, updateRiskOptionsQuery } from './RiskOptions.utils';
import MyOwnTiers from './components/MyOwnTiers';
import DistributionBandSelector from './components/DistributionBandSelector';
import ScoreTypeSelector from './components/ScoreTypeSelector';
import SaveConfirmationDialog from './components/SaveConfirmationDialog';
import SavedOptions from './components/SavedOptions';
import './RiskOptions.scss';
import ControlsByReport from './components/ControlsByReport';
import { StoreContext } from '../../../../store/storeContext';
import { VANTAGE_V3_NAME } from '../../../utils/constants';

function RiskOptionsSelection({
  query,
  onChange,
  validationErrorsState: [validationErrors, setValidationErrors],
  resetState,
  setResetState,
  focusLender,
  isExecutiveSummaryReport,
}) {
  const [distributionBandTypeValue, setDistributionBandTypeValue] = useState(1);
  const [customScoreRangeOption, setCustomScoreRangeOption] = useState(false);
  const [userQueryOptionId, setUserQueryOptionId] = useState(0);
  const [savedOptionsChangeNotSaved, setSavedOptionsChangeNotSaved] = useState(false);
  const [presetBandsDistributionValue, setPresetBandsDistributionValue] = useState({
    value: '100 Point Bands',
  });
  const [changeNotSaved, setChangeNotSaved] = useState(false);
  const [hideOwnScoreRangeSection, setHideOwnScoreRangeSection] = useState(true);
  const [hideCustomTiersSection, setHideCustomTiersSection] = useState(true);
  const [minTierValue, setMinTierValue] = useState(300);
  const [maxTierValue, setMaxTierValue] = useState(850);
  const [customRiskScoreTypeMinValue, setCustomRiskScoreTypeMinValue] = useState(300);
  const [customRiskScoreTypeMaxValue, setCustomRiskScoreTypeMaxValue] = useState(850);
  const [customTiers, setCustomTiers] = useState([]);

  const [selectedRiskScoreType, setSelectedRiskScoreType] = useState();
  const [ownScoreRangeErrorMessage, setOwnScoreRangeErrorMessage] = useState('');

  const [newSavedOptionNameTextFieldHidden, setNewSavedOptionNameTextFieldHidden] = useState(true);
  const [savedOptionName, setSavedOptionName] = useState('');
  const [availableRiskScoreTypes, setAvailableRiskScoreTypes] = useState([]);
  const [availableUsersQueriesOptionsActive, setAvailableUsersQueriesOptionsActive] = useState([]);
  const [message, setMessage] = useState({ severity: '', text: '' });
  const [, setComponentRiskOptionsSelection] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const { actions } = useContext(StoreContext);
  const [defaultValues, setDefaultValues] = useState(false);

  useEffect(() => {
    if (!validationErrors)
      updateRiskOptionsQuery(
        query,
        availableRiskScoreTypes,
        selectedRiskScoreType,
        customTiers,
        userQueryOptionId,
        customScoreRangeOption,
        customRiskScoreTypeMinValue,
        customRiskScoreTypeMaxValue,
        distributionBandTypeValue,
        presetBandsDistributionValue,
        onChange,
        setComponentRiskOptionsSelection,
      );
  }, [
    customTiers,
    availableRiskScoreTypes,
    validationErrors,
    selectedRiskScoreType,
    customRiskScoreTypeMinValue,
    customRiskScoreTypeMaxValue,
    distributionBandTypeValue,
    presetBandsDistributionValue,
    resetState,
  ]);

  useEffect(() => {
    if (resetState || defaultValues) {
      setSelectedRiskScoreType(
        parseInt(
          availableRiskScoreTypes.find(({ riskScoreTypeNm }) => riskScoreTypeNm === VANTAGE_V3_NAME)
            .riskScoreTypeId,
        ),
      );
      setDistributionBandTypeValue(1);
      setPresetBandsDistributionValue({ value: '100 Point Bands' });
      setMinTierValue(300);
      setMaxTierValue(850);
      setCustomRiskScoreTypeMinValue(300);
      setCustomRiskScoreTypeMaxValue(850);
      setDistributionBandTypeValue(1);
      setCustomScoreRangeOption(false);
      setUserQueryOptionId(0);
      setHideOwnScoreRangeSection(true);
      setHideCustomTiersSection(true);
      setCustomTiers([]);
    }
    setDefaultValues(false);
  }, [resetState, defaultValues]);

  useEffect(() => {
    getRiskScoreTypesActive()
      .then((availableRiskScoreTypesData) => {
        setAvailableRiskScoreTypes(availableRiskScoreTypesData);
        if (query?.riskOptionsSelection?.editableRiskOption?.customScoreType?.riskScoreTypeId) {
          setSelectedRiskScoreType(
            query?.riskOptionsSelection?.editableRiskOption?.customScoreType?.riskScoreTypeId,
          );
        } else if (availableRiskScoreTypesData.length > 0) {
          setSelectedRiskScoreType(
            parseInt(
              availableRiskScoreTypesData.find(
                ({ riskScoreTypeNm }) => riskScoreTypeNm === VANTAGE_V3_NAME,
              ).riskScoreTypeId,
            ),
          );
        }
      })
      .catch((error) => {
        actions.errorAction(error);
      });

    getSavedRiskOptions(QueryOptionTypes.CriteriaRiskQueryOptions)
      .then((availableUsersQueriesOptionsActiveData) => {
        setAvailableUsersQueriesOptionsActive(availableUsersQueriesOptionsActiveData);
      })
      .catch((error) => {
        actions.errorAction(error);
      });
    if (query?.riskOptionsSelection?.editableRiskOption?.presetBandsDistributionValue) {
      setPresetBandsDistributionValue(
        query?.riskOptionsSelection?.editableRiskOption?.presetBandsDistributionValue,
      );
    }

    if (query?.riskOptionsSelection?.editableRiskOption?.distributionBandTypeId) {
      setDistributionBandTypeValue(
        query?.riskOptionsSelection?.editableRiskOption?.distributionBandTypeId,
      );
    }
    if (
      query?.riskOptionsSelection?.editableRiskOption?.distributionBandTypeId === 2 ||
      query?.riskOptionsSelection?.editableRiskOption?.distributionBandTypeId?.value ===
        'Custom Tiers'
    ) {
      setHideCustomTiersSection(false);
    }
    if (query?.riskOptionsSelection?.editableRiskOption?.customTiers) {
      setCustomTiers(query?.riskOptionsSelection?.editableRiskOption?.customTiers);
      if (query?.riskOptionsSelection?.editableRiskOption?.customTiers.length > 0)
        setCustomRiskScoreTypeMinValue(
          query?.riskOptionsSelection?.editableRiskOption?.customTiers[0].high,
        );
    }
    if (query?.riskOptionsSelection?.editableRiskOption?.customScoreRangeOption) {
      setCustomScoreRangeOption(
        query?.riskOptionsSelection?.editableRiskOption?.customScoreRangeOption,
      );
      setHideOwnScoreRangeSection(
        !query?.riskOptionsSelection?.editableRiskOption?.customScoreRangeOption,
      );
    }
    if (query?.riskOptionsSelection?.editableRiskOption?.customScoreType) {
      setCustomRiskScoreTypeMinValue(
        query?.riskOptionsSelection?.editableRiskOption?.customScoreType?.riskScoreTypeMinValue,
      );
      setCustomRiskScoreTypeMaxValue(
        query?.riskOptionsSelection?.editableRiskOption?.customScoreType?.riskScoreTypeMaxValue,
      );
    }
  }, []);

  useEffect(() => {
    if (distributionBandTypeValue === 2 && customTiers.length === 0)
      setCustomTiers([
        {
          id: 1,
          tierName: 'Tier 1',
          low: customRiskScoreTypeMinValue,
          high: customRiskScoreTypeMaxValue,
        },
      ]);
  }, [distributionBandTypeValue]);

  function validateErrorsInOwnScoreRange(
    newCustomRiskScoreTypeMinValue,
    newCustomRiskScoreTypeMaxValue,
    minTier,
    maxTier,
  ) {
    let errorInValidation = false;
    const scoreTypeSelected = availableRiskScoreTypes.find(
      ({ riskScoreTypeId }) => riskScoreTypeId === selectedRiskScoreType,
    );
    if (!validator.isNumeric(newCustomRiskScoreTypeMinValue.toString())) {
      setOwnScoreRangeErrorMessage('The Low tier can only contain numbers.');
      errorInValidation = true;
    } else if (!validator.isNumeric(newCustomRiskScoreTypeMaxValue.toString())) {
      setOwnScoreRangeErrorMessage('The High tier can only contain numbers.');
      errorInValidation = true;
    } else if (parseInt(newCustomRiskScoreTypeMinValue) < parseInt(minTier)) {
      setOwnScoreRangeErrorMessage(
        `The Low ${scoreTypeSelected?.riskScoreTypeNm} range must be greater than or equal to ${minTier} and less than or equal to ${maxTier}.`,
      );
      errorInValidation = true;
    } else if (parseInt(newCustomRiskScoreTypeMaxValue) > parseInt(maxTier)) {
      setOwnScoreRangeErrorMessage(
        `The High ${scoreTypeSelected?.riskScoreTypeNm} range must be greater than or equal to ${minTier} and less than or equal to ${maxTier}.`,
      );
      errorInValidation = true;
    } else if (
      parseInt(newCustomRiskScoreTypeMaxValue) - parseInt(newCustomRiskScoreTypeMinValue) <
      19
    ) {
      setOwnScoreRangeErrorMessage(
        `The range between Low and Max ${scoreTypeSelected?.riskScoreTypeNm} must be at least 20 points.`,
      );
      errorInValidation = true;
    } else if (
      parseInt(newCustomRiskScoreTypeMinValue) > parseInt(newCustomRiskScoreTypeMaxValue)
    ) {
      setOwnScoreRangeErrorMessage(
        `The range between Low and Max ${scoreTypeSelected?.riskScoreTypeNm} must be at least 20 points.`,
      );
      errorInValidation = true;
    } else {
      setOwnScoreRangeErrorMessage('');
    }
    return errorInValidation;
  }

  function validateErrorsInCustomTierRows(newCustomTiers) {
    let errorInValidation = false;
    for (let i = 0; i < newCustomTiers.length; i++) {
      // validate tier names
      if (newCustomTiers[i].tierName.length > 10) {
        newCustomTiers[i].errorMessage = "The Tier's name can not have more than 10 characters.";
        errorInValidation = true;
      }

      // validate low values
      if (!validator.isNumeric(newCustomTiers[i].low.toString())) {
        newCustomTiers[i].errorMessage = "The Tier's Low point can only contain numbers.";
        errorInValidation = true;
      }
      if (parseInt(newCustomTiers[i].low) < minTierValue) {
        newCustomTiers[i].errorMessage =
          'Your min. Tier number cannot be lesser than the Min Range of the Score Type or the Score Range.';
        errorInValidation = true;
      }
      if (i > 0 && parseInt(newCustomTiers[i].low) <= parseInt(newCustomTiers[i - 1].high)) {
        newCustomTiers[i].errorMessage =
          "The Tier's Low point must be one more then the previous Tier's High point.";
        errorInValidation = true;
      }

      // validate high values
      if (!validator.isNumeric(newCustomTiers[i].high.toString())) {
        newCustomTiers[i].errorMessage = "The Tier's High point can only contain numbers.";
        errorInValidation = true;
      }
      if (parseInt(newCustomTiers[i].high) - parseInt(newCustomTiers[i].low) < 19) {
        newCustomTiers[i].errorMessage =
          "The Tier's High point must be at least 20 points higher than the Tier's Low point.";
        errorInValidation = true;
      }
      if (parseInt(newCustomTiers[i].low) > parseInt(newCustomTiers[i].high)) {
        newCustomTiers[i].errorMessage =
          "The Tier's High point must be one more then the previous Tier's High point.";
        errorInValidation = true;
      }
      if (parseInt(newCustomTiers[i].high) > customRiskScoreTypeMaxValue) {
        newCustomTiers[i].errorMessage =
          'Your max. Tier number cannot be higher than the Max Range of the Score Type or the Score Range.';
        errorInValidation = true;
      }

      if (parseInt(newCustomTiers[i].low) < customRiskScoreTypeMinValue) {
        newCustomTiers[i].errorMessage =
          'Your min. Tier number cannot be lower than the Min Range of the Score Type or the Score Range.';
        errorInValidation = true;
      }
      if (!errorInValidation) {
        // if no errors reset the error message
        newCustomTiers[i].errorMessage = '';
      }
    }

    setCustomTiers(newCustomTiers);
    return errorInValidation;
  }

  function validateAll(
    ct,
    newCustomRiskScoreTypeMinValue = customRiskScoreTypeMinValue,
    newCustomRiskScoreTypeMaxValue = customRiskScoreTypeMaxValue,
    minTier,
    maxTier,
  ) {
    if (
      (customScoreRangeOption &&
        validateErrorsInOwnScoreRange(
          newCustomRiskScoreTypeMinValue,
          newCustomRiskScoreTypeMaxValue,
          minTier,
          maxTier,
        )) ||
      (distributionBandTypeValue === 2 && validateErrorsInCustomTierRows(ct))
    ) {
      setValidationErrors(true);
    } else {
      setValidationErrors(false);
    }
  }

  function handleSaveOrUpdate(queryActionType) {
    const queryOptionTypeId = QueryOptionTypes.CriteriaRiskQueryOptions;

    let userQueryOptionNm = '';
    if (savedOptionName.trim() === '') {
      userQueryOptionNm = availableUsersQueriesOptionsActive?.find(
        (item) => item.userQueryOptionId === userQueryOptionId,
      ).userQueryOptionNm;
    } else {
      userQueryOptionNm = savedOptionName.trimEnd();
    }
    const userQueryOptionJSON = JSON.stringify(query.riskOptionsSelection);
    const activeIndicator = true;

    if (queryActionType === QueryActionTypes.Add) {
      addUserQueryOption(
        queryOptionTypeId,
        null,
        userQueryOptionNm,
        userQueryOptionJSON,
        activeIndicator,
        false,
      )
        .then((availableUsersQueriesOptionsActiveData) => {
          setAvailableUsersQueriesOptionsActive(availableUsersQueriesOptionsActiveData);
          setSavedOptionsChangeNotSaved(false);
          setNewSavedOptionNameTextFieldHidden(true);
          let newUserQueryOption = null;
          if (availableUsersQueriesOptionsActiveData.length > 0) {
            newUserQueryOption = availableUsersQueriesOptionsActiveData.reduce((prev, current) =>
              prev.userQueryOptionId > current.userQueryOptionId ? prev : current,
            );
          }
          if (newUserQueryOption != null) {
            setUserQueryOptionId(newUserQueryOption?.userQueryOptionId);
          }
          setSavedOptionName('');
          setChangeNotSaved(false);
          setAlertSuccessResponse(`Risk Option added successfuly!`, setMessage);
        })
        .catch((error) => {
          actions.errorAction(error);
        });
    } else if (
      queryActionType === QueryActionTypes.Update ||
      queryActionType === QueryActionTypes.UpdateAdd ||
      queryActionType === QueryActionTypes.UpdateReplace
    ) {
      updateUserQueryOption(
        queryOptionTypeId,
        null,
        null,
        userQueryOptionNm,
        userQueryOptionJSON,
        activeIndicator,
        false,
        false,
      )
        .then((availableUsersQueriesOptionsActiveData) => {
          setAvailableUsersQueriesOptionsActive(availableUsersQueriesOptionsActiveData);
          setSavedOptionsChangeNotSaved(false);
          setNewSavedOptionNameTextFieldHidden(true);
          let newUserQueryOption;
          if (availableUsersQueriesOptionsActiveData.length > 0) {
            newUserQueryOption = availableUsersQueriesOptionsActiveData.reduce((prev, current) =>
              prev.userQueryOptionId > current.userQueryOptionId ? prev : current,
            );
          }
          if (newUserQueryOption != null) {
            setUserQueryOptionId(newUserQueryOption?.userQueryOptionId);
          }
          setSavedOptionName('');
          setChangeNotSaved(false);
          setAlertSuccessResponse(`Risk Option updated successfuly!`, setMessage);
        })
        .catch((error) => {
          actions.errorAction(error);
        });
    }
  }

  return (
    <Grid container className="riskOptions" spacing={2} padding="10px 30px">
      <SavedOptions
        onChange={onChange}
        setMessage={setMessage}
        setUserQueryOptionId={setUserQueryOptionId}
        availableUsersQueriesOptionsActive={availableUsersQueriesOptionsActive}
        setSelectedRiskScoreType={setSelectedRiskScoreType}
        setCustomScoreRangeOption={setCustomScoreRangeOption}
        setHideOwnScoreRangeSection={setHideOwnScoreRangeSection}
        setCustomRiskScoreTypeMinValue={setCustomRiskScoreTypeMinValue}
        setCustomRiskScoreTypeMaxValue={setCustomRiskScoreTypeMaxValue}
        setDistributionBandTypeValue={setDistributionBandTypeValue}
        setPresetBandsDistributionValue={setPresetBandsDistributionValue}
        setHideCustomTiersSection={setHideCustomTiersSection}
        setCustomTiers={setCustomTiers}
        setNewSavedOptionNameTextFieldHidden={setNewSavedOptionNameTextFieldHidden}
        userQueryOptionId={userQueryOptionId}
        QueryOptionTypes={QueryOptionTypes}
        setAvailableUsersQueriesOptionsActive={setAvailableUsersQueriesOptionsActive}
        setSavedOptionName={setSavedOptionName}
        setAlertSuccessResponse={setAlertSuccessResponse}
        setSavedOptionsChangeNotSaved={setSavedOptionsChangeNotSaved}
        savedOptionName={savedOptionName}
        newSavedOptionNameTextFieldHidden={newSavedOptionNameTextFieldHidden}
        savedOptionsChangeNotSaved={savedOptionsChangeNotSaved}
        changeNotSaved={changeNotSaved}
        handleSaveOrUpdate={handleSaveOrUpdate}
        QueryActionTypes={QueryActionTypes}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        validationErrors={validationErrors}
        message={message}
        setDefaultValues={setDefaultValues}
        validateErrorsInOwnScoreRange={validateErrorsInOwnScoreRange}
      />
      <SaveConfirmationDialog
        confirmationDialogOpen={confirmationDialogOpen}
        setConfirmationDialogOpen={setConfirmationDialogOpen}
        handleSaveOrUpdate={handleSaveOrUpdate}
        QueryActionTypes={QueryActionTypes}
      />

      <ScoreTypeSelector
        selectedRiskScoreType={selectedRiskScoreType}
        setSelectedRiskScoreType={setSelectedRiskScoreType}
        availableRiskScoreTypes={availableRiskScoreTypes}
        setMinTierValue={setMinTierValue}
        customScoreRangeOption={customScoreRangeOption}
        setCustomScoreRangeOption={setCustomScoreRangeOption}
        setCustomRiskScoreTypeMinValue={setCustomRiskScoreTypeMinValue}
        setMaxTierValue={setMaxTierValue}
        setCustomRiskScoreTypeMaxValue={setCustomRiskScoreTypeMaxValue}
        customTiers={customTiers}
        validateAll={validateAll}
        setChangeNotSaved={setChangeNotSaved}
        setHideOwnScoreRangeSection={setHideOwnScoreRangeSection}
        hideOwnScoreRangeSection={hideOwnScoreRangeSection}
        customRiskScoreTypeMinValue={customRiskScoreTypeMinValue}
        customRiskScoreTypeMaxValue={customRiskScoreTypeMaxValue}
        minTierValue={minTierValue}
        maxTierValue={maxTierValue}
        ownScoreRangeErrorMessage={ownScoreRangeErrorMessage}
        setCustomTiers={setCustomTiers}
        setValidationErrors={setValidationErrors}
        validateErrorsInOwnScoreRange={validateErrorsInOwnScoreRange}
      />

      <DistributionBandSelector
        setDistributionBandTypeValue={setDistributionBandTypeValue}
        setHideCustomTiersSection={setHideCustomTiersSection}
        setChangeNotSaved={setChangeNotSaved}
        setPresetBandsDistributionValue={setPresetBandsDistributionValue}
        presetBandsDistributionValue={presetBandsDistributionValue}
      />

      {!hideCustomTiersSection && (
        <MyOwnTiers
          customTiers={customTiers}
          setChangeNotSaved={setChangeNotSaved}
          validateAll={validateAll}
          minTierValue={minTierValue}
          maxTierValue={maxTierValue}
          customRiskScoreTypeMaxValue={customRiskScoreTypeMaxValue}
          customRiskScoreTypeMinValue={customRiskScoreTypeMinValue}
          setDistributionBandTypeValue={setDistributionBandTypeValue}
          setHideCustomTiersSection={setHideCustomTiersSection}
          validationErrors={validationErrors}
        />
      )}

      <ControlsByReport
        onChange={onChange}
        query={query}
        setChangeNotSaved={setChangeNotSaved}
        isExecutiveSummaryReport={isExecutiveSummaryReport}
        focusLender={focusLender}
        resetState={resetState}
        setResetState={setResetState}
        setValidationErrors={setValidationErrors}
      />

      <div style={{ display: 'flex', flexDirection: 'row' }} />
    </Grid>
  );
}

export default RiskOptionsSelection;
