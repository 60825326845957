import { AlertSeverity } from '../../../../utils/constants/enums';
import { removeEmptyValues } from '../../../../utils/objectUtils';

const timerMessage = (time, setMessage) => {
  setTimeout(() => {
    setMessage({ severity: '', text: '' });
  }, time);
};

const setAlertSuccessResponse = (msg, setMessage) => {
  setMessage({ severity: AlertSeverity.success, text: msg });
  timerMessage(8000, setMessage);
};

function updateRiskOptionsQuery(
  query,
  availableRiskScoreTypes,
  selectedRiskScoreType,
  customTiers,
  userQueryOptionId,
  customScoreRangeOption,
  customRiskScoreTypeMinValue,
  customRiskScoreTypeMaxValue,
  distributionBandTypeValue,
  presetBandsDistributionValue,
  onChange,
  setComponentRiskOptionsSelection,
) {
  const scoreTypeSelected = availableRiskScoreTypes.find(
    ({ riskScoreTypeId }) => riskScoreTypeId === selectedRiskScoreType,
  );

  const riskOptionsSelection = {
    riskDynamicFields: query?.riskOptionsSelection?.riskDynamicFields,
    userQueryOptionId,
    editableRiskOption: {
      scoreType: {
        riskScoreTypeId: scoreTypeSelected?.riskScoreTypeId,
        riskScoreTypeNm: scoreTypeSelected?.riskScoreTypeNm,
        riskScoreTypeParameterValueNm: scoreTypeSelected?.riskScoreTypeParameterValueNm,
        riskScoreTypeMinValue: customRiskScoreTypeMinValue.toString(),
        riskScoreTypeMaxValue: customRiskScoreTypeMaxValue.toString(),
      },
      customScoreRangeOption,
      customScoreType: {
        riskScoreTypeId: scoreTypeSelected?.riskScoreTypeId,
        riskScoreTypeNm: scoreTypeSelected?.riskScoreTypeNm,
        riskScoreTypeParameterValueNm: scoreTypeSelected?.riskScoreTypeParameterValueNm,
        riskScoreTypeMinValue: customRiskScoreTypeMinValue.toString(),
        riskScoreTypeMaxValue: customRiskScoreTypeMaxValue.toString(),
      },
      customTiers,
      distributionBandTypeId: distributionBandTypeValue,
      presetBandsDistributionValue,
    },
    focusLender: query?.riskOptionsSelection?.focusLender,
  };

  if (
    customTiers?.length > 0 &&
    (distributionBandTypeValue === 2 || distributionBandTypeValue?.value === 'Custom Tiers')
  ) {
    riskOptionsSelection.editableRiskOption.tier1Low = { value: customTiers[0].low };
    customTiers.forEach(({ high, tierName }, index) => {
      const tierNum = index + 1;
      riskOptionsSelection.editableRiskOption[`tier${tierNum}High`] = { value: high };
      riskOptionsSelection.editableRiskOption[`tier${tierNum}Name`] = { value: tierName };
    });
  }

  onChange({
    riskOptionsSelection: {
      ...removeEmptyValues(riskOptionsSelection),
    },
  });

  setComponentRiskOptionsSelection(riskOptionsSelection);
  return riskOptionsSelection;
}

export { setAlertSuccessResponse, updateRiskOptionsQuery };
