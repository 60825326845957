import React from 'react';
import Button from '@mui/material/Button';
import ClassicModal from '../../components/common/ClassicModal';
import { useStyles } from '../../theme/VelocityHubMaterial';

function DeleteScheduleQueryModal({ show, onHideModal, title, onConfirmAction, text }) {
  const { classes } = useStyles();

  const deleteScheduleQueryModalBody = <div className={classes.root}>{text}</div>;

  const deleteScheduleQueryModalFooter = (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        onClick={onConfirmAction}
        data-cy="delete-schedule-query-button-confirm"
      >
        Confirm
      </Button>
      <Button
        variant="contained"
        onClick={onHideModal}
        data-cy="delete-schedule-query-button-cancel"
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <div>
      <ClassicModal
        show={show}
        className={classes.root}
        onHide={onHideModal}
        title={title}
        body={deleteScheduleQueryModalBody}
        footer={deleteScheduleQueryModalFooter}
      />
    </div>
  );
}

export default DeleteScheduleQueryModal;
