import { fetchData } from '../utils/fetchData';
import { RESOURCES, API_BASE_URL } from '../utils/constants/constants';

const DEALERS_AND_LENDERS_RESOURCES = RESOURCES.dealersAndLenders;

function searchDealers(stateId, query, searchType) {
  const resource = `${API_BASE_URL}${DEALERS_AND_LENDERS_RESOURCES.searchDealers}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      stateId,
      searchType,
      query,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

function searchLenders(stateId, query, searchType) {
  const resource = `${API_BASE_URL}${DEALERS_AND_LENDERS_RESOURCES.searchLenders}`;

  const payload = {
    method: 'POST',
    body: JSON.stringify({
      stateId,
      searchType,
      query,
    }),
    headers: {
      'Content-type': 'application/json',
    },
  };
  return fetchData(resource, payload);
}

export { searchDealers, searchLenders };
